import {Http} from '../http/http';
import {ApiUrls, UrlParamBuilder} from './api-urls';
import {Payment, Transaction} from '../store/payment/types';

class PaymentApi {
    public static async getPayment(bankId: string, webformId: string): Promise<Payment> {
        return await Http.get(
            ApiUrls.GetPayment,
            new UrlParamBuilder().addWebformId(webformId).addBankId(bankId).build(),
        );
    }

    public static async getPaymentUrl(bankId: string, webformId: string): Promise<string> {
        return await Http.get(
            ApiUrls.GetPaymentUrl,
            new UrlParamBuilder().addWebformId(webformId).addBankId(bankId).build(),
        );
    }

    public static async getLatestTransaction(bankId: string, paymentId: string): Promise<Transaction> {
        return await Http.get(
            ApiUrls.GetTransaction,
            new UrlParamBuilder().addPaymentId(paymentId).addBankId(bankId).build(),
        )
    }
}

export default PaymentApi;
