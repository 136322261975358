import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {FormParams} from '../../web-form.types';
import {AttachmentType} from '../../../../store/webform/types';
import styles from './file-handler.module.scss';
import closeImage from '../../../../../assets/images/close.svg';
import FileUploaderComponent from './file-uploader.component';
import WebformApi from '../../../../api/webform-api';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {Attachment} from '../../../../types/responses/attachments-response';
import {VariableDisplay} from '../../../../types/variable-display';

const FileHandlerComponent: FunctionComponent<FileHandlerProps> = ({attachmentTypes, label, footNote, errorMessage, onNumberOfFilesChanged, invalid, display = VariableDisplay.Optional}: FileHandlerProps) => {
    const {t} = useTranslation();
    const {invitationToken, bankId} = useParams<FormParams>();
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [fileNames, setFileNames] = useState<string[] | undefined>(undefined);
    const [filesInProgress, setFilesInProgress] = useState<boolean>(false);
    const [currentAttachmentType, setCurrentAttachmentType] = useState<AttachmentType>(attachmentTypes[0]);
    const [availableTypes, setAvailableTypes] = useState<AttachmentType[]>([])

    useEffect(() => {
        let shouldUpdate = false;
        if (attachmentTypes.length !== availableTypes.length) {
            shouldUpdate = true;
        } else {
            attachmentTypes.forEach(at => {
                if (!availableTypes.includes(at)) {
                    shouldUpdate = true;
                }
            })
        }
        if (shouldUpdate) {
            setAvailableTypes(attachmentTypes);
        }

    }, [attachmentTypes]);

    useEffect(() => {
        if (display !== VariableDisplay.Hidden) {
            getAttachments(attachmentTypes);
        }
    }, [availableTypes]);

    useEffect(() => {
        const activeAttachments = attachments.find(attachment => attachment.type === currentAttachmentType);
        if (activeAttachments) {
            setFileNames(activeAttachments.fileNames);
        }
    }, [attachments, currentAttachmentType]);

    const getAttachments = async (attachmentTypes: AttachmentType[]) => {
        if (attachmentTypes) {
            const attachments = await WebformApi.getAttachments(bankId, invitationToken, attachmentTypes);
            if (onNumberOfFilesChanged) {
                attachments.forEach(attachment => {
                    onNumberOfFilesChanged(attachment.type, attachment.fileNames.length);
                });
            }
            setAttachments(attachments);
            setFilesInProgress(false);
        }
    }

    const onFileSelected = async (files: FileList, attachmentType: AttachmentType) => {
        setFilesInProgress(true);
        const uploads = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i]) {
                uploads.push(WebformApi.uploadAttachments(bankId, invitationToken, files[i], attachmentType));
            }
        }
        Promise.all(uploads).then(() => {
            getAttachments(attachmentTypes);
        });
    }

    const onDeleteFile = (filename: string, attachmentType: AttachmentType) => {
        setFilesInProgress(true);
        WebformApi.deleteAttachment(bankId, invitationToken, filename, attachmentType).then(() => {
            getAttachments(attachmentTypes);
        });
    }

    const labelClasses = conditionalClassLister(styles)({
        label: true,
        withError: errorMessage
    });

    const containerClasses = conditionalClassLister(styles)({
        container: true,
        invalid: invalid,
    });

    const attachmentBoxClasses = conditionalClassLister(styles)({
        attachmentsBox: true,
        borderRadiusLeft: attachmentTypes.length === 1,
    })

    return display !== VariableDisplay.Hidden ? (
        <div className={styles.formField}>
            <label className={labelClasses}>
                {label}
                {display === VariableDisplay.Optional ? <div className={styles.labelInfo}>{t('Optional')}</div> : ''}
            </label>
            {invalid && errorMessage ? <div className={styles.labelError}>{t(errorMessage)}</div> : null}
            <div className={containerClasses}>
                {attachmentTypes.length > 1 ? <div className={styles.attachmentTypes}>
                    {attachmentTypes.map(type => {
                        const attachment = attachments.find(a => a.type === type);
                        const attachmentTypeClasses = conditionalClassLister(styles)({
                            attachmentType: true,
                            active: currentAttachmentType === type,
                            invalid: type !== AttachmentType.Images && invalid && (!attachment || attachment.fileNames.length === 0)
                        });
                        return <div key={type}
                                    className={attachmentTypeClasses}
                                    onClick={() => setCurrentAttachmentType(type)}>
                            {t(type)}
                        </div>
                    })}
                </div> : null}
                <div className={attachmentBoxClasses}>
                    <FileUploaderComponent onFileSelection={(fileList) => onFileSelected(fileList, currentAttachmentType)}
                                           hasLeftPanel={true}
                    />
                    <div className={styles.attachmentsList}>
                        {fileNames?.map(fileName =>
                            <div key={fileName} className={styles.attachment}>
                                <div className={styles.attachmentName}>{fileName}</div>
                                <img onClick={() => onDeleteFile(fileName, currentAttachmentType)}
                                     src={closeImage}
                                     width={30}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {footNote ? <div className={styles.footnote}>
                {footNote}
            </div> : ''}

        </div>
    ) : <></>;
}

export default FileHandlerComponent;

interface FileHandlerProps {
    label: string;
    footNote?: string;
    attachmentTypes: AttachmentType[];
    onNumberOfFilesChanged?: (type: AttachmentType, value: number) => void;
    display?: VariableDisplay;
    invalid?: boolean;
    errorMessage?: string;
}
