import React, {FunctionComponent, Suspense} from 'react';
import Routes from './routes';
import mapboxgl from 'mapbox-gl';
import {i18n} from 'i18next';
import AppProvider from './store/app-context';
import ToastMessagesComponent from './components/toast-messages/toast-messages.component';
import ErrorBoundary from './components/error-boundry/error-boundry.component';

mapboxgl.accessToken = 'pk.eyJ1Ijoiam1lc3RlcnMiLCJhIjoiY2tsajlmbXlvMG51NTJwcWl1dTRidGl6OSJ9.JZUAVi9z3yWwA9ZpcZGk_w'

export const App: FunctionComponent<OwnProps> = () => {
    return (
        <AppProvider>
            <ErrorBoundary>
                <ToastMessagesComponent/>
                <Suspense fallback="">
                    <Routes/>
                </Suspense>
            </ErrorBoundary>
        </AppProvider>
    )
}

export default App;

interface OwnProps {
    i18n: i18n;
}
