import React, {FunctionComponent, useEffect} from 'react';
import mapboxgl from 'mapbox-gl';
import styles from './mapbox.module.scss';
import {MapShape} from '../../types/map-shape';

// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-792053551
// eslint-disable-next-line @typescript-eslint/no-var-requires
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Mapbox: FunctionComponent<OwnProps> = ({
                                                 longitude,
                                                 latitude,
                                                 zoom,
                                                 shapes,
                                                 onParcelClick,
                                                 onDragEnd,
                                                 highlightedShapeIds,
                                                 onZoom,
                                             }: OwnProps) => {
    const map = React.useRef<mapboxgl.Map>();
    const [lng, setLng] = React.useState<number>(longitude || 4.691992);
    const [lat, setLat] = React.useState<number>(latitude || 50.872181);
    const [zoomLevel, setZoomLevel] = React.useState<number>(zoom || 17);
    const [mapShapes, setMapShapes] = React.useState<MapShape[] | null>(null);
    const [currentShapeIds, setCurrentShapeIds] = React.useState<string[] | null>(null);
    const [styleLoaded, setStyleLoaded] = React.useState<boolean>(false);
    const [shapesLoaded, setShapesLoaded] = React.useState<boolean>(false);
    const [activeShapesLoaded, setActiveShapesLoaded] = React.useState<boolean>(false);


    const checkStylesPresentAndMapDrawn = () => {
        return styleLoaded && shapesLoaded && activeShapesLoaded;
    }

    useEffect(() => {
        updateMap();
    }, [styleLoaded, shapesLoaded, activeShapesLoaded]);

    const updateMap = () => {
        if (styleLoaded) {
            // add3DModel();
            addHouseNumber();
        }
        if (checkStylesPresentAndMapDrawn()) {
            addMapShapes();
            addMapActiveShapes();
        }
    }

    const addHouseNumber = () => {
        // remove the default house number layer
        const existingHouseNumberId = 'building-number-label'
        if (map && map.current && map.current.getLayer(existingHouseNumberId)) {
            map.current.removeLayer(existingHouseNumberId);
        }
        // add our own
        const layerId = 'house-number'
        if (map && map.current && !map.current.getLayer(layerId)) {
            map.current.addLayer({
                'id': layerId,
                'type': 'symbol',
                'metadata': {
                    'mapbox:featureComponent': 'buildings',
                    'mapbox:group': 'Buildings, building-labels'
                },
                'source': 'composite',
                'source-layer': 'housenum_label',
                'minzoom': 17,
                'layout': {
                    'text-field': ['get', 'house_num'],
                    'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
                    'text-padding': 4,
                    'text-max-width': 7,
                    'text-size': 12
                },
                'paint': {
                    'text-halo-color': 'hsl(20, 13%, 92%)',
                    'text-halo-width': 1
                }
            });
        }
    }

    const addMapShapes = () => {
        if (!mapShapes) return;
        if (!map?.current!.getLayer('geoShapes')) {
            addMapShapesSource()
        }
        map.current?.setLayerZoomRange('geoShapes', 16, 22);
        map.current?.setLayerZoomRange('outline', 16, 22);

        (map.current!.getSource('geoShapes') as mapboxgl.GeoJSONSource).setData({
            'type': 'FeatureCollection',
            // @ts-ignore
            'features': mapShapes?.map(mapShape => {
                return ({
                    properties: {id: mapShape.capakey},
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': mapShape.geojson.coordinates
                    }
                })
            })
        });
    }

    const addMapShapesSource = () => {
        // Add a data source containing GeoJSON data.
        map?.current!.addSource('geoShapes', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                // @ts-ignore
                'features': mapShapes?.map(mapShape => {
                    return ({
                        properties: {id: mapShape.capakey},
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': mapShape.geojson.coordinates
                        }
                    })
                })
            }
        });

        // Add a new layer to visualize the polygon.
        map.current!.addLayer({
            'id': 'geoShapes',
            'type': 'fill',
            'source': 'geoShapes', // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#46788B',
                'fill-opacity': 0.1
            }
        });
        // Add a black outline around the polygon.
        map.current!.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'geoShapes',
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 0.5
            }
        });
    }

    const addMapActiveShapes = () => {
        if (!currentShapeIds || !map?.current) return;
        if (!map.current!.getSource('currentShape')) {
            addMapActiveShapesSource();
        }
        (map.current!.getSource('currentShape') as mapboxgl.GeoJSONSource).setData({
            'type': 'FeatureCollection',
            // @ts-ignore
            'features': mapShapes?.filter(mapShape => {
                return currentShapeIds?.includes(mapShape.capakey.toString());
            }).map(mapShape => {
                return ({
                    properties: {id: mapShape.capakey},
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': mapShape.geojson.coordinates
                    }
                })
            })
        });
    }

    const addMapActiveShapesSource = () => {
        // SET ACTIVE SHAPES SOURCE LAYERS
        map?.current!.addSource('currentShape', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                // @ts-ignore
                'features': []
            }
        });

        // Add a new layer to visualize the polygon.
        map.current!.addLayer({
            'id': 'currentShape',
            'type': 'fill',
            'source': 'currentShape', // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#0080ff',
                'fill-opacity': 0.2
            }
        });
        // Add a black outline around the polygon.
        map.current!.addLayer({
            'id': 'currentShapeOutline',
            'type': 'line',
            'source': 'currentShape',
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 0.5
            }
        });
    }

    useEffect(() => {
        if (highlightedShapeIds) {
            setActiveShapesLoaded(false);
            setCurrentShapeIds(highlightedShapeIds);
        }
    }, [highlightedShapeIds]);

    useEffect(() => {
        if (currentShapeIds) {
            setActiveShapesLoaded(() => true);
        }
    }, [currentShapeIds])

    useEffect(() => {
        setLng(longitude || 4.691992);
        setLat(latitude || 50.872181);
        // setZoomLevel(zoom || 18);
        map.current?.flyTo({
            curve: 1.5,
            center: [longitude || 4.691992, latitude || 50.872181],
            zoom: zoomLevel,
        });
    }, [longitude, latitude]);

    useEffect(() => {
        if (shapes) {
            setShapesLoaded(false);
            setMapShapes(shapes);
        }
    }, [shapes]);

    useEffect(() => {
        if (!mapShapes) return;
        setShapesLoaded(true)
    }, [mapShapes])

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: 'map-container',
            style: 'mapbox://styles/mapbox/streets-v12',
            // style: 'mapbox://styles/mapbox/outdoors-v12',
            center: [lng, lat],
            attributionControl: false,
            zoom: zoomLevel,
            doubleClickZoom: false,
            maxZoom: 19,
        });

        map.current!.on('load', (e) => {
            setStyleLoaded(true);
        });

        map.current!.on('click', 'geoShapes', (e) => {
            const features = map.current?.queryRenderedFeatures(e.point);
            const shapes = features?.filter(feature => feature.source === 'geoShapes');
            if (shapes && shapes.length > 0) {
                const capakey = shapes[0].properties?.id.toString();
                onParcelClick(capakey);
            }
        });

        map.current!.on('dragend', (e) => {
            if (map.current && onDragEnd) {
                const center = map.current.getCenter();
                onDragEnd(center.lng, center.lat);
            }
        });
        map.current!.on('zoom', (e) => {
            if (map.current && onZoom) {
                const updatedZoom = map.current?.getZoom();
                setZoomLevel(updatedZoom);
                onZoom(updatedZoom);
            }
        });
        // CURSOR STYLING
        map.current.on('mouseenter', 'geoShapes', (e) => {
            // Change the cursor style as a UI indicator.
            map.current!.getCanvas().style.cursor = 'pointer';
        });

        map.current.on('mouseleave', 'geoShapes', () => {
            map.current!.getCanvas().style.cursor = '';
        });
    }, []);


    return (
        <>
            <div id="map-container" className={styles.mapContainer}/>
        </>
    )
}

export default Mapbox;

interface OwnProps {
    longitude?: number;
    latitude?: number;
    zoom?: number;
    shapes?: MapShape[];
    onParcelClick: (capakey: string) => void;
    onClick?: (e: mapboxgl.MapboxGeoJSONFeature[]) => void;
    onDragEnd?: (lng: number, lat: number) => void;
    highlightedShapeIds?: string[];
    onZoom: (zoom: number) => void;
}
