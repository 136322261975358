import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {HTMLDateUtils} from '../../../utils/date-utils';
import {AttachmentType, WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import {VariableDisplay} from '../../../types/variable-display';
import LabeledDateInputComponent from '../components/input/labeled-date-input.component';
import LabeledNumberInputComponent from '../components/input/labeled-number-input.component';
import {WebformConfiguration} from '../../../store/bank/types';
import {isEmpty, isNullOrUndefined} from '../../../utils/validation.util';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import LoaderComponent from '../../../components/loader/loader';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step9Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [transactionDate, setTransactionDate] = useState<string>(HTMLDateUtils.fromISOtoHTMLString(form.previousTransactionDate) || '');
    const [transactionPrice, setTransactionPrice] = useState<number | string | undefined>(form.previousTransactionPrice || '');
    const [lastValuationDate, setLastValuationDate] = useState<string>(HTMLDateUtils.fromISOtoHTMLString(form.lastValuationDate) || '');
    const [lastValuationPrice, setLastValuationPrice] = useState<number | string | undefined>(form.lastValuationPrice || '');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [errors, setErrors] = useState<Step9Errors>({});
    const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true)
        const scopedIsValid = step9IsValid(updatedForm, configuration) && (configuration.displayValuationReportUpload === VariableDisplay.Required ? numberOfFiles > 0 : true);

        setIsValid(scopedIsValid);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
    }, [transactionPrice, transactionDate, lastValuationDate, lastValuationPrice, numberOfFiles]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || transactionPrice) {
            updatedForm.previousTransactionPrice = !isEmpty(transactionPrice) ? transactionPrice : null;
        }
        if (skipValidation || lastValuationPrice) {
            updatedForm.lastValuationPrice = !isEmpty(lastValuationPrice) ? lastValuationPrice : null
        }
        if (skipValidation || transactionDate) {
            updatedForm.previousTransactionDate = transactionDate ? HTMLDateUtils.fromHTMLtoISOString(transactionDate) : null;
        }
        if (skipValidation || lastValuationDate) {
            updatedForm.lastValuationDate = lastValuationDate ? HTMLDateUtils.fromHTMLtoISOString(lastValuationDate) : null;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (configuration.displayTransactionPrice === VariableDisplay.Required && isEmpty(transactionPrice)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('transaction_price_required')
                transactionPriceRequired: 'transaction_price_required',
            }));
        }
        if (configuration.displayTransactionDate === VariableDisplay.Required && isEmpty(transactionDate)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('transaction_date_required')
                transactionDateRequired: 'transaction_date_required',
            }));
        }
        if (configuration.displayLastValuationPrice === VariableDisplay.Required && isEmpty(lastValuationPrice)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('last_valuation_price_required')
                lastValuationPriceRequired: 'last_valuation_price_required',
            }));
        }
        if (configuration.displayLastValuationDate === VariableDisplay.Required && isEmpty(lastValuationDate)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('last_valuation_date_required')
                lastValuationDateRequired: 'last_valuation_date_required',
            }));
        }
        if (configuration.displayValuationReportUpload === VariableDisplay.Required && numberOfFiles === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('files_required_error')
                requiredFiles: 'files_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !isNullOrUndefined(form.constructionYear);
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onPreviousTransactionPriceChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        setTransactionPrice(!isNaN(parseInt(updatedValue)) ? updatedValue : '');
        setErrors((prevState) => ({
            ...prevState,
            transactionPriceRequired: undefined
        }));
    };

    const onPreviousTransactionDateChanged = (htmlDateString: string) => {
        setTransactionDate(htmlDateString);
        setErrors((prevState) => ({
            ...prevState,
            transactionDateRequired: undefined
        }));
    };

    const onLastValuationPriceChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        setLastValuationPrice(!isNaN(parseInt(updatedValue)) ? updatedValue : '');
        setErrors((prevState) => ({
            ...prevState,
            lastValuationPriceRequired: undefined
        }));
    };

    const onLastValuationDateChanged = (htmlDateString: string) => {
        setLastValuationDate(htmlDateString);
        setErrors((prevState) => ({
            ...prevState,
            lastValuationDateRequired: undefined
        }));
    };

    const onPreviousTransactionPriceBlur = (value: string) => {
        if (value !== '' && isNaN(parseInt(value)) || parseInt(value) <= 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Previous transaction price must be bigger than 0')
                previousTransactionPrice: 'Previous transaction price must be bigger than 0'
            }));
        } else {
            setErrors((prevState) => ({
                ...prevState,
                previousTransactionPrice: undefined
            }));
        }
    }

    const onLastValuationPriceBlur = (value: string) => {
        if (value != '' && isNaN(parseInt(value)) || parseInt(value) <= 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Last valuation price must be bigger than 0')
                lastValuationPrice: 'Last valuation price must be bigger than 0'
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                lastValuationPrice: undefined
            }))
        }
    }

    const filesChanged = (value: number) => {
        setNumberOfFiles(value);
        if (value > 0) {
            setErrors((prevState) => ({
                ...prevState,
                requiredFiles: undefined,
            }));
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={9}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <LabeledDateInputComponent
                        id='transactionDate'
                        label={t('Enter transaction date')}
                        footnote={t('Date when the property was sold or bought')}
                        display={configuration.displayTransactionDate}
                        value={transactionDate}
                        valueChanged={onPreviousTransactionDateChanged}
                        errors={errors.transactionDateRequired}/>
                    <LabeledNumberInputComponent
                        id='transactionPrice'
                        label={t('Enter transaction price')}
                        footnote={t('Excl. notary\'s fees, registration fees and any VAT')}
                        display={configuration.displayTransactionPrice}
                        value={transactionPrice}
                        valueChanged={onPreviousTransactionPriceChanged}
                        valueBlurred={onPreviousTransactionPriceBlur}
                        unit={'EUR'}
                        errors={errors.previousTransactionPrice || errors.transactionPriceRequired}/>
                    <LabeledDateInputComponent
                        id='lastValuationDate'
                        label={t('Enter last valuation date')}
                        footnote={t('Date when the property was valuated')}
                        display={configuration.displayLastValuationDate}
                        value={lastValuationDate}
                        valueChanged={onLastValuationDateChanged}
                        errors={errors.lastValuationDateRequired}/>
                    <LabeledNumberInputComponent
                        id='lastValuationPrice'
                        label={t('Enter last valuation price')}
                        display={configuration.displayLastValuationPrice}
                        value={lastValuationPrice}
                        valueChanged={onLastValuationPriceChanged}
                        valueBlurred={onLastValuationPriceBlur}
                        unit={'EUR'}
                        errors={errors.lastValuationPrice || errors.lastValuationPriceRequired}/>
                    <FileHandlerComponent attachmentTypes={[AttachmentType.ValuationReport]}
                                          label={t('Upload valuation report')}
                                          display={configuration.displayValuationReportUpload}
                                          footNote={t('Valuation report, deed,...')}
                                          invalid={!!errors.requiredFiles}
                                          onNumberOfFilesChanged={(type, value) => filesChanged(value)}
                                          errorMessage={errors.requiredFiles}/>
                    <RequiredErrorsBoxComponent
                        errors={errors.lastValuationPriceRequired || errors.lastValuationDateRequired
                        || errors.transactionDateRequired || errors.transactionPriceRequired}/>
                </div>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step9Component;

interface Step9Errors {
    previousTransactionPrice?: string;
    lastValuationPrice?: string;
    lastValuationPriceRequired?: string;
    lastValuationDateRequired?: string;
    transactionPriceRequired?: string;
    transactionDateRequired?: string;
    requiredFiles?: string;
}

export const step9IsValid = (form: WebForm, configuration: WebformConfiguration): boolean => {
    if (configuration.displayLastValuationPrice === VariableDisplay.Required) {
        if (isEmpty(form.lastValuationPrice)) {
            return false;
        } else if (isNaN(parseInt(form.lastValuationPrice!.toString()))
            || parseInt(form.lastValuationPrice!.toString()) < 0) {
            return false;
        }
    } else if (!isEmpty(form.lastValuationPrice)) {
        if (isNaN(parseInt(form.lastValuationPrice!.toString()))
            || parseInt(form.lastValuationPrice!.toString()) < 0) {
            return false;
        }
    } else if (configuration.displayLastValuationDate === VariableDisplay.Required && isNullOrUndefined(form.lastValuationDate)) {
        return false;
    } else if (configuration.displayTransactionPrice === VariableDisplay.Required) {
        if (isEmpty(form.previousTransactionPrice)) {
            return false;
        } else if (isNaN(parseInt(form.previousTransactionPrice!.toString()))
            || parseInt(form.previousTransactionPrice!.toString()) < 0) {
            return false;
        }
    } else if (!isEmpty(form.previousTransactionPrice)) {
        if (isNaN(parseInt(form.previousTransactionPrice!.toString()))
            || parseInt(form.previousTransactionPrice!.toString()) < 0) {
            return false;
        }
    } else if (configuration.displayTransactionDate === VariableDisplay.Required && isNullOrUndefined(form.previousTransactionDate)) {
        return false;
    }
    return true;
}
