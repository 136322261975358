import React, {createContext, Dispatch, FunctionComponent, useReducer} from 'react';
import {AppAction, AppState} from './types';
import {initialWebFormState, webFormReducer} from './webform/reducer';
import {initialLayoutState, layoutReducer} from './layout/reducer';
import {bankReducer, initialBankState} from './bank/reducer';
import {initialPaymentState, paymentReducer} from './payment/reducer';

const initialState: AppState = {
    webFormState: initialWebFormState,
    layoutState: initialLayoutState,
    bankState: initialBankState,
    paymentState: initialPaymentState,
};

export const AppContext = createContext<{
    state: AppState;
    dispatch: Dispatch<AppAction>;
}>({
    state: initialState,
    dispatch: () => null
});

const mainReducer = (
    {webFormState, layoutState, bankState, paymentState}: AppState,
    action: AppAction
) =>
    ({
        webFormState: webFormReducer(webFormState, action),
        layoutState: layoutReducer(layoutState, action),
        bankState: bankReducer(bankState, action),
        paymentState: paymentReducer(paymentState, action),
    });

const AppProvider: FunctionComponent = ({children}) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
