import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './step-0.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {WebForm} from '../../../store/webform/types';
import {Bank} from '../../../store/bank/types';
import {AppContext} from '../../../store/app-context';
import stadimLogo from '../../../../assets/images/stadim_logo.png';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import LanguageSelectorComponent, {LanguageSelectorLocation} from '../../../components/language-selector/language-selector.component';

const Step0Component: FunctionComponent<Step0Props> = ({onGetStartedClick, form, onChange}: Step0Props) => {

    const [consent, setConsent] = useState<boolean>(form.consent ? form.consent : false);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [errors, setErrors] = useState<Step0Errors>({});
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const [bank, setBank] = useState<Bank | undefined>(state.bankState.bank);

    useEffect(() => {
        if (state.bankState.bank) {
            setBank(state.bankState.bank);
        }
    }, [state.bankState.bank]);

    useEffect(() => {
        const updatedForm = Object.assign({}, form);
        updatedForm.consent = consent;
        onChange(updatedForm);
        if (consent) {
            setErrors({});
        }
    }, [consent]);

    const getStarted = () => {
        if (consent) {
            onGetStartedClick();
        } else {
            // t('consent_required_error')
            setErrors({consentRequired: 'consent_required_error'});
        }
    };

    const toggleMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    return (
        <div className={styles.content}>
            <div className={styles.leftPanel}>
                <div className={styles.wrapper}>
                    <div className={styles.topContainer}>
                        <img src={stadimLogo} className={styles.logo} alt="placeholder"/>
                        <LanguageSelectorComponent languageSelectorLocation={LanguageSelectorLocation.Body}/>
                    </div>
                    <div className={styles.textPanel}>
                        <p className={styles.titleText}>{t('intro_p_1')}</p>
                        <div className={styles.bodyText}>
                            <p className={styles.introParagraph}>{t('intro_p_2_1')} {t('intro_p_2_2')}</p>
                            <p>{t('intro_p_3_1')} {t('intro_p_3_2')}&nbsp;
                                {!showMoreInfo ?
                                    <span className={styles.link} onClick={() => toggleMoreInfo()}>
                                    {t('intro_p_3_3')}
                                </span> :
                                    <span className={styles.link} onClick={() => toggleMoreInfo()}>
                                    {t('intro_p_3_4')}
                                </span>
                                }
                            </p>
                            {showMoreInfo ?
                                <div className={styles.readMore}>
                                    <div>
                                        <p>{t('intro_info_1')}</p>
                                        <p>{t('intro_info_2')}</p>
                                        <p>{t('intro_info_3')}</p>
                                        <p>
                                            <Trans i18nKey="intro_info_4">placeholder
                                                <a className={styles.link}
                                                   href="mailto:privacy@stadim.be">privacy@stadim.be
                                                </a>
                                            </Trans>
                                        </p>
                                        <p>{t('intro_info_5')}</p>
                                    </div>
                                </div>
                                : null
                            }
                            <div className={styles.checkBoxCentered} onClick={() => setConsent(!consent)}>
                                <input className={styles.consentBox} type="checkbox" checked={consent} onChange={() => setConsent(!consent)}/>
                                <div className={styles.consentLabel}>{t('intro_consent')} </div>
                            </div>
                            {bank ? <div className={styles.text}>
                                <p><Trans i18nKey="intro_p_4_1">placeholder<a className={styles.link} href="https://www.stadim.be/nl/privacy" rel="noreferrer" target="_blank">placeholder</a>placeholder</Trans></p>
                                <p><Trans i18nKey="intro_p_4_2">placeholder<a className={styles.link} href={`mailto:${bank.supportEmail}`}>{bank.supportEmail.toLowerCase()}</a>placeholder</Trans></p>
                            </div> : null}
                            {errors.consentRequired ? <div className={styles.errorsPanel}>
                                {t(errors.consentRequired)}
                            </div> : null}
                            <div className={styles.buttonPanel}>
                                <div className={styles.button} onClick={() => getStarted()}>
                                    {t('Let\'s get started')} <ChevronRight className={styles.icon}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.opacityMask}></div>
            </div>
        </div>
    )
}

export default Step0Component;

export interface Step0Props {
    onGetStartedClick: () => void;
    form: WebForm;
    onChange: (form: WebForm) => void;
}

interface Step0Errors {
    consentRequired?: string;
}
