import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {enumToSelectionOptions} from '../../../components/dropdown/selection-option.util';
import {
    AttachmentType,
    BuildingCondition,
    CeilingHeightType,
    FloorCoveringType,
    Quality,
    WebForm
} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import {RuntimeConfig} from '../../../../config/runtime-config';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import {VariableDisplay} from '../../../types/variable-display';
import LabeledDropdownComponent from '../components/input/labeled-dropdown.component';
import {isNullOrUndefined} from '../../../utils/validation.util';
import LoaderComponent from '../../../components/loader/loader';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step7Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [ceilingHeightType, setCeilingHeightType] = useState<CeilingHeightType | undefined>(form.ceilingHeightType);
    const [floorCoveringType, setFloorCoveringType] = useState<FloorCoveringType | undefined>(form.floorCoveringType);
    const [kitchenQuality, setKitchenQuality] = useState<Quality | undefined>(form.kitchenQuality);
    const [bathroomQuality, setBathroomQuality] = useState<Quality | undefined>(form.bathroomQuality);
    const [buildingQuality, setBuildingQuality] = useState<Quality | undefined>(form.buildingQuality);
    const [overallBuildingCondition, setOverallBuildingCondition] = useState<BuildingCondition | undefined>(form.overallBuildingCondition);
    const qualityOptions = enumToSelectionOptions(Quality, t);
    const ceilingHeightOptions = enumToSelectionOptions(CeilingHeightType, t);
    const floorCoveringOptions = enumToSelectionOptions(FloorCoveringType, t);
    const buildingConditionOptions = enumToSelectionOptions(BuildingCondition, t);

    const [frontFacadeImages, setFrontFacadeImages] = useState<number>(0);
    const [rearFacadeImages, setRearFacadeImages] = useState<number>(0);
    const [livingRoomImages, setLivingRoomImages] = useState<number>(0);
    const [bathroomRoomImages, setBathroomRoomImages] = useState<number>(0);
    const [kitchenImages, setKitchenImages] = useState<number>(0);
    const [bedroomImages, setBedroomImages] = useState<number>(0);

    const [errors, setErrors] = useState<Step7Errors>({});
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    const attachmentTypes = [
        AttachmentType.FrontFacade, AttachmentType.RearFacade, AttachmentType.Bedroom, AttachmentType.Bathroom,
        AttachmentType.Kitchen, AttachmentType.LivingRoom, AttachmentType.Images
    ];

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step7IsValid(updatedForm) && (configuration.displayBuildingImagesUpload === VariableDisplay.Required ? checkIfAllImagesAreUploaded() : true);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
        setIsValid(scopedIsValid);
    }, [kitchenQuality, bathroomQuality, buildingQuality, ceilingHeightType, floorCoveringType, overallBuildingCondition,
        frontFacadeImages, rearFacadeImages, livingRoomImages, bathroomRoomImages, kitchenImages, bedroomImages])

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || !isNullOrUndefined(kitchenQuality)) {
            updatedForm.kitchenQuality = kitchenQuality;
        }
        if (skipValidation || !isNullOrUndefined(bathroomQuality)) {
            updatedForm.bathroomQuality = bathroomQuality;
        }
        if (skipValidation || !isNullOrUndefined(buildingQuality)) {
            updatedForm.buildingQuality = buildingQuality;
        }
        if (skipValidation || !isNullOrUndefined(ceilingHeightType)) {
            updatedForm.ceilingHeightType = ceilingHeightType;
        }
        if (skipValidation || !isNullOrUndefined(floorCoveringType)) {
            updatedForm.floorCoveringType = floorCoveringType;
        }
        if (skipValidation || !isNullOrUndefined(overallBuildingCondition)) {
            updatedForm.overallBuildingCondition = overallBuildingCondition;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (!overallBuildingCondition) {
            setErrors((prevState) => ({
                ...prevState,
                // t('overall_building_condition_required_error')
                overallBuildingCondition: 'overall_building_condition_required_error',
            }));
        }
        if (!kitchenQuality) {
            setErrors((prevState) => ({
                ...prevState,
                // t('kitchen_quality_required_error')
                kitchenQuality: 'kitchen_quality_required_error',
            }));
        }
        if (!bathroomQuality) {
            setErrors((prevState) => ({
                ...prevState,
                // t('bathroom_quality_required_error')
                bathroomQuality: 'bathroom_quality_required_error',
            }));
        }
        if (!buildingQuality) {
            setErrors((prevState) => ({
                ...prevState,
                // t('building_quality_required_error')
                buildingQuality: 'building_quality_required_error',
            }));
        }
        if (!ceilingHeightType) {
            setErrors((prevState) => ({
                ...prevState,
                // t('ceiling_height_required_error')
                ceilingHeightType: 'ceiling_height_required_error',
            }));
        }
        if (!floorCoveringType) {
            setErrors((prevState) => ({
                ...prevState,
                // t('floor_covering_required_error')
                floorCoveringType: 'floor_covering_required_error',
            }));
        }
        if (configuration.displayBuildingImagesUpload === VariableDisplay.Required && (!frontFacadeImages || !rearFacadeImages || !kitchenImages || !bathroomRoomImages || !bedroomImages || !livingRoomImages)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('images_required_error')
                requiredImages: 'images_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !isNullOrUndefined(form.kitchenQuality)
            && !isNullOrUndefined(form.bathroomQuality)
            && !isNullOrUndefined(form.buildingQuality)
            && !isNullOrUndefined(form.ceilingHeightType)
            && !isNullOrUndefined(form.floorCoveringType)
            && !isNullOrUndefined(form.overallBuildingCondition);
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onOverallBuildingConditionChanged = (optionValue: string) => {
        setOverallBuildingCondition(optionValue as BuildingCondition);
        setErrors((prevState) => ({
            ...prevState,
            overallBuildingCondition: undefined,
        }));
    };

    const onKitchenQualityChanged = (optionValue: string) => {
        setKitchenQuality(optionValue as Quality);
        setErrors((prevState) => ({
            ...prevState,
            kitchenQuality: undefined,
        }));
    };

    const onBathroomQualityChanged = (optionValue: string) => {
        setBathroomQuality(optionValue as Quality);
        setErrors((prevState) => ({
            ...prevState,
            bathroomQuality: undefined,
        }));
    };

    const onBuildingQualityChanged = (optionValue: string) => {
        setBuildingQuality(optionValue as Quality);
        setErrors((prevState) => ({
            ...prevState,
            buildingQuality: undefined,
        }));
    };

    const onCeilingHeightChanged = (optionValue: string) => {
        setCeilingHeightType(optionValue as CeilingHeightType);
        setErrors((prevState) => ({
            ...prevState,
            ceilingHeightType: undefined,
        }));
    };

    const onFloorCoveringChanged = (optionValue: string) => {
        setFloorCoveringType(optionValue as FloorCoveringType);
        setErrors((prevState) => ({
            ...prevState,
            floorCoveringType: undefined,
        }));
    };

    const numberOfFilesChanged = (type: AttachmentType, value: number) => {
        switch (type) {
            case AttachmentType.FrontFacade:
                setFrontFacadeImages(value);
                break;
            case AttachmentType.RearFacade:
                setRearFacadeImages(value);
                break;
            case AttachmentType.Bathroom:
                setBathroomRoomImages(value);
                break;
            case AttachmentType.Bedroom:
                setBedroomImages(value);
                break;
            case AttachmentType.Kitchen:
                setKitchenImages(value);
                break;
            case AttachmentType.LivingRoom:
                setLivingRoomImages(value);
                break;
        }
        if (frontFacadeImages > 0 && rearFacadeImages > 0 && livingRoomImages > 0 && bathroomRoomImages > 0 && kitchenImages > 0 && bedroomImages > 0) {
            setErrors((prevState) => ({
                ...prevState,
                requiredImages: undefined,
            }));
        }
    }

    const checkIfAllImagesAreUploaded = () => {
        if (RuntimeConfig.getEnvironment() !== 'local') {
            return frontFacadeImages > 0 && rearFacadeImages > 0 && livingRoomImages > 0 && bathroomRoomImages > 0 && kitchenImages > 0 && bedroomImages > 0;
        } else {
            return true;
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={7}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <LabeledDropdownComponent
                        id='overallBuildingCondition'
                        label={t('Select Building Condition')}
                        footnote={t('In general, we consider the condition of a normally maintained 15-year-old building to be average')}
                        display={VariableDisplay.Required}
                        value={overallBuildingCondition}
                        options={buildingConditionOptions}
                        valueChanged={onOverallBuildingConditionChanged}
                        addRanking={true}
                        errors={errors.overallBuildingCondition}/>
                    <LabeledDropdownComponent
                        id='kitchenQuality'
                        label={t('Select kitchen quality')}
                        display={VariableDisplay.Required}
                        value={kitchenQuality}
                        options={qualityOptions}
                        valueChanged={onKitchenQualityChanged}
                        addRanking={true}
                        toolTipMessages={[t('kitchen_high_quality'), t('kitchen_average_quality'), t('kitchen_very_low_quality')]}
                        errors={errors.kitchenQuality}/>
                    <LabeledDropdownComponent
                        id='bathroomQuality'
                        label={t('Select bathroom quality')}
                        display={VariableDisplay.Required}
                        value={bathroomQuality}
                        options={qualityOptions}
                        valueChanged={onBathroomQualityChanged}
                        addRanking={true}
                        toolTipMessages={[t('bathroom_high_quality'), t('bathroom_average_quality'), t('bathroom_very_low_quality')]}
                        errors={errors.bathroomQuality}/>
                    <LabeledDropdownComponent
                        id='ceilingHeightType'
                        label={t('Select ceiling height')}
                        footnote={t('An average ceiling height is 250cm')}
                        display={VariableDisplay.Required}
                        value={ceilingHeightType}
                        options={ceilingHeightOptions}
                        valueChanged={onCeilingHeightChanged}
                        addRanking={true}
                        errors={errors.ceilingHeightType}/>
                    <LabeledDropdownComponent
                        id='floorCoveringType'
                        label={t('Select floor covering')}
                        footnote={t('An average floor covering is laminate/ceramic tile 40x40cm')}
                        display={VariableDisplay.Required}
                        value={floorCoveringType}
                        options={floorCoveringOptions}
                        valueChanged={onFloorCoveringChanged}
                        addRanking={true}
                        errors={errors.floorCoveringType}/>
                    <LabeledDropdownComponent
                        id='buildingQuality'
                        label={t('Select the overall building quality')}
                        footnote={t('The overall quality takes into account all the qualitative characteristics of all the different rooms and building elements of the property')}
                        display={VariableDisplay.Required}
                        value={buildingQuality}
                        options={qualityOptions}
                        valueChanged={onBuildingQualityChanged}
                        addRanking={true}
                        errors={errors.buildingQuality}/>
                    <FileHandlerComponent attachmentTypes={attachmentTypes}
                                          display={configuration.displayBuildingImagesUpload}
                                          invalid={!!errors.requiredImages}
                                          onNumberOfFilesChanged={numberOfFilesChanged}
                                          label={t('Upload images')}
                                          errorMessage={errors.requiredImages}
                                          footNote={t('required_images')}/>
                </div>
                <RequiredErrorsBoxComponent
                    errors={errors.overallBuildingCondition || errors.kitchenQuality || errors.bathroomQuality
                    || errors.ceilingHeightType || errors.floorCoveringType || errors.buildingQuality || errors.requiredImages}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step7Component;

interface Step7Errors {
    overallBuildingCondition?: string;
    kitchenQuality?: string;
    bathroomQuality?: string;
    buildingQuality?: string;
    ceilingHeightType?: string;
    floorCoveringType?: string;
    requiredImages?: string;
}

export const step7IsValid = (form: WebForm): boolean => {
    return !isNullOrUndefined(form.overallBuildingCondition)
        && !isNullOrUndefined(form.kitchenQuality)
        && !isNullOrUndefined(form.bathroomQuality)
        && !isNullOrUndefined(form.buildingQuality)
        && !isNullOrUndefined(form.ceilingHeightType)
        && !isNullOrUndefined(form.floorCoveringType)
}
