import {RuntimeConfig} from '../../config/runtime-config';
import {AttachmentType} from '../store/webform/types';

export enum ApiUrls {
    //WEBFORM
    GetWebformBankInfo = '/webforms/{bankId}/info',
    GetWebformById = '/webforms/{bankId}/{invitationToken}',
    PutWebform = '/webforms/{bankId}/webforms/{webformTokenId}',
    GetPayment = '/payments/{bankId}/webform/{webformTokenId}/payment',
    GetPaymentUrl = '/payments/{bankId}/webform/{webformTokenId}/payment-url',
    GetTransaction = '/payments/{bankId}/payment/{paymentId}/transaction',
    SaveInvoiceDetails = '/payments/{bankId}/payment/{paymentId}/invoice-details',
    //ATTACHMENTS
    GetAttachments = '/webforms/{bankId}/webforms/{invitationToken}/attachments',
    PostNewAttachment = '/webforms/{bankId}/webforms/{invitationToken}/attachments/{attachmentType}',
    DeleteAttachmentByName = '/webforms/{bankId}/webforms/{invitationToken}/attachments/{attachmentType}/{attachmentName}',
    //MAP API
    GetMapShapes = '/webforms/getMapShapes',
    //LOGGING
    ClientLogging = '/webforms/client-logging',

}

export function buildBaseUrl(): string {
    const apiUrl = RuntimeConfig.getApiURL();
    return `${apiUrl}`;
}

export class UrlParamBuilder {
    private params: any = {};

    public addBankId(bankId: string): UrlParamBuilder {
        this.params['bankId'] = bankId;
        return this;
    }

    public addInvitationToken(invitationToken: string): UrlParamBuilder {
        this.params['invitationToken'] = invitationToken;
        return this;
    }

    public addWebformId(webformTokenId: string): UrlParamBuilder {
        this.params['webformTokenId'] = webformTokenId;
        return this;
    }

    public addAttachmentName(attachmentName: string): UrlParamBuilder {
        this.params['attachmentName'] = attachmentName;
        return this;
    }

    public addAttachmentType(attachmentType: AttachmentType): UrlParamBuilder {
        this.params['attachmentType'] = attachmentType;
        return this;
    }

    public addPaymentId(paymentId: string): UrlParamBuilder {
        this.params['paymentId'] = paymentId;
        return this;
    }

    public build(): any {
        return this.params;
    }
}
