import React, {FunctionComponent, useContext, useEffect} from 'react';
import styles from './toast-messsages.module.scss';
import {AppContext} from '../../store/app-context';
import ToastMessageComponent from './toast-message.component'
import {LayoutActionTypes} from '../../store/layout/actions';

const ToastMessagesComponent: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);

    useEffect(() => {
        if (state.layoutState.toasts.length > 0) {
            setTimeout(() => {
                    dispatch({type: LayoutActionTypes.RemoveToast, toastId: state.layoutState.toasts[0]?.id})
                }, 5000
            )
        }
    }, [state.layoutState.toasts]);

    return (
        <div className={styles.container}>
            {state.layoutState.toasts.map((toastMessage, index) => {
                return <div key={index} className={styles.toast}
                            onClick={() => dispatch({type: LayoutActionTypes.RemoveToast, toastId: toastMessage.id})}>
                    <ToastMessageComponent message={toastMessage.content} type={toastMessage.type}/>
                </div>;
            })}
        </div>
    )
}

export default ToastMessagesComponent;
