import {ToastMessage} from '../../types/toast';

export enum LayoutActionTypes {
    AddToast = 'Layout/AddToast',
    RemoveToast = 'Layout/RemoveToast',
}

export type LayoutActions =
    | { type: LayoutActionTypes.AddToast, toast: ToastMessage }
    | { type: LayoutActionTypes.RemoveToast, toastId: string };
