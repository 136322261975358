import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {MapShape} from '../../../types/map-shape';
import {WebForm} from '../../../store/webform/types';
import WebformApi from '../../../api/webform-api';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {ReactComponent as LocationIconComponent} from '../../../../assets/images/location.svg';
import CheckboxComponent from '../../../components/checkbox/checkbox.component';
import {RuntimeConfig} from '../../../../config/runtime-config';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import LoaderComponent from '../../../components/loader/loader';
import GoogleMapComponent from '../../../components/google/map/google-map.component';
import GoogleAutocompleteComponent from '../../../components/google/autocomplete/google-autocomplete.component';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {isNullOrUndefined} from '../../../utils/validation.util';
import Mapbox from '../../../components/mapbox/mapbox';
import StepHeaderComponent from '../components/step-header/step-header.component';
import {getMaxActiveStep} from '../web-form.page';

const Step1Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onChange,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          updateInProgress,
                                                      }: StepProps) => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [address, setAddress] = useState<string | undefined>(form.address || undefined);
    const [center, setCenter] = useState<Center | null>(form.longitude && !isNaN(parseFloat(form.longitude)) && form.latitude && !isNaN(parseFloat(form.latitude)) ? {
        lat: parseFloat(form.latitude),
        lng: parseFloat(form.longitude)
    } : null);
    const [zoom, setZoom] = useState<number>(18);
    const [mapShapes, setMapShapes] = useState<MapShape[] | undefined>(undefined);
    const [capakeys, setCapakeys] = useState<string[]>([]);
    const [isOnlyPartOfParcel, setIsOnlyPartOfParcel] = useState<boolean>(form.isOnlyPartOfParcel || false);
    const [hasEasements, setHasEasements] = useState<boolean>(form.hasEasements || false);
    const [errors, setErrors] = useState<Step1Errors>({});
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [isLocal, setIsLocal] = useState<boolean>(false);
    const [mapType, setMapType] = useState<MapType>(MapType.Map);

    useEffect(() => {
        if (RuntimeConfig.getEnvironment() === 'local') {
            setIsLocal(true);
        }

        if (form.address && !form.capakeys) {
            setCapakeys([]);
            onAddressChange(address);
            setAddress(address);
        }
    }, []);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        if (form.capakeys) {
            setCapakeys(form.capakeys?.split(',').filter(e => e != ''));
        }
    }, [form.capakeys]);

    useEffect(() => {
        if (capakeys && capakeys.length > 0) {
            const mapShape = mapShapes?.find(e => e.capakey.toString() === capakeys[0])
            if (mapShape && (mapShape.gewest === 'VLA' || mapShape.gewest === 'BRU' || mapShape.gewest === 'WAL')) {
                form.region = mapShape.gewest;
            }
        }
    }, [capakeys]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step1IsValid(updatedForm);
        if (scopedIsValid) {
            onChange(updatedForm, false);
        }
        setIsValid(scopedIsValid);
    }, [address, errors, capakeys, center, isOnlyPartOfParcel, hasEasements]);

    useEffect(() => {
        async function getMapShapes() {
            if (!center || !center.lat || !center.lng) return;
            const response = await WebformApi.getMapShapes(center?.lng, center?.lat);
            setMapShapes(response.shapes);
            if (!form.region) {
                const currentParcel = response.shapes.find(e => e.capakey.toString() === response.currentCapakey);
                if (currentParcel) {
                    form.region = currentParcel.gewest;
                }
            }
        }

        if (!isLocal) {
            getMapShapes();
        }
    }, [center]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || address) {
            updatedForm.address = address;
        }
        if (skipValidation || (center && center.lng && center.lat)) {
            updatedForm.longitude = center?.lng?.toString();
            updatedForm.latitude = center?.lat?.toString();
        }
        if (skipValidation || (capakeys && capakeys.length > 0)) {
            updatedForm.capakeys = capakeys.join(',');
        }

        updatedForm.isOnlyPartOfParcel = isOnlyPartOfParcel;
        updatedForm.hasEasements = hasEasements;

        return updatedForm;
    }

    const validateFields = () => {
        if (!address) {
            setErrors((prevState) => ({
                ...prevState,
                // t('address_required_error')
                addressRequired: 'address_required_error',
            }));
        }
        if (isLocal || (!isLocal && (!capakeys || capakeys.length === 0))) {
            setErrors((prevState) => ({
                ...prevState,
                // t('parcel_required_error')
                parcelRequired: 'parcel_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !isNullOrUndefined(form.address)
            && !isNullOrUndefined(form.capakeys)
            && !isNullOrUndefined(form.longitude)
            && !isNullOrUndefined(form.latitude);
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const loadMapShapes = async (lng: number, lat: number) => {
        if (!isLocal) {
            const response = await WebformApi.getMapShapes(lng, lat);
            await setMapShapes(response.shapes);
            return response.shapes;
        }
    }

    const onParcelClick = (capaKey: string) => {
        if (capaKey) {
            setCapakeys(prevState => {
                if (prevState?.includes(capaKey)) {
                    return prevState.filter(key => key !== capaKey);
                } else {
                    return [capaKey, ...prevState];
                }
            });
            setErrors(prevState => ({
                ...prevState,
                parcelRequired: undefined,
            }));
        }
    }

    const onAddressChange = (value?: string) => {
        if (value) {
            geocodeByAddress(value)
                .then(results => {
                    return getLatLng(results[0]);
                })
                .then(({lat, lng}) => {
                        setCenter({lat, lng})
                        setZoom(18)
                    }
                );
        }
    }

    const onOnlyPartOfParcelChange = (value: any) => {
        setIsOnlyPartOfParcel(value);
    }

    const onHasEasementsChange = (value: any) => {
        setHasEasements(value);
    }

    const onDragEnd = async (lng: number, lat: number) => {
        if (!isLocal) {
            const shapes = await loadMapShapes(lng, lat);
            setCapakeys(prevState => !shapes?.find(e => e.capakey.toString() === prevState[0])?.capakey ? [] : prevState);
            setCenter({lng, lat});
        }
    }

    const onAddressInputChange = (value: string) => {
        setAddress(value);
        setCapakeys([]);
        setIsOnlyPartOfParcel(false);
        if (value) {
            setErrors((prevState => ({
                ...prevState,
                addressRequired: undefined,
            })))
        }
    }

    const getParcelDisplayText = (): string => {
        if (capakeys.length > 1) {
            return t(' Multiple parcels selected');
        } else if (capakeys.length === 1) {
            return t('Parcel selected with ID: ') + capakeys[0];
        } else {
            return '';
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={1}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.addressRequired,
                        })}>
                            {t('Please enter your address below')}
                        </label>
                        {errors.addressRequired ?
                            <div className={styles.labelError}>{t(errors.addressRequired)}</div> : null}
                        <GoogleAutocompleteComponent
                            address={address}
                            onAddressChange={onAddressChange}
                            onAddressInputChange={onAddressInputChange}
                        />
                        {errors.parcelRequired ?
                            <div className={styles.labelError}>{t(errors.parcelRequired)}</div> : null}
                        {center && <div className={styles.mapSelection}>
                            <div className={styles.mapButtons}>
                                <div
                                    className={`${styles.button} ${mapType === MapType.Satellite ? styles.negative : ''}`}
                                    onClick={() => setMapType(MapType.Map)}>{t('Map')}</div>
                                <div className={`${styles.button} ${mapType === MapType.Map ? styles.negative : ''}`}
                                     onClick={() => setMapType(MapType.Satellite)}>{t('Satellite')}</div>
                            </div>
                        </div>}
                        {center && mapType === MapType.Satellite && <GoogleMapComponent
                            zoom={zoom}
                            longitude={center.lng}
                            latitude={center.lat}
                            shapes={mapShapes}
                            selectedCapakeys={capakeys}
                            onDragEnd={onDragEnd}
                            onParcelClick={onParcelClick}
                            onZoom={(updatedZoom) => setZoom(updatedZoom)}
                        />}
                        {center && mapType === MapType.Map && <Mapbox
                            zoom={zoom - 1}
                            longitude={center.lng}
                            latitude={center.lat}
                            shapes={mapShapes}
                            highlightedShapeIds={capakeys}
                            onDragEnd={onDragEnd}
                            onParcelClick={onParcelClick}
                            onZoom={(updatedZoom) => setZoom(updatedZoom)}
                        />}
                        {!center &&
                        <div
                            className={styles.mapPlaceholder}>
                            <LocationIconComponent className={styles.locationIcon}/>
                            <div className={styles.label}>
                                {t('Please select an address above')}
                            </div>
                        </div>}
                        {center &&
                        <div className={styles.parcelSelectionLabel}>
                            {getParcelDisplayText()}
                        </div>}
                    </div>

                    {center &&
                    <>
                        <div className={styles.formField}>
                            <div className={`${styles.label} ${styles.alignLeft} ${styles.verticallyCentered}`}
                                 style={{paddingBottom: '0px'}}>
                            <span style={{paddingRight: '10px'}}>
                                <CheckboxComponent
                                    value={isOnlyPartOfParcel}
                                    onSelectionChanged={(value) => onOnlyPartOfParcelChange(value)}/>
                            </span>
                                {t('I’m not the only owner of the selected parcel(s). I own a land share.')}
                            </div>
                        </div>
                        <div className={styles.formField}>
                            <div className={`${styles.label} ${styles.alignLeft} ${styles.verticallyCentered}`}
                                 style={{paddingBottom: '0px'}}>
                            <span style={{paddingRight: '10px'}}>
                                <CheckboxComponent
                                    value={hasEasements}
                                    onSelectionChanged={(value) => onHasEasementsChange(value)}/>
                            </span>
                                {t('The property is burdened by easements')}
                            </div>
                        </div>
                    </>
                    }
                </div>
                <RequiredErrorsBoxComponent errors={errors.addressRequired || errors.parcelRequired}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step1Component;

interface Center {
    lng: number;
    lat: number;
}

enum MapType {
    Map = 'map',
    Satellite = 'satellite',
}

interface Step1Errors {
    addressRequired?: string;
    parcelRequired?: string;
}

export const step1IsValid = (form: WebForm): boolean => {
    return form.address !== undefined
        && form.address !== null
        && form.address !== ''
        && form.address !== null
        && form.latitude !== undefined
        && form.latitude !== null
        && form.longitude !== undefined
        && form.longitude !== null
        && (RuntimeConfig.getEnvironment() !== 'local' ? form.capakeys !== undefined && form.capakeys !== '' : true);
    // && (form.capakeys !== undefined && form.capakeys !== '');
}
