import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './step-header.module.scss';
import {ReactComponent as ChevronRight} from '../../../../../assets/images/chevron_right.svg';
import ProgressBarComponent from './progress-bar.component';
import {conditionalClassLister} from '../../../../utils/class-helpers';

const StepHeaderComponent: FunctionComponent<ownProps> = ({step, totalSteps, maxActiveStep, onNextClick, onPreviousClick}) => {
    const {t} = useTranslation();

    const stepTitles = [
        t('Location'),
        t('Property Type'),
        t('Dimensions'),
        t('Rooms'),
        t('Energy'),
        t('Amenities'),
        t('Building Condition'),
        t('Renovation'),
        t( 'Financial'),
        t('Additional Information'),
        t('Invoice Details'),
        t('Payment'),
    ]

    const nextButtonClasses = conditionalClassLister(styles)({
        navIcon: true,
        disabled: maxActiveStep <= step,
    });

    const onNext = () => {
        if (maxActiveStep > step) {
            onNextClick();
        }
    };

    return (
        <div className={styles.stepHeader}>
            <div className={styles.topPanel}>
                <div className={styles.titles}>
                    <div className={styles.stepsIndicator}>{t('Step {{step}} of {{totalSteps}}', {step, totalSteps})}</div>
                    <div className={styles.stepTitle}>{stepTitles[step-1]}</div>
                </div>
                <div className={styles.navControls}>
                    <ChevronRight className={`${styles.navIcon} ${styles.rotate}`} onClick={() => onPreviousClick()}/>
                    <ChevronRight className={nextButtonClasses} onClick={() => onNext()} />
                </div>
            </div>
            <div className={styles.progressBarPanel}>
                <ProgressBarComponent percentage={step / totalSteps * 100}/>
            </div>
        </div>
    );
}

export default StepHeaderComponent;
interface ownProps {
    step: number;
    totalSteps: number;
    maxActiveStep: number;
    onNextClick: () => void;
    onPreviousClick: () => void;
}




