export class HTMLDateUtils {
    public static fromISOtoHTMLString(ISODate: string | undefined | null): string {
        if (ISODate) {
            const date = new Date(ISODate);
            return date.getFullYear().toString() + '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        } else {
            return '';
        }
    }

    public static fromHTMLtoISOString(HTMLDate: string): string {
        const date = new Date(HTMLDate);
        return date.toISOString();
    }
}
