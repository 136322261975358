export const classLister = (styleObject: { [key: string]: string }): any => (...classList: any[]) =>
    classList.reduce((list, myClass) => {
        let output = list;
        if (styleObject[myClass]) {
            if (list) output += ' ';
            output += styleObject[myClass];
        }
        return output;
    }, '');

export const conditionalClassLister = (styleObject: { [key: string]: string }): any => (toJoin: any) => {
    const classList = Object.getOwnPropertyNames(toJoin).map((x) => {
        const value = toJoin[x];
        if (!value) {
            return '';
        }
        return x;
    });

    return classLister(styleObject)(...classList);
};
