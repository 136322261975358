import {Http} from '../http/http';
import {ApiUrls, UrlParamBuilder} from './api-urls';
import {AttachmentType, WebForm} from '../store/webform/types';
import {GeoLocation, MapShapeResponse} from '../types/responses/map-shape-response';
import {MapShape} from '../types/map-shape';
import {Bank} from '../store/bank/types';
import {Attachment, AttachmentsResponse} from '../types/responses/attachments-response';
import {LoggerRequest} from '../types/requests/logger-request';

class WebformApi {

    public static async getWebformByTokenId(bankId: string, invitationToken: string): Promise<WebForm> {
        return Http.get<WebForm>(
            ApiUrls.GetWebformById,
            new UrlParamBuilder().addInvitationToken(invitationToken).addBankId(bankId).build(),
        )
    }

    public static async updateWebform(bankId: string, webform: WebForm): Promise<WebForm> {
        if (webform.id !== 'DEMO-TOKEN') {
            return await Http.put<WebformUpdateRequest, WebForm>(ApiUrls.PutWebform,
                {webform: webform},
                new UrlParamBuilder().addBankId(bankId).addWebformId(webform.id).build(),
            )
        } else {
            return webform;
        }
    }

    public static async getMapShapes(long: number, lat: number): Promise<{ shapes: MapShape[], currentCapakey: string }> {
        return Http.post(ApiUrls.GetMapShapes, {longitude: long, latitude: lat}).then((response) => {
            const mapShapeResponses = (response as MapShapeResponse).locations as unknown as MapShape[];
            mapShapeResponses.map(mapShapeResponse => {
                mapShapeResponse.geojson = JSON.parse((mapShapeResponse as unknown as GeoLocation).geojson);
                return mapShapeResponse;
            });
            return {shapes: mapShapeResponses, currentCapakey: (response as MapShapeResponse).currentCapakey};
        })
    }

    public static async getAttachments(bankId: string, invitationToken: string, attachmentTypes: AttachmentType[]): Promise<Attachment[]> {
        const response = await Http.post<{attachmentTypes: string[]}, AttachmentsResponse>(
            ApiUrls.GetAttachments,
            {attachmentTypes},
            new UrlParamBuilder().addInvitationToken(invitationToken).addBankId(bankId).build()
        );
        return response.attachments;
    }

    public static async uploadAttachments(bankId: string, invitationToken: string, file: File, attachmentType: AttachmentType): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        return await Http.post(
            ApiUrls.PostNewAttachment,
            formData,
            new UrlParamBuilder().addInvitationToken(invitationToken).addBankId(bankId).addAttachmentType(attachmentType).build(),
        );
    }

    public static async deleteAttachment(bankId: string, invitationToken: string, filename: string, attachmentType: AttachmentType): Promise<void> {
        return await Http.delete(
            ApiUrls.DeleteAttachmentByName,
            new UrlParamBuilder().addBankId(bankId).addInvitationToken(invitationToken).addAttachmentName(filename).addAttachmentType(attachmentType).build(),
        );
    }

    public static async getBank(bankId: string): Promise<Bank> {
        return await Http.get<Bank>(ApiUrls.GetWebformBankInfo, new UrlParamBuilder().addBankId(bankId).build());
    }

    public static async logToServer(request: LoggerRequest): Promise<void> {
        return await Http.post(ApiUrls.ClientLogging, request);
    }
}

export default WebformApi;

export interface WebformUpdateRequest {
    webform: WebForm;
}
