import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './language-selector.module.scss';
import {conditionalClassLister} from '../../utils/class-helpers';

const LanguageSelectorComponent: FunctionComponent<OwnProps> = ({languageSelectorLocation}: OwnProps) => {
    const {i18n} = useTranslation();

    const selectLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    const languagesStyles = conditionalClassLister(styles)({
        languages: true,
        footer: languageSelectorLocation === LanguageSelectorLocation.Footer,
        body: languageSelectorLocation === LanguageSelectorLocation.Body,
    })

    return (
        <div className={languagesStyles}>
            <div className={styles.language} onClick={() => selectLanguage('en')}>EN</div>
            |
            <div className={styles.language} onClick={() => selectLanguage('nl')}>NL</div>
            |
            <div className={styles.language} onClick={() => selectLanguage('fr')}>FR</div>
        </div>
    )
}
export default LanguageSelectorComponent;

type OwnProps = {
    languageSelectorLocation: LanguageSelectorLocation;
}

export enum LanguageSelectorLocation {
    Footer = 'footer',
    Body = 'body'
}
