import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        backend:{
            loadPath:'/locales/{{lng}}.json'
        },
        nsSeparator: false,
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
        returnEmptyString: false,
    })
    .catch(() => null);

export default i18n;
