import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {SelectionOption} from '../../../components/dropdown/dropdown.component';
import {enumToSelectionOptions} from '../../../components/dropdown/selection-option.util';
import {
    AttachmentType,
    EpcCategory,
    FlatRoofInsulation,
    FloorOnCrawlBasementInsulation,
    FloorOnFullGroundInsulation,
    FloorType,
    HeatingElements,
    PrimaryHeatProduction,
    RoofType,
    SlopedRoofInsulation,
    VentilationSystem,
    WallInsulation,
    WaterHeating,
    WebForm,
    WindowIsolationType
} from '../../../store/webform/types';
import EPCSliderComponent from '../components/epc/epc-slider.component';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import {VariableDisplay} from '../../../types/variable-display';
import LabeledDropdownComponent from '../components/input/labeled-dropdown.component';
import {isNullOrUndefined} from '../../../utils/validation.util';
import LoaderComponent from '../../../components/loader/loader';
import {getMaxActiveStep, isPartOfBuilding} from '../web-form.page';
import {WebformConfiguration} from '../../../store/bank/types';
import LabeledMultiSelectComponent from '../components/input/labeled-multi-select.component';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step5Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [selectedWindowIsolationTypeOptions, setSelectedWindowIsolationTypeOptions] = useState<SelectionOption[]>([]);
    const [selectedHeatingElementsOptions, setSelectedHeatingElementsOptions] = useState<SelectionOption[]>([]);
    const [selectedPrimaryHeatProductionOptions, setSelectedPrimaryHeatProductionOptions] = useState<SelectionOption[]>([]);
    const [selectedWaterHeatingOptions, setSelectedWaterHeatingOptions] = useState<SelectionOption[]>([]);
    const [epcScore, setEpcScore] = useState<string>(form.epcScore ? form.epcScore.toString() : '');
    const [epcCategory, setEpcCategory] = useState<EpcCategory | undefined | null>(form.epcCategory);
    const [errors, setErrors] = useState<Step5Errors>({});
    const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [extraEPCQuestionsDisplay, setExtraEPCQuestionsDisplay] = useState<VariableDisplay>(VariableDisplay.Hidden);
    const [ventilationSystem, setVentilationSystem] = useState<VentilationSystem | undefined | null>(form.ventilationSystem);
    const [flatRoofInsulation, setFlatRoofInsulation] = useState<FlatRoofInsulation | undefined | null>(form.flatRoofInsulation);
    const [slopedRoofInsulation, setSlopedRoofInsulation] = useState<SlopedRoofInsulation | undefined | null>(form.slopedRoofInsulation);
    const [floorType, setFloorType] = useState<FloorType | undefined | null>(form.floorType);
    const [floorOnFullGroundInsulation, setFloorOnFullGroundInsulation] = useState<FloorOnFullGroundInsulation | undefined | null>(form.floorOnFullGroundInsulation);
    const [floorOnCrawlBasementInsulation, setFloorOnCrawlBasementInsulation] = useState<FloorOnCrawlBasementInsulation | undefined | null>(form.floorOnCrawlBasementInsulation);
    const [wallInsulation, setWallInsulation] = useState<WallInsulation | undefined | null>(form.wallInsulation);

    const primaryHeatProductionOptions = enumToSelectionOptions(PrimaryHeatProduction, t);
    const windowIsolationOptions = enumToSelectionOptions(WindowIsolationType, t);
    const heatingOptions = enumToSelectionOptions(HeatingElements, t);
    const waterHeatingOptions = enumToSelectionOptions(WaterHeating, t);
    const ventilationSystemOptions = enumToSelectionOptions(VentilationSystem, t);
    const flatRoofInsulationOptions = enumToSelectionOptions(FlatRoofInsulation, t);
    const slopedRoofInsulationOptions = enumToSelectionOptions(SlopedRoofInsulation, t);
    const floorTypeOptions = enumToSelectionOptions(FloorType, t);
    const floorOnFullGroundInsulationOptions = enumToSelectionOptions(FloorOnFullGroundInsulation, t);
    const floorOnCrawlBasementInsulationOptions = enumToSelectionOptions(FloorOnCrawlBasementInsulation, t);
    const wallInsulationOptions = enumToSelectionOptions(WallInsulation, t);
    const {roofType} = form;

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const initialHeatingOptions: SelectionOption[] = [];
        if (form.heatingElements && form.heatingElements.length > 0) {
            form.heatingElements.forEach(type => {
                const option = heatingOptions.find(o => o.value === type);
                if (option) {
                    initialHeatingOptions.push(option);
                }
            });
        }

        const initialEnergyProductionOptions: SelectionOption[] = [];
        if (form.primaryHeatProduction && form.primaryHeatProduction.length > 0) {
            form.primaryHeatProduction.forEach(type => {
                const option = primaryHeatProductionOptions.find(o => o.value === type);
                if (option) {
                    initialEnergyProductionOptions.push(option);
                }
            });
        }

        const initialWaterHeatingOptions: SelectionOption[] = [];
        if (form.waterHeating && form.waterHeating.length > 0) {
            form.waterHeating.forEach(type => {
                const option = waterHeatingOptions.find(o => o.value === type);
                if (option) {
                    initialWaterHeatingOptions.push(option);
                }
            });
        }

        if ((!form.epcScore && !form.epcCategory) &&
            (form.ventilationSystem || form.flatRoofInsulation || form.slopedRoofInsulation || form.floorType
                || form.floorOnFullGroundInsulation || form.floorOnCrawlBasementInsulation || form.wallInsulation)) {
            setExtraEPCQuestionsDisplay(configuration.displayEpc);
        }

        const initialWindowIsolationTypeOptions: SelectionOption[] = [];
        if (form.windowIsolationType) {
            const option = windowIsolationOptions.find(o => o.value === form.windowIsolationType);
            if (option) {
                initialWindowIsolationTypeOptions.push(option);
            }
        }

        setSelectedHeatingElementsOptions(initialHeatingOptions);
        setSelectedPrimaryHeatProductionOptions(initialEnergyProductionOptions);
        setSelectedWaterHeatingOptions(initialWaterHeatingOptions);
        setSelectedWindowIsolationTypeOptions(initialWindowIsolationTypeOptions);
    }, []);

    useEffect(() => {
        if (errors.epc && configuration.displayEpc === VariableDisplay.Required && !epcScore && !epcCategory &&
            !errors.ventilationSystem && !errors.flatRoofInsulation && !errors.slopedRoofInsulation &&
            !errors.floorType && !errors.floorOnFullGroundInsulation && !errors.floorOnCrawlBasementInsulation && !errors.wallInsulation) {
            setErrors((prevState) => ({
                ...prevState,
                epc: undefined,
            }));
        }
    }, [errors]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step5IsValid(updatedForm, configuration) && (configuration.displayEpcReportUpload === VariableDisplay.Required ? numberOfFiles > 0 : true);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
        setIsValid(scopedIsValid);
    }, [
        selectedWindowIsolationTypeOptions, epcScore, selectedHeatingElementsOptions, selectedPrimaryHeatProductionOptions, selectedWaterHeatingOptions, epcCategory, numberOfFiles,
        ventilationSystem, flatRoofInsulation, slopedRoofInsulation, floorType, floorOnFullGroundInsulation, floorOnCrawlBasementInsulation, wallInsulation
    ]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        updatedForm.epcCategory = epcCategory ? epcCategory : null;
        updatedForm.epcScore = !isNaN(parseInt(epcScore)) ? parseInt(epcScore) : null;
        if (selectedWindowIsolationTypeOptions.length === 1) {
            updatedForm.windowIsolationType = WindowIsolationType[selectedWindowIsolationTypeOptions[0].key as keyof typeof WindowIsolationType];
        } else if (skipValidation && selectedWindowIsolationTypeOptions.length === 0) {
            updatedForm.windowIsolationType = undefined;
        }
        if (skipValidation || selectedHeatingElementsOptions.length > 0) {
            updatedForm.heatingElements = selectedHeatingElementsOptions.map(option => HeatingElements[option.value! as keyof typeof HeatingElements]);
        }
        if (skipValidation || selectedPrimaryHeatProductionOptions.length > 0) {
            updatedForm.primaryHeatProduction = selectedPrimaryHeatProductionOptions.map(option => PrimaryHeatProduction[option.value! as keyof typeof PrimaryHeatProduction]);
        }
        if (skipValidation || selectedWaterHeatingOptions.length > 0) {
            updatedForm.waterHeating = selectedWaterHeatingOptions.map(option => WaterHeating[option.value! as keyof typeof WaterHeating]);
        }
        // set on skipValidation or if there is a valid value
        if (skipValidation || !isNullOrUndefined(ventilationSystem)) {
            updatedForm.ventilationSystem = ventilationSystem;
            // if there is no value, but epcCategory is known, we reset the field to null anyway
        } else if (isNullOrUndefined(ventilationSystem) && epcCategory) {
            updatedForm.ventilationSystem = null;
        }
        if (skipValidation || !isNullOrUndefined(flatRoofInsulation)) {
            updatedForm.flatRoofInsulation = flatRoofInsulation;
        } else if (isNullOrUndefined(flatRoofInsulation) && epcCategory) {
            updatedForm.flatRoofInsulation = null;
        }
        if (skipValidation || !isNullOrUndefined(slopedRoofInsulation)) {
            updatedForm.slopedRoofInsulation = slopedRoofInsulation;
        } else if (isNullOrUndefined(slopedRoofInsulation) && epcCategory) {
            updatedForm.slopedRoofInsulation = null;
        }
        if (skipValidation || !isNullOrUndefined(floorType)) {
            updatedForm.floorType = floorType;
        } else if (isNullOrUndefined(floorType) && epcCategory) {
            updatedForm.floorType = null;
        }
        if (skipValidation || !isNullOrUndefined(floorOnFullGroundInsulation)) {
            updatedForm.floorOnFullGroundInsulation = floorOnFullGroundInsulation;
        } else if (isNullOrUndefined(floorOnFullGroundInsulation) && epcCategory) {
            updatedForm.floorOnFullGroundInsulation = null;
        }
        if (skipValidation || !isNullOrUndefined(floorOnCrawlBasementInsulation)) {
            updatedForm.floorOnCrawlBasementInsulation = floorOnCrawlBasementInsulation;
        } else if (isNullOrUndefined(floorOnCrawlBasementInsulation) && epcCategory) {
            updatedForm.floorOnCrawlBasementInsulation = null;
        }
        if (skipValidation || !isNullOrUndefined(wallInsulation)) {
            updatedForm.wallInsulation = wallInsulation;
        } else if (isNullOrUndefined(wallInsulation) && epcCategory) {
            updatedForm.wallInsulation = null;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (!selectedPrimaryHeatProductionOptions || selectedPrimaryHeatProductionOptions.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('primary_heating_production_required_error')
                primaryHeatProduction: 'primary_heating_production_required_error',
            }));
        }
        if (!selectedHeatingElementsOptions || selectedHeatingElementsOptions.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('heating_element_required_error')
                heatingElements: 'heating_element_required_error',
            }));
        }
        if (!selectedWaterHeatingOptions || selectedWaterHeatingOptions.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('water_heating_required_error')
                waterHeating: 'water_heating_required_error',
            }));
        }
        if (!selectedWindowIsolationTypeOptions || selectedWindowIsolationTypeOptions.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('window_isolation_required_error')
                windowIsolationType: 'window_isolation_required_error',
            }));
        }
        if (configuration.displayEpcReportUpload === VariableDisplay.Required && numberOfFiles === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('files_required_error')
                requiredFiles: 'files_required_error',
            }));
        }
        if (configuration.displayEpc === VariableDisplay.Required) {
            if (!epcScore && !epcCategory) {
                let hasExtraQuestionsAnswered = true;
                if (!ventilationSystem) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('ventilation_system_required_error')
                        ventilationSystem: 'ventilation_system_required_error',
                    }));
                }
                if ((roofType === RoofType.Flat || roofType === RoofType.Complex) && !flatRoofInsulation) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('flat_roof_insulation_required_error')
                        flatRoofInsulation: 'flat_roof_insulation_required_error',
                    }));
                }
                if ((roofType === RoofType.Hip || roofType === RoofType.Gable || roofType === RoofType.Complex) && !slopedRoofInsulation) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('sloped_roof_insulation_required_error')
                        slopedRoofInsulation: 'sloped_roof_insulation_required_error',
                    }));
                }
                if (!floorType) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('floor_type_required_error')
                        floorType: 'floor_type_required_error',
                    }));
                }
                if ((floorType === FloorType.OnFullGround || floorType === FloorType.Both) && !floorOnFullGroundInsulation) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('floor_on_full_ground_insulation_required_error')
                        floorOnFullGroundInsulation: 'floor_on_full_ground_insulation_required_error',
                    }));
                }
                if ((floorType === FloorType.OnCrawlBasement || floorType === FloorType.Both) && !floorOnCrawlBasementInsulation) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('floor_on_crawl_basement_insulation_required_error')
                        floorOnCrawlBasementInsulation: 'floor_on_crawl_basement_insulation_required_error',
                    }));
                }
                if (!wallInsulation) {
                    hasExtraQuestionsAnswered = false;
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('wall_insulation_required_error')
                        wallInsulation: 'wall_insulation_required_error',
                    }));
                }
                if (!hasExtraQuestionsAnswered) {
                    setErrors((prevState) => ({
                        ...prevState,
                        // t('epc_required_error')
                        epc: 'epc_required_error',
                    }));
                }
            }
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        if (!form.primaryHeatProduction
            || !form.waterHeating
            || !form.heatingElements
            || !form.windowIsolationType) {
            return false;
        }
        if (configuration.displayEpc === VariableDisplay.Required) {
            if (!isNullOrUndefined(form.epcScore) && form.epcCategory) {
                return true;
            }

            if (!form.ventilationSystem
                || !form.floorType
                || !form.wallInsulation
                || ((form.roofType === RoofType.Flat || form.roofType === RoofType.Complex) && !form.flatRoofInsulation)
                || (form.roofType !== RoofType.Flat && !form.slopedRoofInsulation)
                || ((form.floorType === FloorType.OnFullGround || form.floorType === FloorType.Both) && !form.floorOnFullGroundInsulation)
                || (form.floorType !== FloorType.OnFullGround && !form.floorOnCrawlBasementInsulation)) {
                return false
            }
        }

        return true
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onEpcScoreChanged = (score: string): void => {
        setEpcScore(score);
        if (score) {
            clearExtraEpcQuestions();
            setErrors((prevState) => ({
                ...prevState,
                epc: undefined,
            }));
        }
    }

    const onEpcCategoryChanged = (category?: EpcCategory): void => {
        setEpcCategory(category);
        if (epcCategory) {
            clearExtraEpcQuestions();
            setErrors((prevState) => ({
                ...prevState,
                epc: undefined,
            }));
        }
    }

    const onEpcUnknown = () => {
        setEpcScore('');
        setEpcCategory(undefined);
        setExtraEPCQuestionsDisplay(configuration.displayEpc);
    }

    const clearExtraEpcQuestions = () => {
        setExtraEPCQuestionsDisplay(VariableDisplay.Hidden);
        setVentilationSystem(null);
        setFlatRoofInsulation(null);
        setSlopedRoofInsulation(null);
        setFloorType(null);
        setFloorOnFullGroundInsulation(null);
        setFloorOnCrawlBasementInsulation(null);
        setWallInsulation(null);
        setErrors((prevState) => ({
            ...prevState,
            ventilationSystem: undefined,
            flatRoofInsulation: undefined,
            slopedRoofInsulation: undefined,
            floorType: undefined,
            floorOnFullGroundInsulation: undefined,
            floorOnCrawlBasementInsulation: undefined,
            wallInsulation: undefined,
        }))
    }

    const onPrimaryHeatProductionChanged = (options: SelectionOption[]) => {
        setSelectedPrimaryHeatProductionOptions(options);
        if (options.length > 0) {
            setErrors((prevState) => ({
                ...prevState,
                primaryHeatProduction: undefined,
            }));
        }
    };

    const onWaterHeatingChanged = (options: SelectionOption[]) => {
        setSelectedWaterHeatingOptions(options);
        if (options.length > 0) {
            setErrors((prevState) => ({
                ...prevState,
                waterHeating: undefined,
            }));
        }
    };

    const onVentilationSystemChanged = (optionValue: string) => {
        setVentilationSystem(optionValue as VentilationSystem);
        setErrors((prevState) => ({
            ...prevState,
            ventilationSystem: undefined,
        }));
    };

    const onFlatRoofInsulationChanged = (optionValue: string) => {
        setFlatRoofInsulation(optionValue as FlatRoofInsulation);
        setErrors((prevState) => ({
            ...prevState,
            flatRoofInsulation: undefined,
        }));
    };

    const onSlopedRoofInsulationChanged = (optionValue: string) => {
        setSlopedRoofInsulation(optionValue as SlopedRoofInsulation);
        setErrors((prevState) => ({
            ...prevState,
            slopedRoofInsulation: undefined,
        }));
    };

    const onFloorTypeChanged = (optionValue: string) => {
        const selectedOption = optionValue as FloorType;
        setFloorType(selectedOption);

        let clearFloorOnFullGroundInsulationError = false;
        let clearFloorOnCrawlBasementInsulationError = false;
        if (selectedOption === FloorType.OnFullGround) {
            setFloorOnCrawlBasementInsulation(null);
            clearFloorOnCrawlBasementInsulationError = true;
        }
        if (selectedOption === FloorType.OnCrawlBasement) {
            setFloorOnFullGroundInsulation(null);
            clearFloorOnFullGroundInsulationError = true;
        }
        setErrors((prevState) => ({
            ...prevState,
            floorType: undefined,
            floorOnCrawlBasementInsulation: clearFloorOnCrawlBasementInsulationError ? undefined : prevState.floorOnCrawlBasementInsulation,
            floorOnFullGroundInsulation: clearFloorOnFullGroundInsulationError ? undefined : prevState.floorOnFullGroundInsulation,
        }));
    };

    const onFloorOnFullGroundInsulationChanged = (optionValue: string) => {
        setFloorOnFullGroundInsulation(optionValue as FloorOnFullGroundInsulation);
        setErrors((prevState) => ({
            ...prevState,
            floorOnFullGroundInsulation: undefined,
        }));
    };

    const onFloorOnCrawlBasementChanged = (optionValue: string) => {
        setFloorOnCrawlBasementInsulation(optionValue as FloorOnCrawlBasementInsulation);
        setErrors((prevState) => ({
            ...prevState,
            floorOnCrawlBasementInsulation: undefined,
        }));
    };

    const onWallInsulationChanged = (optionValue: string) => {
        setWallInsulation(optionValue as WallInsulation);
        setErrors((prevState) => ({
            ...prevState,
            wallInsulation: undefined,
        }));
    };

    const onWindowIsolationTypeChanged = (options: SelectionOption[]) => {
        setSelectedWindowIsolationTypeOptions(options);
        if (options.length > 0) {
            setErrors((prevState) => ({
                ...prevState,
                windowIsolationType: undefined,
            }));
        }
    };

    const onHeatingElementsChanged = (options: SelectionOption[]) => {
        setSelectedHeatingElementsOptions(options);
        if (options.length > 0) {
            setErrors((prevState) => ({
                ...prevState,
                heatingElements: undefined,
            }));
        }
    };

    const filesChanged = (value: number) => {
        setNumberOfFiles(value);
        if (value > 0) {
            setErrors((prevState) => ({
                ...prevState,
                requiredFiles: undefined,
            }));
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={5}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    {configuration.displayEpc !== VariableDisplay.Hidden && <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.epc,
                        })}>
                            {t('Select EPC')}
                            {configuration.displayEpc === VariableDisplay.Optional &&
                            <div className={styles.labelInfo}>{t('Optional')}</div>}
                        </label>
                        {errors.epc && <div className={styles.labelError}>{t(errors.epc)}</div>}
                        <EPCSliderComponent
                            regionType={form.region || 'VLA'}
                            score={epcScore}
                            category={epcCategory ? epcCategory : undefined}
                            updateScore={(score: string) => onEpcScoreChanged(score)}
                            updateCategory={(category?: EpcCategory) => onEpcCategoryChanged(category)}
                            markEpcAsUnknown={onEpcUnknown}
                        />
                    </div>}
                    <LabeledDropdownComponent
                        id='ventilationSystem'
                        label={t('Select ventilation system')}
                        display={extraEPCQuestionsDisplay}
                        value={ventilationSystem ? ventilationSystem : undefined}
                        options={ventilationSystemOptions}
                        valueChanged={onVentilationSystemChanged}
                        errors={errors.ventilationSystem}/>
                    <LabeledDropdownComponent
                        id='flatRoofInsulation'
                        label={t('Select flat roof insulation')}
                        display={roofType === RoofType.Flat || roofType === RoofType.Complex ? extraEPCQuestionsDisplay : VariableDisplay.Hidden}
                        value={flatRoofInsulation ? flatRoofInsulation : undefined}
                        options={flatRoofInsulationOptions}
                        valueChanged={onFlatRoofInsulationChanged}
                        errors={errors.flatRoofInsulation}/>
                    <LabeledDropdownComponent
                        id='slopedRoofInsulation'
                        label={t('Select sloped roof insulation')}
                        display={roofType === RoofType.Hip || roofType === RoofType.Gable || roofType === RoofType.Complex ? extraEPCQuestionsDisplay : VariableDisplay.Hidden}
                        value={slopedRoofInsulation ? slopedRoofInsulation : undefined}
                        options={slopedRoofInsulationOptions}
                        valueChanged={onSlopedRoofInsulationChanged}
                        errors={errors.slopedRoofInsulation}/>
                    {!isPartOfBuilding(form) && <>
                        <LabeledDropdownComponent
                            id='floorType'
                            label={t('Select floor type')}
                            display={extraEPCQuestionsDisplay}
                            value={floorType ? floorType : undefined}
                            options={floorTypeOptions}
                            valueChanged={onFloorTypeChanged}
                            errors={errors.floorType}/>
                        <LabeledDropdownComponent
                            id='floorOnFullGroundInsulation'
                            label={t('Select floor on full ground insulation')}
                            display={floorType === FloorType.OnFullGround || floorType === FloorType.Both ? extraEPCQuestionsDisplay : VariableDisplay.Hidden}
                            value={floorOnFullGroundInsulation ? floorOnFullGroundInsulation : undefined}
                            options={floorOnFullGroundInsulationOptions}
                            valueChanged={onFloorOnFullGroundInsulationChanged}
                            errors={errors.floorOnFullGroundInsulation}/>
                        <LabeledDropdownComponent
                            id='floorOnCrawlBasementInsulation'
                            label={t('Select floor on (crawl) basement insulation')}
                            display={floorType === FloorType.OnCrawlBasement || floorType === FloorType.Both ? extraEPCQuestionsDisplay : VariableDisplay.Hidden}
                            value={floorOnCrawlBasementInsulation ? floorOnCrawlBasementInsulation : undefined}
                            options={floorOnCrawlBasementInsulationOptions}
                            valueChanged={onFloorOnCrawlBasementChanged}
                            errors={errors.floorOnCrawlBasementInsulation}/>
                    </>}
                    <LabeledDropdownComponent
                        id='wallInsulation'
                        label={t('Select wall insulation')}
                        display={extraEPCQuestionsDisplay}
                        value={wallInsulation ? wallInsulation : undefined}
                        options={wallInsulationOptions}
                        valueChanged={onWallInsulationChanged}
                        errors={errors.wallInsulation}/>
                    <LabeledMultiSelectComponent
                        label={t('Select primary heat production')}
                        display={VariableDisplay.Required}
                        options={primaryHeatProductionOptions}
                        selectedOptions={selectedPrimaryHeatProductionOptions}
                        valueChanged={onPrimaryHeatProductionChanged}
                        allowMultiple={true}
                        errors={errors.primaryHeatProduction}
                    />
                    <LabeledMultiSelectComponent
                        label={t('Select water heating')}
                        display={VariableDisplay.Required}
                        options={waterHeatingOptions}
                        selectedOptions={selectedWaterHeatingOptions}
                        valueChanged={onWaterHeatingChanged}
                        allowMultiple={true}
                        errors={errors.waterHeating}
                    />
                    <LabeledMultiSelectComponent
                        label={t('Select heating elements')}
                        display={VariableDisplay.Required}
                        options={heatingOptions}
                        selectedOptions={selectedHeatingElementsOptions}
                        valueChanged={onHeatingElementsChanged}
                        allowMultiple={true}
                        errors={errors.heatingElements}
                    />
                    <LabeledMultiSelectComponent
                        label={t('Select window isolation')}
                        display={VariableDisplay.Required}
                        options={windowIsolationOptions}
                        selectedOptions={selectedWindowIsolationTypeOptions}
                        valueChanged={onWindowIsolationTypeChanged}
                        allowMultiple={false}
                        errors={errors.windowIsolationType}
                    />
                    <FileHandlerComponent attachmentTypes={[AttachmentType.EPCReport]}
                                          label={t('Upload EPC report')}
                                          display={configuration.displayEpcReportUpload}
                                          invalid={!!errors.requiredFiles}
                                          onNumberOfFilesChanged={(type, value) => filesChanged(value)}
                                          errorMessage={errors.requiredFiles}/>
                </div>
                <RequiredErrorsBoxComponent errors={
                    (errors.epc && (errors.ventilationSystem || errors.flatRoofInsulation || errors.slopedRoofInsulation ||
                        errors.floorType || errors.floorOnFullGroundInsulation || errors.floorOnCrawlBasementInsulation ||
                        errors.wallInsulation)) || errors.primaryHeatProduction || errors.heatingElements || errors.windowIsolationType}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step5Component;

interface Step5Errors {
    primaryHeatProduction?: string;
    heatingElements?: string;
    waterHeating?: string;
    epc?: string;
    ventilationSystem?: string;
    flatRoofInsulation?: string;
    slopedRoofInsulation?: string;
    floorType?: string;
    floorOnFullGroundInsulation?: string;
    floorOnCrawlBasementInsulation?: string;
    wallInsulation?: string;
    windowIsolationType?: string;
    requiredFiles?: string;
}

export const step5IsValid = (form: WebForm, configuration: WebformConfiguration): boolean => {
    return !isNullOrUndefined(form.primaryHeatProduction) && form.primaryHeatProduction!.length > 0
        && !isNullOrUndefined(form.heatingElements) && form.heatingElements!.length > 0
        && !isNullOrUndefined(form.waterHeating) && form.waterHeating!.length > 0
        && !isNullOrUndefined(form.windowIsolationType)
        && isEpcValid(form, configuration);
}

const isEpcValid = (form: WebForm, configuration: WebformConfiguration): boolean => {
    if (configuration.displayEpc === VariableDisplay.Required) {
        let isValid = true;
        if (!form.epcScore && !form.epcCategory) {
            if (!form.ventilationSystem) {
                isValid = false;
            }
            if (!form.flatRoofInsulation && !form.slopedRoofInsulation) {
                isValid = false;
            }
            if (!form.floorType) {
                isValid = false;
            }
            if ((form.floorType === FloorType.OnFullGround || form.floorType === FloorType.Both) && !form.floorOnFullGroundInsulation) {
                isValid = false;
            }
            if ((form.floorType === FloorType.OnCrawlBasement || form.floorType === FloorType.Both) && !form.floorOnCrawlBasementInsulation) {
                isValid = false;
            }
            if (!form.wallInsulation) {
                isValid = false;
            }
        }
        return isValid;
    } else {
        return true;
    }
}
