import React, {FunctionComponent, useEffect, useState} from 'react';
import {SelectionOption, SelectionOptionCategory} from '../dropdown/dropdown.component';
import styles from './multi-select.module.scss';
import {conditionalClassLister} from '../../utils/class-helpers';
import {ReactComponent as CheckboxComponent} from '../../../assets/images/checkbox.svg';

const MultiSelectComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {options, onSelectionsChanged, selection, categories, allowMultiSelect = true} = props;
    const [selectionOptions, setSelectionOptions] = useState<SelectionOption[]>(options);
    const [selectionOptionsPerCategory, setSelectionOptionsPerCategory] = useState<SelectionOption[][]>([]);

    useEffect(() => {
        if (options && options.length > 0 && categories && categories.length > 0) {
            const optionsPerCategory: SelectionOption[][] = [];
            const orderedOptions = options.sort((a, b) => {
                return a.category!.index - b.category!.index;
            });
            categories.forEach(() => optionsPerCategory.push([]));
            orderedOptions.forEach(option => {
                optionsPerCategory[option.category!.index].push(option);
            })

            setSelectionOptionsPerCategory(optionsPerCategory);
        } else {
            setSelectionOptions(options)
        }
    }, [options, categories]);

    const select = (option: SelectionOption, selected: boolean) => {
        let result: SelectionOption[];
        if (allowMultiSelect) {
            result = [...selection];
            if (selected) {
                const index = result.findIndex(opt => opt.value === option.value);
                result.splice(index, 1);
            } else {
                result.push(option);
            }
        } else {
            if (selected) {
                result = [];
            } else {
                result = [option];
            }
        }

        onSelectionsChanged(result);
    }

    return (
        <div className={styles.content}>
            {!categories && selectionOptions ? selectionOptions.map(option => {
                const selected = !!selection.find(o => option.value === o.value);
                const key = option.key ? option.key : option.value;
                const buttonClasses = conditionalClassLister(styles)({
                    label: true,
                    selected,
                });
                return <div className={buttonClasses}
                              key={key}
                              onClick={() => select(option, selected)}>
                    <div className={styles.checkBoxPanel}>
                        <CheckboxComponent className={styles.checkIcon} />
                    </div>
                    {option.label}
                </div>
            }) : '' }
            {selectionOptionsPerCategory ? selectionOptionsPerCategory.map(optionsWithCategory => {
                const category = optionsWithCategory[0].category!.label;
                return <div key={category} className={styles.categoryBox}>
                    <div className={styles.categoryTitle}>{category}</div>
                    <div className={styles.optionsList}>
                        {optionsWithCategory.map(option => {
                            const selected = !!selection.find(o => option.value === o.value);
                            const key = option.key ? option.key : option.value;
                            const buttonClasses = conditionalClassLister(styles)({
                                label: true,
                                selected,
                            });
                            return <div className={buttonClasses}
                                        key={key}
                                        onClick={() => select(option, selected)}>
                                <div className={styles.checkBoxPanel}>
                                    <CheckboxComponent className={styles.checkIcon} />
                                </div>
                                {option.label}
                            </div>
                        })}
                    </div>
                </div>
            }) : ''}
        </div>
    );
}

export default MultiSelectComponent;

interface OwnProps {
    options: SelectionOption[];
    onSelectionsChanged: (selections: SelectionOption[]) => void;
    selection: SelectionOption[];
    allowMultiSelect?: boolean;
    categories?: SelectionOptionCategory[];
}
