import React, {FunctionComponent} from 'react';
import styles from './required-errors-box.module.scss';
import {useTranslation} from 'react-i18next';

const RequiredErrorsBoxComponent: FunctionComponent<OwnProps> = ({errors}: OwnProps) => {
    const {t} = useTranslation();

    return errors ? (
        <div className={styles.errorsPanel}>
            {t('required_fields_error')}
        </div>
    ) : <></>
}
export default RequiredErrorsBoxComponent;

type OwnProps = {
    errors?: string;
}
