import {Renovation} from '../../../../store/webform/types';
import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './renovation.module.scss';
import {useTranslation} from 'react-i18next';
import RenovationComponent from './renovation.component';

interface OwnProps {
    initialRenovations: Renovation[];
    onRenovationsUpdated: (renovations: Renovation[]) => void
    showErrors: boolean
}

const RenovationsComponent: FunctionComponent<OwnProps> = ({
                                                               initialRenovations,
                                                               onRenovationsUpdated,
                                                               showErrors,
                                                           }: OwnProps) => {
    const {t} = useTranslation();
    const [renovations, setRenovations] = useState<Renovation[]>(initialRenovations || []);

    useEffect(() => {
        onRenovationsUpdated(renovations);
    }, [renovations]);

    const onAddRenovationClick = () => {
        const renovationsCopy = [...renovations];
        renovationsCopy.push({
            id: undefined,
            type: undefined,
            amount: undefined,
            year: undefined,
        });
        setRenovations(renovationsCopy);
    }

    const renovationDeleted = (index: number) => {
        const renovationsCopy = [...renovations];
        renovationsCopy.splice(index, 1);
        setRenovations(renovationsCopy);
    }

    const renovationUpdated = (renovation: Renovation, index: number) => {
        const renovationsCopy = [...renovations];
        renovationsCopy[index] = renovation;
        setRenovations(renovationsCopy);
    }

    return (
        <div className={styles.formField}>
            <label className={styles.label}>
                {t('Renovations')}
                <span>
                    <small>{t('Optional')}</small>
                </span>
            </label>
            {(renovations && renovations.length > 0 && (
                <div className={styles.renovations}>
                    {renovations.map((renovation, index) => (
                        <RenovationComponent
                            key={`renovation_${index}`}
                            renovation={renovation}
                            onRenovationUpdated={(renovation: Renovation) => renovationUpdated(renovation, index)}
                            onRenovationDeleted={() => renovationDeleted(index)}
                            showErrors={showErrors}
                        />
                    ))}
                </div>
            ))}
            <div className={styles.button} onClick={() => onAddRenovationClick()}>
                {t('Add a renovation')}
            </div>

        </div>
    )
}

export default RenovationsComponent;
