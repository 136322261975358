import React, {FunctionComponent} from 'react';
import styles from './loader.module.scss'
import {ReactComponent as LoadingIcon} from '../../../assets/images/loading.svg';

const LoaderComponent: FunctionComponent<OwnProps> = ({color}: OwnProps) => {

    return <LoadingIcon className={styles.loadingIcon} style={color ? {fill: color} : {}}/>
}
export default LoaderComponent;

interface OwnProps {
    color?: string;
}
