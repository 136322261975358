import {Http} from '../http/http';
import {ApiUrls, UrlParamBuilder} from './api-urls';
import {InvoiceDetails} from '../store/payment/types';
import {InvoiceDetailsRequest} from '../types/requests/invoice-details-request';

class InvoiceApi {
    public static async saveInvoiceDetails(bankId: string, paymentId: string, request: InvoiceDetailsRequest): Promise<InvoiceDetails> {
        return await Http.post<InvoiceDetailsRequest, InvoiceDetails>(
            ApiUrls.SaveInvoiceDetails,
            request,
            new UrlParamBuilder().addBankId(bankId).addPaymentId(paymentId).build(),
        );
    }
}

export default InvoiceApi;
