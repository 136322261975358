import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './epc-slider.module.scss';
import stepsStyles from '../../steps/steps.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {EpcValues, RegionTypes} from '../../../../types/responses/epc-values';
import {RuntimeConfig} from '../../../../../config/runtime-config';
import {EpcCategory} from '../../../../store/webform/types';
import NumberFormat from 'react-number-format';
import {useTranslation} from 'react-i18next';
import EPCArrowComponent from './epc-arrow.component';

const EPCSliderComponent: FunctionComponent<OwnProps> = ({score, category, updateScore, updateCategory, regionType, markEpcAsUnknown}: OwnProps) => {
    const {t} = useTranslation();
    const parsedScore = !isNaN(parseInt(score)) ? parseInt(score) : '';
    const [epcTypes, setEpcTypes] = useState<EpcValues>(RuntimeConfig.getEPCValuesVLA());

    let inputRef: HTMLInputElement;

    const onEpcScoreChanged = (input: string) => {
        const updatedValue = input.replace(/\s/g, '');
        if (updatedValue && !isNaN(parseInt(updatedValue, 10))) {
            updateScore(updatedValue);
            const value = parseInt(updatedValue, 10)
            if (regionType !== 'BRU' && value <= epcTypes.APlus) {
                updateCategory(EpcCategory.APlus);
            } else if ((regionType === 'BRU' && value <= epcTypes.A) || (regionType !== 'BRU' && value > epcTypes.APlus && value <= epcTypes.A)) {
                updateCategory(EpcCategory.A);
            } else if (value > epcTypes.A && value <= epcTypes.B) {
                updateCategory(EpcCategory.B);
            } else if (value > epcTypes.B && value <= epcTypes.C) {
                updateCategory(EpcCategory.C);
            } else if (value > epcTypes.C && value <= epcTypes.D) {
                updateCategory(EpcCategory.D);
            } else if (value > epcTypes.D && value <= epcTypes.E) {
                updateCategory(EpcCategory.E);
            } else if ((regionType === 'VLA' && value > epcTypes.E) || (regionType !== 'VLA' && value > epcTypes.E && value <= epcTypes.F)) {
                updateCategory(EpcCategory.F);
            } else if (regionType !== 'VLA' && value > epcTypes.F) {
                updateCategory(EpcCategory.G);
            }
        } else {
            updateScore('');
            updateCategory(undefined);
        }
    }

    const onEpcCategoryClicked = (category: EpcCategory) => {
        updateCategory(category)
        switch (category) {
            case EpcCategory.APlus:
                updateScore('0');
                break;
            case EpcCategory.A:
                if (regionType === 'BRU') {
                    updateScore('0');
                } else {
                    updateScore(((epcTypes.A + epcTypes.APlus)/2).toString());
                }
                break;
            case EpcCategory.B:
                updateScore(((epcTypes.B + epcTypes.A)/2).toString());
                break;
            case EpcCategory.C:
                updateScore(((epcTypes.C + epcTypes.B)/2).toString());
                break;
            case EpcCategory.D:
                updateScore(((epcTypes.D + epcTypes.C)/2).toString());
                break;
            case EpcCategory.E:
                updateScore(((epcTypes.E + epcTypes.D)/2).toString());
                break;
            case EpcCategory.F:
                if (regionType === 'VLA') {
                    updateScore((epcTypes.F + 50).toString());
                } else {
                    updateScore(((epcTypes.F + epcTypes.E)/2).toString());
                }
                break;
            case EpcCategory.G:
                updateScore((epcTypes.F + 50).toString());
                break;
            default:
                updateScore('');
        }
    }

    useEffect(() => {
        if (regionType === 'WAL') {
            setEpcTypes(RuntimeConfig.getEPCValuesWAL());
        } else if (regionType === 'BRU') {
            setEpcTypes(RuntimeConfig.getEPCValuesBRU());
        } else {
            setEpcTypes(RuntimeConfig.getEPCValuesVLA());
        }
    }, [regionType]);

    const focusOnEpcInput = () => {
        inputRef.focus()
    }

    const onUnknownEpcClick = () => {
        updateScore('');
        updateCategory(undefined);
        markEpcAsUnknown()
    }

    return (
        <div className={styles.container}>
            <div className={styles.boxContainer}>
                {regionType !== 'VLA' && <EPCArrowComponent category={EpcCategory.G} activeCategory={category} zIndex={80} onClick={onEpcCategoryClicked} />}
                <EPCArrowComponent category={EpcCategory.F} activeCategory={category} zIndex={70} onClick={onEpcCategoryClicked} />
                <EPCArrowComponent category={EpcCategory.E} activeCategory={category} zIndex={60} onClick={onEpcCategoryClicked} />
                <EPCArrowComponent category={EpcCategory.D} activeCategory={category} zIndex={50} onClick={onEpcCategoryClicked} />
                <EPCArrowComponent category={EpcCategory.C} activeCategory={category} zIndex={40} onClick={onEpcCategoryClicked} />
                <EPCArrowComponent category={EpcCategory.B} activeCategory={category} zIndex={30} onClick={onEpcCategoryClicked} />
                <EPCArrowComponent category={EpcCategory.A} activeCategory={category} zIndex={20} onClick={onEpcCategoryClicked} />
                {regionType !== 'BRU' && <EPCArrowComponent category={EpcCategory.APlus} activeCategory={category} zIndex={10} onClick={onEpcCategoryClicked} />}
            </div>

            {/*{epcTypes ? <div className={styles.letterContainer}>*/}
            {/*    <span/>*/}
            {/*    {regionType !== 'VLA' ? <span className={conditionalClassLister(styles)({*/}
            {/*            GBackgroundActive: parsedScore > epcTypes.F,*/}
            {/*        })} onClick={() => onEpcCategoryClicked(EpcCategory.G)}>G</span>*/}
            {/*        : null}*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        FBackgroundActive: regionType !== 'VLA' ? parsedScore > epcTypes.E && parsedScore <= epcTypes.F : parsedScore > epcTypes.E,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.F)}>F</span>*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        EBackgroundActive: parsedScore > epcTypes.D && parsedScore <= epcTypes.E,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.E)}>E</span>*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        DBackgroundActive: parsedScore > epcTypes.C && parsedScore <= epcTypes.D,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.D)}>D</span>*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        CBackgroundActive: parsedScore > epcTypes.B && parsedScore <= epcTypes.C,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.C)}>C</span>*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        BBackgroundActive: parsedScore > epcTypes.A && parsedScore <= epcTypes.B,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.B)}>B</span>*/}
            {/*    <span className={conditionalClassLister(styles)({*/}
            {/*        ABackgroundActive: regionType !== 'BRU' ? parsedScore > epcTypes.APlus && parsedScore <= epcTypes.A : parsedScore !== undefined && parsedScore !== '' && parsedScore <= epcTypes.A,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.A)}>A</span>*/}
            {/*    {regionType !== 'BRU' ? <span className={conditionalClassLister(styles)({*/}
            {/*        APlusBackgroundActive: parsedScore !== undefined && parsedScore !== '' && parsedScore <= epcTypes.APlus,*/}
            {/*    })} onClick={() => onEpcCategoryClicked(EpcCategory.APlus)}>A+</span>*/}
            {/*    : null}*/}
            {/*    <span/>*/}
            {/*</div> : null }*/}
            <div className={styles.bottomContainer}>
                <div onClick={() => focusOnEpcInput()} className={styles.inputContainer}>
                    <span className={styles.scorePrefix}>&#177;</span>
                    <NumberFormat
                        type="text"
                        pattern="[0-9]*"
                        thousandSeparator="&thinsp;"
                        className={`${styles.scoreIndicator} ${stepsStyles.input}`}
                        value={score}
                        maxLength={4}
                        onChange={(e) => onEpcScoreChanged(e.target.value)}
                        getInputRef={(el: HTMLInputElement) => inputRef = el}/>
                    <span className={styles.scoreUnit}>kWh / m&#178;j</span>
                </div>
                <div className={styles.button} onClick={() => onUnknownEpcClick()}>
                    {t('Unknown')}
                </div>
            </div>
        </div>
    );
}

interface OwnProps {
    score: string;
    category?: EpcCategory;
    updateScore: (score: string) => void;
    updateCategory: (category?: EpcCategory) => void;
    regionType: RegionTypes;
    markEpcAsUnknown: () => void;
}

export default EPCSliderComponent;
