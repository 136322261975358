import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import CheckboxComponent from '../../../components/checkbox/checkbox.component';
import {
    AttachmentType,
    Renovation,
    RenovationType,
    WebForm,
} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import {VariableDisplay} from '../../../types/variable-display';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import LoaderComponent from '../../../components/loader/loader';
import RenovationsComponent from '../components/renovation/renovations.component';
import LabeledNumberInputComponent from '../components/input/labeled-number-input.component';
import {isNullOrUndefined} from '../../../utils/validation.util';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step8Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [constructionYear, setConstructionYear] = useState<number | string | undefined>(form.constructionYear);
    const [renovations, setRenovations] = useState<Renovation[]>(form.renovations || []);
    const [showRenovationErrors, setShowRenovationErrors] = useState(false);
    const [overallRenovationWithPermit, setOverallRenovationWithPermit] = useState<boolean>(form.overallRenovationWithPermit || false);
    const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
    const [errors, setErrors] = useState<Step8Errors>({});
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step8IsValid(updatedForm) && (configuration.displayBuildingPermitUpload === VariableDisplay.Required ? numberOfFiles > 0 : true);
        if (scopedIsValid) {
            onChange(updatedForm);
            setShowRenovationErrors(false);
        }
        setIsValid(scopedIsValid);
    }, [constructionYear, renovations, overallRenovationWithPermit, numberOfFiles]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || !isNullOrUndefined(configuration)) {
            updatedForm.constructionYear = typeof constructionYear === 'string' ? parseInt(constructionYear) : constructionYear;
        }
        if (skipValidation || renovations) {
            updatedForm.renovations = renovations;
        }
        updatedForm.overallRenovationWithPermit = overallRenovationWithPermit;

        return updatedForm;
    }

    const validateFields = () => {
        setShowRenovationErrors(true);
        if (!constructionYear) {
            setErrors((prevState) => ({
                ...prevState,
                // t('construction_year_required')
                constructionYear: 'construction_year_required',
            }));
        }
        if (configuration.displayBuildingPermitUpload === VariableDisplay.Required && numberOfFiles === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('files_required_error')
                requiredFiles: 'files_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !isNullOrUndefined(form.constructionYear);
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onConstructionYearChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setConstructionYear(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                constructionYear: undefined,
            }));
        } else {
            setConstructionYear('');
        }
    };

    const onConstructionYearBlur = (value: string) => {
        if (isNaN(parseFloat(value)) || parseFloat(value) < 1) {
            setErrors((prevState) => ({
                ...prevState,
                // t('construction_year_required')
                constructionYear: 'construction_year_required',
            }));
        }
    };

    const onRenovationsUpdated = (updatedRenovations: Renovation[]) => {
        setRenovations(updatedRenovations);
    }

    const filesChanged = (value: number) => {
        setNumberOfFiles(value);
        if (value > 0) {
            setErrors((prevState) => ({
                ...prevState,
                requiredFiles: undefined,
            }));
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={8}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <LabeledNumberInputComponent
                        id='constructionYear'
                        label={t('Enter construction year')}
                        footnote={t('Year the building was initially constructed in')}
                        display={VariableDisplay.Required}
                        value={constructionYear}
                        valueChanged={onConstructionYearChanged}
                        valueBlurred={onConstructionYearBlur}
                        maxLength={4}
                        errors={errors.constructionYear}/>
                    <RenovationsComponent
                        initialRenovations={renovations}
                        onRenovationsUpdated={onRenovationsUpdated}
                        showErrors={showRenovationErrors}
                    />
                    {configuration.displayOverallRenovationWithPermit !== VariableDisplay.Hidden
                    && renovations.find((renovation) => renovation.type === RenovationType.Overall)
                    &&
                    (<div className={styles.formField}>
                        <div className={`${styles.label} ${styles.verticallyCentered}`}>
                        <span style={{paddingRight: '10px'}}>
                            <CheckboxComponent
                                value={overallRenovationWithPermit}
                                onSelectionChanged={(value) => setOverallRenovationWithPermit(value)}/>
                        </span>
                            {t('Renovations were made with a permit')}
                        </div>
                    </div>)}
                    <FileHandlerComponent attachmentTypes={[AttachmentType.BuildingPermit]}
                                          label={t('Upload building permit')}
                                          display={configuration.displayBuildingPermitUpload}
                                          invalid={!!errors.requiredFiles}
                                          onNumberOfFilesChanged={(type, value) => filesChanged(value)}
                                          errorMessage={errors.requiredFiles}/>
                </div>
                <RequiredErrorsBoxComponent
                    errors={errors.constructionYear || (showRenovationErrors && hasRenovationTypes(form) ? 'error' : undefined)}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step8Component;

interface Step8Errors {
    constructionYear?: string;
    requiredFiles?: string;
}

export const step8IsValid = (form: WebForm): boolean => {
    return (form.constructionYear ? form.constructionYear >= 1500 && form.constructionYear <= new Date().getFullYear() : false)
        && (hasRenovationTypes(form));
}

const hasRenovationTypes = (form: WebForm): boolean => {
    return form.renovations ? form.renovations.every(renovation => !!renovation.type) : true
}
