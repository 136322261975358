import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {AttachmentType, RoofType, WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {getMaxActiveStep, isPartOfBuilding} from '../web-form.page';
import {enumToSelectionOptions} from '../../../components/dropdown/selection-option.util';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import LabeledNumberInputComponent from '../components/input/labeled-number-input.component';
import {VariableDisplay} from '../../../types/variable-display';
import LabeledNumberSelectComponent from '../components/input/labeled-number-select.component';
import LabeledDropdownComponent from '../components/input/labeled-dropdown.component';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import {isEmpty, isNullOrUndefined, isValidInput} from '../../../utils/validation.util';
import LoaderComponent from '../../../components/loader/loader';
import {WebformConfiguration} from '../../../store/bank/types';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import StepHeaderComponent from '../components/step-header/step-header.component';

interface OwnProps extends StepProps {
    hasDataFromApi: boolean;
}

const Step3Component: FunctionComponent<OwnProps> = ({
                                                         form,
                                                         configuration,
                                                         totalSteps,
                                                         onNextClick,
                                                         onPreviousClick,
                                                         onChange,
                                                         updateInProgress,
                                                         hasDataFromApi,
                                                     }: OwnProps) => {
    const {t} = useTranslation();
    const [plotSize, setPlotSize] = useState<number | string | undefined>(form.plotSize || undefined);
    const [livingAreaSize, setLivingAreaSize] = useState<number | string | undefined>(form.livingAreaSize || undefined);
    const [storageBasementSize, setStorageBasementSize] = useState<number | string | undefined>(!isNullOrUndefined(form.storageBasementSize) ? form.storageBasementSize : undefined);
    const [storageAtticSize, setStorageAtticSize] = useState<number | string | undefined>(!isNullOrUndefined(form.storageAtticSize) ? form.storageAtticSize : undefined);
    const [numberOfIndoorParkingSpacesMainBuilding, setNumberOfIndoorParkingSpacesMainBuilding] = useState<number | string | undefined>(form.numberOfIndoorParkingSpacesMainBuilding);
    const [numberOfIndoorParkingSpacesSeparateBuilding, setNumberOfIndoorParkingSpacesSeparateBuilding] = useState<number | string | undefined>(form.numberOfIndoorParkingSpacesSeparateBuilding);
    const [numberOfOutdoorParkingSpacesOpenAir, setNumberOfOutdoorParkingSpacesOpenAir] = useState<number | string | undefined>(form.numberOfOutdoorParkingSpacesOpenAir);
    const [numberOfOutdoorParkingSpacesCarport, setNumberOfOutdoorParkingSpacesCarport] = useState<number | string | undefined>(form.numberOfOutdoorParkingSpacesCarport);
    const [balconyAndTerraceSize, setBalconyAndTerraceSize] = useState<number | string | undefined>(!isNullOrUndefined(form.balconyAndTerraceSize) ? form.balconyAndTerraceSize : undefined);
    const [privateGardenSize, setPrivateGardenSize] = useState<number | string | undefined>(form.privateGardenSize || undefined);
    const [communalGardenSize, setCommunalGardenSize] = useState<number | string | undefined>(form.communalGardenSize || undefined);
    const [entranceFloor, setEntranceFloor] = useState<number | undefined>(form.floor);
    const [floorCount, setFloorCount] = useState<number | undefined>(form.floorCount);
    const [roofType, setRoofType] = useState<RoofType | undefined>(form.roofType);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errors, setErrors] = useState<Step3Errors>({});
    const roofTypeOptions = enumToSelectionOptions(RoofType, t);
    const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step3IsValid(updatedForm, configuration) && (configuration.displayPlansUpload === VariableDisplay.Required ? numberOfFiles > 0 : true);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
        setIsValid(scopedIsValid);
    }, [plotSize, livingAreaSize, storageBasementSize, storageAtticSize, balconyAndTerraceSize, privateGardenSize, communalGardenSize,
        numberOfIndoorParkingSpacesMainBuilding, numberOfIndoorParkingSpacesSeparateBuilding, numberOfOutdoorParkingSpacesCarport, numberOfOutdoorParkingSpacesOpenAir,
        entranceFloor, floorCount, roofType, numberOfFiles]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || !isEmpty(plotSize)) {
            updatedForm.plotSize = (typeof plotSize === 'string' ? Math.round(parseFloat(plotSize)) : Math.round(plotSize!));
        }
        if (skipValidation || !isEmpty(livingAreaSize)) {
            updatedForm.livingAreaSize = (typeof livingAreaSize === 'string' ? Math.round(parseFloat(livingAreaSize)) : Math.round(livingAreaSize!));
        }
        if (skipValidation || !isEmpty(storageBasementSize)) {
            updatedForm.storageBasementSize = (typeof storageBasementSize === 'string' ? Math.round(parseFloat(storageBasementSize)) : Math.round(storageBasementSize!));
        }
        if (skipValidation || !isEmpty(storageAtticSize)) {
            updatedForm.storageAtticSize = (typeof storageAtticSize === 'string' ? Math.round(parseFloat(storageAtticSize)) : Math.round(storageAtticSize!));
        }
        if (skipValidation || !isEmpty(balconyAndTerraceSize)) {
            updatedForm.balconyAndTerraceSize = (typeof balconyAndTerraceSize === 'string' ? Math.round(parseFloat(balconyAndTerraceSize)) : Math.round(balconyAndTerraceSize!));
        }
        if (skipValidation || !isEmpty(balconyAndTerraceSize)) {
            updatedForm.privateGardenSize = (typeof privateGardenSize === 'string' ? Math.round(parseFloat(privateGardenSize)) : Math.round(privateGardenSize!));
        }
        if (skipValidation || !isEmpty(balconyAndTerraceSize)) {
            updatedForm.communalGardenSize = (typeof communalGardenSize === 'string' ? Math.round(parseFloat(communalGardenSize)) : Math.round(communalGardenSize!));
        }
        if (skipValidation || !isNullOrUndefined(entranceFloor)) {
            updatedForm.floor = entranceFloor;
        }
        if (skipValidation || !isNullOrUndefined(floorCount)) {
            updatedForm.floorCount = floorCount;
        }
        if (skipValidation || (roofType && updatedForm.roofType !== roofType)) {
            updatedForm.roofType = roofType;
            switch (roofType) {
                case RoofType.Gable:
                case RoofType.Hip:
                    updatedForm.flatRoofInsulation = null;
                    break;
                case RoofType.Flat:
                    updatedForm.slopedRoofInsulation = null;
                    break;
            }
        }
        if (skipValidation || !isNullOrUndefined(numberOfIndoorParkingSpacesMainBuilding)) {
            updatedForm.numberOfIndoorParkingSpacesMainBuilding = numberOfIndoorParkingSpacesMainBuilding;
        }
        if (skipValidation || !isNullOrUndefined(numberOfIndoorParkingSpacesSeparateBuilding)) {
            updatedForm.numberOfIndoorParkingSpacesSeparateBuilding = numberOfIndoorParkingSpacesSeparateBuilding;
        }
        if (skipValidation || !isNullOrUndefined(numberOfOutdoorParkingSpacesCarport)) {
            updatedForm.numberOfOutdoorParkingSpacesCarport = numberOfOutdoorParkingSpacesCarport;
        }
        if (skipValidation || !isNullOrUndefined(numberOfOutdoorParkingSpacesOpenAir)) {
            updatedForm.numberOfOutdoorParkingSpacesOpenAir = numberOfOutdoorParkingSpacesOpenAir;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (!isPartOfBuilding(form) && !plotSize) {
            setErrors((prevState) => ({
                ...prevState,
                // t('parcel_area_required_error')
                plotSizeRequired: 'parcel_area_required_error',
            }));
        }
        if (!livingAreaSize) {
            setErrors((prevState) => ({
                ...prevState,
                // t('living_area_required_error')
                livingAreaSizeRequired: 'living_area_required_error',
            }));
        }
        if (configuration.displayStorageBasementSize === VariableDisplay.Required && isEmpty(storageBasementSize)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('storage_basement_area_required_error')
                storageBasementSizeRequired: 'storage_basement_area_required_error',
            }));
        }
        if (configuration.displayStorageAtticSize === VariableDisplay.Required && isEmpty(storageAtticSize)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('storage_attic_area_required_error')
                storageAtticSizeRequired: 'storage_attic_area_required_error',
            }));
        }
        if (configuration.displayPrivateGardenSize === VariableDisplay.Required && isEmpty(privateGardenSize)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('private_garden_area_required_error')
                privateGardenSizeRequired: 'private_garden_area_required_error',
            }));
        }
        if (isPartOfBuilding(form) && configuration.displayCommunalGardenSize === VariableDisplay.Required && isEmpty(communalGardenSize)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('communal_garden_area_required_error')
                communalGardenSizeRequired: 'communal_garden_area_required_error',
            }));
        }
        if (isPartOfBuilding(form) && configuration.displayBalconyAndTerraceSize === VariableDisplay.Required && isEmpty(balconyAndTerraceSize)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('balcony_terrace_area_required_error')
                balconyAndTerraceSizeRequired: 'balcony_terrace_area_required_error',
            }));
        }
        if (isNullOrUndefined(floorCount)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_floors_required_error')
                floorCountRequired: 'number_of_floors_required_error',
            }));
        }
        if (isEmpty(numberOfIndoorParkingSpacesMainBuilding)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_indoor_parking_spaces_main_building_required')
                numberOfIndoorParkingSpacesMainBuildingRequired: 'number_of_indoor_parking_spaces_main_building_required',
            }));
        }
        if (isEmpty(numberOfIndoorParkingSpacesSeparateBuilding)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_indoor_parking_spaces_separate_building_required')
                numberOfIndoorParkingSpacesSeparateBuildingRequired: 'number_of_indoor_parking_spaces_separate_building_required',
            }));
        }
        if (isEmpty(numberOfOutdoorParkingSpacesCarport)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_outdoor_parking_spaces_carport_required')
                numberOfOutdoorParkingSpacesCarportRequired: 'number_of_outdoor_parking_spaces_carport_required',
            }));
        }
        if (isEmpty(numberOfOutdoorParkingSpacesOpenAir)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_outdoor_parking_spaces_open_air_required')
                numberOfOutdoorParkingSpacesOpenAirRequired: 'number_of_outdoor_parking_spaces_open_air_required',
            }));
        }
        if (isPartOfBuilding(form) && (entranceFloor === undefined || entranceFloor === null)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('entrance_floor_required_error')
                floorRequired: 'entrance_floor_required_error',
            }));
        }
        if (!roofType) {
            setErrors((prevState) => ({
                ...prevState,
                // t('roof_type_required_error')
                roofTypeRequired: 'roof_type_required_error',
            }));
        }
        if (configuration.displayPlansUpload === VariableDisplay.Required && numberOfFiles === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('files_required_error')
                requiredFiles: 'files_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        if (!form.livingAreaSize
            || (configuration.displayBalconyAndTerraceSize === VariableDisplay.Required ? isNullOrUndefined(form.balconyAndTerraceSize) : false)
            || (configuration.displayStorageBasementSize === VariableDisplay.Required ? isNullOrUndefined(form.storageBasementSize) : false)
            || (configuration.displayStorageAtticSize === VariableDisplay.Required ? isNullOrUndefined(form.storageAtticSize) : false)
            || !isEmpty(form.numberOfIndoorParkingSpacesMainBuilding)
            || !isEmpty(form.numberOfIndoorParkingSpacesSeparateBuilding)
            || !isEmpty(form.numberOfOutdoorParkingSpacesCarport)
            || !isEmpty(form.numberOfOutdoorParkingSpacesOpenAir)
            || form.floorCount
            || form.roofType) {
            return false;
        }

        if (isPartOfBuilding(form)) {
            if ((configuration.displayCommunalGardenSize === VariableDisplay.Required ? isNullOrUndefined(form.communalGardenSize) : false)
                || (configuration.displayPrivateGardenSize === VariableDisplay.Required ? isNullOrUndefined(form.privateGardenSize) : false)
                || isNullOrUndefined(form.floor)) {
                return false;
            }
        } else {
            if (!form.plotSize) {
                return false;
            }
        }
        return true
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onPlotAreaChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setPlotSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                plotSize: undefined,
                plotSizeRequired: undefined,
            }));
        } else {
            setPlotSize('');
        }

    };

    const onPlotAreaBlur = (value: string) => {
        if (isNaN(parseFloat(value)) || parseFloat(value) < 1) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Parcel area must be bigger than 0')
                plotSize: 'Parcel area must be bigger than 0',
            }));
        }
    };

    const onLivingAreaChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setLivingAreaSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                livingAreaSize: undefined,
                livingAreaSizeRequired: undefined,
            }));
        } else {
            setLivingAreaSize('');
        }
    };

    const onLivingAreaBlur = (value: string) => {
        if (isNaN(parseFloat(value)) || parseFloat(value) < 1) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Living area size must be bigger than 0')
                livingAreaSize: 'Living area size must be bigger than 0',
            }));
        }
    };

    const onStorageBasementSizeChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setStorageBasementSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                storageBasementSize: undefined,
                storageBasementSizeRequired: undefined,
            }));
        } else {
            setStorageBasementSize('');
        }
    };

    const onStorageBasementSizeBlur = (value: string) => {
        if (configuration.displayStorageBasementSize == VariableDisplay.Required && (isNaN(parseFloat(value)) || parseFloat(value) < 0)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Storage basement size must not be less than 0')
                storageBasementSize: 'Storage basement size must not be less than 0',
            }));
        }
    };

    const onStorageAtticSizeChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setStorageAtticSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                storageAtticSize: undefined,
                storageAtticSizeRequired: undefined,
            }));
        } else {
            setStorageAtticSize('');
        }
    };

    const onStorageAtticSizeBlur = (value: string) => {
        if (configuration.displayStorageAtticSize == VariableDisplay.Required && (isNaN(parseFloat(value)) || parseFloat(value) < 0)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Storage attic size must not be less than 0')
                storageAtticSize: 'Storage attic size must not be less than 0',
            }));
        }
    };


    const onBalconyAndTerraceSizeChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        setBalconyAndTerraceSize(!isNaN(parseFloat(updatedValue)) ? updatedValue : '');
        if (!isNaN(parseFloat(updatedValue))) {
            setBalconyAndTerraceSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                balconyAndTerraceSize: undefined,
                balconyAndTerraceSizeRequired: undefined,
            }));
        } else {
            setBalconyAndTerraceSize('');
        }
    };

    const onBalconyAndTerraceSizeBlur = (value: string) => {
        if (configuration.displayBalconyAndTerraceSize == VariableDisplay.Required && (isNaN(parseFloat(value)) || parseFloat(value) < 0)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Size must not be less than 0')
                balconyAndTerraceSize: 'Size must not be less than 0',
            }));
        }
    };

    const onPrivateGardenSizeChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setPrivateGardenSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                privateGardenSize: undefined,
                privateGardenSizeRequired: undefined,
            }));
        } else {
            setPrivateGardenSize('');
        }
    };

    const onPrivateGardenSizeBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Private garden size must not be less than 0')
                privateGardenSize: 'Private garden size must not be less than 0',
            }));
        }
    };

    const onCommunalGardenSizeChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setCommunalGardenSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                communalGardenSize: undefined,
                communalGardenSizeRequired: undefined,
            }));
        } else {
            setCommunalGardenSize('');
        }
    };

    const onCommunalGardenSizeBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('Communal garden size must not be less than 0')
                communalGardenSize: 'Communal garden size must not be less than 0',
            }));
        }
    };

    const onFloorEntranceChanged = (value: number) => {
        setEntranceFloor(value);
        setErrors((prevState) => ({
            ...prevState,
            floorRequired: undefined,
        }));
    };

    const onFloorCountChange = (value: number) => {
        setFloorCount(value);
        setErrors((prevState) => ({
            ...prevState,
            floorCountRequired: undefined,
        }));
    };

    const onNumberOfIndoorParkingSpacesMainBuildingChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setNumberOfIndoorParkingSpacesMainBuilding(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                numberOfIndoorParkingSpacesMainBuildingRequired: undefined,
            }));
        } else {
            setNumberOfIndoorParkingSpacesMainBuilding('');
        }
    };

    const onNumberOfIndoorParkingSpacesMainBuildingBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_indoor_parking_spaces_main_building_required')
                numberOfIndoorParkingSpacesMainBuildingRequired: 'number_of_indoor_parking_spaces_main_building_required',
            }));
        }
    };

    const onNumberOfIndoorParkingSpacesSeparateBuildingChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setNumberOfIndoorParkingSpacesSeparateBuilding(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                numberOfIndoorParkingSpacesSeparateBuildingRequired: undefined,
            }));
        } else {
            setNumberOfIndoorParkingSpacesSeparateBuilding('');
        }
    };

    const onNumberOfIndoorParkingSpacesSeparateBuildingBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_indoor_parking_spaces_separate_building_required')
                numberOfIndoorParkingSpacesSeparateBuildingRequired: 'number_of_indoor_parking_spaces_separate_building_required',
            }));
        }
    };

    const onNumberOfOutdoorParkingSpacesCarportChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setNumberOfOutdoorParkingSpacesCarport(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                numberOfOutdoorParkingSpacesCarportRequired: undefined,
            }));
        } else {
            setNumberOfOutdoorParkingSpacesCarport('');
        }
    };

    const onNumberOfOutdoorParkingSpacesCarportBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_outdoor_parking_spaces_carport_required')
                numberOfOutdoorParkingSpacesCarportRequired: 'number_of_outdoor_parking_spaces_carport_required',
            }));
        }
    };

    const onNumberOfOutdoorParkingSpacesOpenAirChange = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setNumberOfOutdoorParkingSpacesOpenAir(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                numberOfOutdoorParkingSpacesOpenAirRequired: undefined,
            }));
        } else {
            setNumberOfOutdoorParkingSpacesOpenAir('');
        }
    };

    const onNumberOfOutdoorParkingSpacesOpenAirBlur = (value: string) => {
        if (!isNaN(parseFloat(value)) && parseFloat(value) < 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_outdoor_parking_spaces_open_air_required')
                numberOfOutdoorParkingSpacesOpenAirRequired: 'number_of_outdoor_parking_spaces_open_air_required',
            }));
        }
    };

    const onRoofTypeChanged = (optionValue: string) => {
        setRoofType(optionValue as RoofType);
        setErrors((prevState) => ({
            ...prevState,
            roofTypeRequired: undefined,
        }));
    };

    const filesChanged = (value: number) => {
        setNumberOfFiles(value);
        if (value > 0) {
            setErrors((prevState) => ({
                ...prevState,
                requiredFiles: undefined,
            }));
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={3}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                {hasDataFromApi && <div className={styles.infoPanel}>
                    {t('We already found some data based on the selected parcel (Source: Belgian Cadastre 2022)')}
                </div>}
                <div className={styles.form}>
                    {!isPartOfBuilding(form) &&
                    <LabeledNumberInputComponent
                        id='plotSize'
                        label={t('Enter parcel area size')}
                        footnote={t('Total parcel area in square meters')}
                        display={VariableDisplay.Required}
                        value={plotSize}
                        valueChanged={onPlotAreaChange}
                        valueBlurred={onPlotAreaBlur}
                        unit='m&#178;'
                        errors={errors.plotSizeRequired || errors.plotSize}/>}
                    <LabeledNumberInputComponent
                        id='livingAreaSize'
                        label={t('Enter habitable area size')}
                        footnote={t('Excluding storage basement, garages and storage attic, including interior and exterior walls')}
                        display={VariableDisplay.Required}
                        value={livingAreaSize}
                        valueChanged={onLivingAreaChange}
                        valueBlurred={onLivingAreaBlur}
                        unit='m&#178;'
                        errors={errors.livingAreaSizeRequired || errors.livingAreaSize}/>
                    <LabeledNumberInputComponent
                        id='storageBasementSize'
                        label={t('Enter storage basement size')}
                        footnote={t('Storage cellar, excluding garage, including interior and exterior walls')}
                        display={configuration.displayStorageBasementSize}
                        value={storageBasementSize}
                        valueChanged={onStorageBasementSizeChange}
                        valueBlurred={onStorageBasementSizeBlur}
                        unit='m&#178;'
                        errors={errors.storageBasementSizeRequired || errors.storageBasementSize}/>
                    <LabeledNumberInputComponent
                        id='storageAtticSize'
                        label={t('Enter storage attic size')}
                        footnote={t('Storage attic, including interior and exterior walls')}
                        display={configuration.displayStorageAtticSize}
                        value={storageAtticSize}
                        valueChanged={onStorageAtticSizeChange}
                        valueBlurred={onStorageAtticSizeBlur}
                        unit='m&#178;'
                        errors={errors.storageAtticSizeRequired || errors.storageAtticSize}/>
                    {isPartOfBuilding(form) &&
                    <>
                        <LabeledNumberInputComponent
                            id='privateGardenSize'
                            label={t('Enter private garden size')}
                            display={configuration.displayPrivateGardenSize}
                            value={privateGardenSize}
                            valueChanged={onPrivateGardenSizeChange}
                            valueBlurred={onPrivateGardenSizeBlur}
                            unit='m&#178;'
                            errors={errors.privateGardenSize || errors.privateGardenSizeRequired}/>
                        <LabeledNumberInputComponent
                            id='communalGardenSize'
                            label={t('Enter communal garden size')}
                            display={configuration.displayCommunalGardenSize}
                            value={communalGardenSize}
                            valueChanged={onCommunalGardenSizeChange}
                            valueBlurred={onCommunalGardenSizeBlur}
                            unit='m&#178;'
                            errors={errors.communalGardenSize || errors.communalGardenSizeRequired}/>
                    </>
                    }
                    <LabeledNumberInputComponent
                        id='balconyAndTerraceSize'
                        label={t('Enter surface of the terrace and balcony')}
                        footnote={t('Total surface of terrace and balcony in square meters')}
                        display={configuration.displayBalconyAndTerraceSize}
                        value={balconyAndTerraceSize}
                        valueChanged={onBalconyAndTerraceSizeChanged}
                        valueBlurred={onBalconyAndTerraceSizeBlur}
                        unit='m&#178;'
                        errors={errors.balconyAndTerraceSizeRequired || errors.balconyAndTerraceSize}/>
                    {isPartOfBuilding(form) &&
                    <LabeledNumberSelectComponent
                        id='entranceFloor'
                        label={t('Select entrance floor level')}
                        display={VariableDisplay.Required}
                        value={entranceFloor}
                        valueChanged={onFloorEntranceChanged}
                        errors={errors.floorRequired}/>}
                    <LabeledNumberSelectComponent
                        id='floorCount'
                        label={t('Select number of floors')}
                        footnote={t('Include non-accessible floors, storage floors, ground floor')}
                        display={VariableDisplay.Required}
                        value={floorCount}
                        min={1}
                        valueChanged={onFloorCountChange}
                        errors={errors.floorCountRequired}/>
                    <LabeledDropdownComponent
                        id='roofType'
                        label={t('Select roof type')}
                        display={VariableDisplay.Required}
                        value={roofType}
                        options={roofTypeOptions}
                        valueChanged={onRoofTypeChanged}
                        errors={errors.roofTypeRequired}/>
                    <div className={styles.title}>{t('Parking Spaces')}</div>
                    <LabeledNumberInputComponent
                        id='numberOfIndoorParkingSpacesMainBuilding'
                        label={t('Enter the number of indoor parking spaces in the main building')}
                        display={VariableDisplay.Required}
                        value={numberOfIndoorParkingSpacesMainBuilding}
                        valueChanged={onNumberOfIndoorParkingSpacesMainBuildingChange}
                        valueBlurred={onNumberOfIndoorParkingSpacesMainBuildingBlur}
                        errors={errors.numberOfIndoorParkingSpacesMainBuildingRequired}/>
                    <LabeledNumberInputComponent
                        id='numberOfIndoorParkingSpacesSeparateBuilding'
                        label={t('Enter the number of indoor parking spaces in a separate building')}
                        display={VariableDisplay.Required}
                        value={numberOfIndoorParkingSpacesSeparateBuilding}
                        valueChanged={onNumberOfIndoorParkingSpacesSeparateBuildingChange}
                        valueBlurred={onNumberOfIndoorParkingSpacesSeparateBuildingBlur}
                        errors={errors.numberOfIndoorParkingSpacesSeparateBuildingRequired}
                        footnote={t('The separate building should be located on the property to be valued')}/>
                    <LabeledNumberInputComponent
                        id='numberOfOutdoorParkingSpacesCarport'
                        label={t('Enter the number of outdoor parking spaces under a carport')}
                        display={VariableDisplay.Required}
                        value={numberOfOutdoorParkingSpacesCarport}
                        valueChanged={onNumberOfOutdoorParkingSpacesCarportChange}
                        valueBlurred={onNumberOfOutdoorParkingSpacesCarportBlur}
                        errors={errors.numberOfOutdoorParkingSpacesCarportRequired}/>
                    <LabeledNumberInputComponent
                        id='numberOfOutdoorParkingSpacesOpenAir'
                        label={t('Enter the number of open air parking spaces')}
                        display={VariableDisplay.Required}
                        value={numberOfOutdoorParkingSpacesOpenAir}
                        valueChanged={onNumberOfOutdoorParkingSpacesOpenAirChange}
                        valueBlurred={onNumberOfOutdoorParkingSpacesOpenAirBlur}
                        errors={errors.numberOfOutdoorParkingSpacesOpenAirRequired}/>
                    <FileHandlerComponent attachmentTypes={[AttachmentType.Plans]}
                                          label={t('Upload plans')}
                                          footNote={t('Plans, sections and facades')}
                                          display={configuration.displayPlansUpload}
                                          invalid={!!errors.requiredFiles}
                                          onNumberOfFilesChanged={(type, value) => filesChanged(value)}
                                          errorMessage={errors.requiredFiles}/>
                </div>
                <RequiredErrorsBoxComponent
                    errors={errors.plotSizeRequired || errors.livingAreaSizeRequired || errors.storageBasementSizeRequired
                    || errors.storageAtticSizeRequired || errors.balconyAndTerraceSizeRequired || errors.floorCountRequired || errors.floorRequired
                    || errors.roofTypeRequired || errors.numberOfIndoorParkingSpacesMainBuildingRequired || errors.numberOfIndoorParkingSpacesSeparateBuildingRequired
                    || errors.numberOfOutdoorParkingSpacesCarportRequired || errors.numberOfOutdoorParkingSpacesOpenAirRequired
                    || errors.privateGardenSizeRequired || errors.communalGardenSizeRequired}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step3Component;

interface Step3Errors {
    plotSize?: string;
    livingAreaSize?: string;
    storageBasementSize?: string;
    storageAtticSize?: string;
    privateGardenSize?: string;
    communalGardenSize?: string;
    balconyAndTerraceSize?: string;
    floorCount?: string;
    plotSizeRequired?: string;
    livingAreaSizeRequired?: string;
    storageBasementSizeRequired?: string;
    storageAtticSizeRequired?: string;
    balconyAndTerraceSizeRequired?: string;
    privateGardenSizeRequired?: string;
    communalGardenSizeRequired?: string;
    floorCountRequired?: string;
    floorRequired?: string;
    roofTypeRequired?: string;
    requiredFiles?: string;
    numberOfIndoorParkingSpacesMainBuildingRequired?: string;
    numberOfIndoorParkingSpacesSeparateBuildingRequired?: string;
    numberOfOutdoorParkingSpacesCarportRequired?: string;
    numberOfOutdoorParkingSpacesOpenAirRequired?: string;
}

export const step3IsValid = (form: WebForm, configuration: WebformConfiguration): boolean => {
    return (!isPartOfBuilding(form) ? isValidInput(form.plotSize, 1) : true)
        && isValidInput(form.livingAreaSize, 1)
        && isValidInput(form.storageBasementSize, 0, configuration.displayStorageBasementSize !== VariableDisplay.Required)
        && isValidInput(form.storageAtticSize, 0, configuration.displayStorageAtticSize !== VariableDisplay.Required)
        && isValidInput(form.balconyAndTerraceSize, 0, configuration.displayBalconyAndTerraceSize !== VariableDisplay.Required)
        && (isPartOfBuilding(form) ? isValidInput(form.privateGardenSize, 0, configuration.displayPrivateGardenSize !== VariableDisplay.Required) : true)
        && (isPartOfBuilding(form) ? isValidInput(form.communalGardenSize, 0, configuration.displayCommunalGardenSize !== VariableDisplay.Required) : true)
        && isValidInput(form.floorCount, 1)
        && isValidInput(form.numberOfIndoorParkingSpacesMainBuilding)
        && isValidInput(form.numberOfIndoorParkingSpacesSeparateBuilding)
        && isValidInput(form.numberOfOutdoorParkingSpacesOpenAir)
        && isValidInput(form.numberOfOutdoorParkingSpacesCarport)
        && (isPartOfBuilding(form) ? isValidInput(form.floor) : true)
        && !isNullOrUndefined(form.roofType);
}

