import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {AttachmentType, WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import {VariableDisplay} from '../../../types/variable-display';
import LoaderComponent from '../../../components/loader/loader';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step10Component: FunctionComponent<Step10Props> = ({
                                                             form,
                                                             configuration,
                                                             totalSteps,
                                                             onNextClick,
                                                             onPreviousClick,
                                                             onChange,
                                                             paymentRequired,
                                                             updateInProgress
                                                         }: Step10Props) => {
    const {t} = useTranslation();
    const [remarks, setRemarks] = useState<string | undefined>(form.overallRemarks || '');
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        onChange(updatedForm);
    }, [remarks]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        updatedForm.overallRemarks = remarks;

        return updatedForm
    }

    const validateFields = () => {
        // currently there is nothing to validate on this step, everything is optional
    }

    const {next, back} = usePreviousAndNext(
        true,
        onNextClick,
        onPreviousClick,
        validateFields,
        () => true,
        updateForm,
        onChange
    );

    const onRemarksChanged = (remarks: string) => {
        const input = remarks.replace(';', '');
        setRemarks(input);
    };

    return (
        <>
            <StepHeaderComponent
                step={10}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <div className={styles.formField}>
                        <label className={styles.label}>
                            {t('Enter additional remarks')}
                            <div className={styles.labelInfo}>{t('Optional')}</div>
                        </label>
                        <textarea rows={8}
                                  value={remarks}
                                  onChange={(event) => onRemarksChanged(event.target.value)}
                        />
                        <div className={styles.footnote}>
                            {t('Any information that can help us better valuate your property is welcome')}
                        </div>
                    </div>
                    <FileHandlerComponent attachmentTypes={[AttachmentType.Other]}
                                          label={t('Upload other files')}
                                          display={VariableDisplay.Optional}/>
                </div>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>
                            {paymentRequired ? t('Continue') : t('Finish')} <ChevronRight className={styles.icon}/>
                        </> : <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step10Component;

interface Step10Props extends StepProps {
    paymentRequired: boolean;
}

export const step10IsValid = (form: WebForm): boolean => {
    return true;
}
