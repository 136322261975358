import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './not-found.module.scss';
import notFoundIcon from '../../../assets/images/404.svg';

const NotFoundPage: FunctionComponent = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.imgContainer}>
                    <img src={notFoundIcon}/>
                </div>
                <div className={styles.textContainer}>
                    <h1>{t('Page not found')}</h1>
                    <p>{t('The page you are looking for does not exist or an other error occurred.')}</p>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;
