import React, {DragEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './file-uploader.module.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as FileUploadIcon} from '../../../../../assets/images/cloud-arrow-up-solid.svg'
import {AppContext} from '../../../../store/app-context';
import {LayoutActionTypes} from '../../../../store/layout/actions';
import {ToastMessage, ToastMessageType} from '../../../../types/toast';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {v4 as uuidv4} from 'uuid';


const FileUploaderComponent: FunctionComponent<ownProps> = ({onFileSelection, invalid, hasLeftPanel}: ownProps) => {
    const {dispatch} = useContext(AppContext);
    const {t} = useTranslation();
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [size, setSize] = useState([0, 0]);
    const [fileKey, setFileKey] = useState<string>(uuidv4());

    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const onFileSelectionClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (files: FileList | null) => {
        if (files) {
            if (!filesHaveCorrectType(files)) {
                dispatch({
                    type: LayoutActionTypes.AddToast,
                    toast: new ToastMessage(ToastMessageType.Warning, t('Unsupported file format, we only support PDF and image documents.'))
                });
            } else if (!filesHaveCorrectSize(files)) {
                dispatch({
                    type: LayoutActionTypes.AddToast,
                    toast: new ToastMessage(ToastMessageType.Warning, t('File is to big!'))
                });
            } else {
                onFileSelection(files);
                setFileKey(uuidv4());
            }
        }
    };

    const handleDragEnter = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        if (event.dataTransfer.files.length > 0) {
            handleChange(event.dataTransfer.files);
        }
    };

    const filesHaveCorrectType = (files: FileList): boolean => {
        const acceptedFileTypes = [
            'image', 'pdf', 'heic'
        ];
        for (let i = 0; i < files.length; i++) {
            if (!acceptedFileTypes.some(type => files[i].type.includes(type))) {
                return false;
            }
        }
        return true;
    }

    const filesHaveCorrectSize = (files: FileList): boolean => {
        // nsx limit is 45MB, we set 1 less to be sure
        const maxAllowedSize = 1000 * 1000 * 44;
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > maxAllowedSize) {
                return false;
            }
        }
        return true;
    }

    const buttonClasses = conditionalClassLister(styles)({
        fileUploadButton: true,
        invalid: invalid,
        borderRadiusLeft: !hasLeftPanel,
    });

    return (
        <>
            <button onClick={(e) => onFileSelectionClick()} className={buttonClasses}
                    onDrop={e => handleDrop(e)}
                    onDragOver={e => handleDragOver(e)}
                    onDragEnter={e => handleDragEnter(e)}
                    onDragLeave={e => handleDragLeave(e)}
                    onDragStart={e => {
                        e.dataTransfer.effectAllowed = 'move';
                        e.dataTransfer.dropEffect = 'copy';
                    }}
            >
                <div className={styles.buttonContent}>
                    <FileUploadIcon width="50" className={styles.fileUploadIcon}/>
                    {size[0] > 700 ?
                        t('Drag & drop your files here or click to upload a file')
                        : t('Click here to upload a file')}
                </div>
            </button>
            <input
                type="file"
                key={fileKey}
                multiple
                ref={hiddenFileInput}
                onChange={(e) => handleChange(e.target.files)}
                accept="image/*,application/pdf,.heic"
                style={{display: 'none'}}
            />
        </>
    );
}

export default FileUploaderComponent;

interface ownProps {
    onFileSelection: (files: FileList) => void;
    invalid?: boolean;
    hasLeftPanel: boolean
}
