import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './webform-invalid.module.scss';
import stadimLogo from '../../../assets/images/stadim_logo.png';
import LanguageSelectorComponent, {LanguageSelectorLocation} from '../../components/language-selector/language-selector.component';

const WebformInvalidPage: FunctionComponent = () => {
    const {t} = useTranslation();


    return (
        <div className={styles.content}>
            <div className={styles.leftPanel}>
                <div className={styles.wrapper}>
                    <div className={styles.topContainer}>
                        <img src={stadimLogo} className={styles.logo} alt="placeholder"/>
                        <LanguageSelectorComponent languageSelectorLocation={LanguageSelectorLocation.Body}/>
                    </div>
                    <div className={styles.textPanel}>
                        <p className={styles.titleText}>{t('Your invitation is no longer valid')}</p>
                        <div className={styles.bodyText}>
                            <p className={styles.italic}>{t('This means you might have already submitted the survey or your bank cancelled the invitation.')}</p>
                            <p className={styles.bold}>{t('Please contact your bank if this is not according to expectations.')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.opacityMask}/>
            </div>
        </div>
    )
}

export default WebformInvalidPage;
