import {WebForm} from './types';

export enum WebFormActionTypes {
    SetWebForm = 'WebForm/SetWebForm',
    SetIsDirty = 'WebForm/SetIsDirty',
}

export type WebFormActions =
    | { type: WebFormActionTypes.SetWebForm, webForm: WebForm }
    | { type: WebFormActionTypes.SetIsDirty, isDirty: boolean };
