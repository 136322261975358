import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {NextAndPreviousProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {AppContext} from '../../../store/app-context';
import {useParams} from 'react-router-dom';
import {FormParams} from '../web-form.types';
import {conditionalClassLister} from '../../../utils/class-helpers';
import InvoiceApi from '../../../api/invoice-api';
import {InvoiceDetailsRequest} from '../../../types/requests/invoice-details-request';
import {LayoutActionTypes} from '../../../store/layout/actions';
import {ToastMessage, ToastMessageType} from '../../../types/toast';
import StepHeaderComponent from '../components/step-header/step-header.component';

const InvoiceComponent: FunctionComponent<NextAndPreviousProps> = ({
                                                                       onNextClick,
                                                                       onPreviousClick,
                                                                   }: NextAndPreviousProps) => {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const [errors, setErrors] = useState<InvoiceErrors>({});
    const [invoiceRequired, setInvoiceRequired] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>('');
    const [street, setStreet] = useState<string | undefined>('');
    const [city, setCity] = useState<string | undefined>('');
    const [country, setCountry] = useState<string | undefined>(t('Belgium'));
    const [email, setEmail] = useState<string | undefined>('');
    const [company, setCompany] = useState<string | undefined>('');
    const [vatNumber, setVatNumber] = useState<string | undefined>('');
    const {bankId} = useParams<FormParams>();

    useEffect(() => {
        if (state.paymentState.payment && state.paymentState.payment.invoiceDetails) {
            const invoiceDetail = state.paymentState.payment.invoiceDetails;
            setName(invoiceDetail.name);
            setStreet(invoiceDetail.street);
            setCity(invoiceDetail.city);
            setCountry(invoiceDetail.country);
            setEmail(invoiceDetail.email);
            setCompany(invoiceDetail.company);
            setVatNumber(invoiceDetail.vatNumber);
        }
    }, [state.paymentState.payment]);

    const back = () => {
        onPreviousClick();
    };

    const next = () => {
        if (((invoiceRequired && email) || !invoiceRequired) && name && street && city && country) {
            const invoiceRequest: InvoiceDetailsRequest = {
                name,
                street,
                city,
                country,
                email,
                company,
                vatNumber,
            }
            InvoiceApi.saveInvoiceDetails(bankId, state.paymentState.payment!.id, invoiceRequest)
                .then(() => {
                    onNextClick();
                })
                .catch(err => {
                    dispatch({
                        type: LayoutActionTypes.AddToast,
                        toast: new ToastMessage(ToastMessageType.Error, 'Something went wrong, please try again later.'),
                    });
                });
        } else {
            if (!name) {
                setErrors((prevState) => ({
                    ...prevState,
                    // t('name_required_error')
                    name: 'name_required_error',
                }));
            }
            if (!street) {
                setErrors((prevState) => ({
                    ...prevState,
                    // t('street_required_error')
                    street: 'street_required_error',
                }));
            }
            if (!city) {
                setErrors((prevState) => ({
                    ...prevState,
                    // t('city_required_error')
                    city: 'city_required_error',
                }));
            }
            if (!country) {
                setErrors((prevState) => ({
                    ...prevState,
                    // t('country_required_error')
                    country: 'country_required_error',
                }));
            }
            if (invoiceRequired && !email) {
                setErrors((prevState) => ({
                    ...prevState,
                    // t('email_required_error')
                    email: 'email_required_error',
                }));
            }
        }
    }

    const onInvoiceRequiredChanged = () => {
        if (invoiceRequired) {
            setEmail('');
            setErrors((prevState) => ({
                ...prevState,
                email: undefined,
            }));
        } else {
            setEmail(state.webFormState.webForm?.invitation?.email);
        }
        setInvoiceRequired(!invoiceRequired);
    }

    return (
        <>
            <StepHeaderComponent
                step={11}
                totalSteps={12}
                maxActiveStep={11}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.formField}>{t('invoice_info_1')}</div>
                <div className={styles.formField}>{t('invoice_info_2')}</div>
                <div className={styles.formField}>{t('invoice_info_3')}</div>
                <div className={styles.formField}>
                    <div className={styles.checkBoxCentered} onClick={() => onInvoiceRequiredChanged()}>
                        <input className={styles.checkBox} type="checkbox" checked={invoiceRequired}
                               onChange={() => onInvoiceRequiredChanged()}/>
                        <div className={styles.checkBoxLabel}>{t('invoice_required_text')} </div>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.email,
                        })}>
                            {t('Email')}
                        </label>
                        {errors.email ? <div className={styles.labelError}>{t(errors.email)}</div> : null}
                        <input type="text"
                               className={conditionalClassLister(styles)({
                                   input: true,
                                   invalidInput: errors.email,
                                   disabled: !invoiceRequired,
                               })}
                               disabled={!invoiceRequired}
                               value={email}
                               onChange={(event) => setEmail(event.target.value)}
                               onBlur={(e) => {
                                   if (e.target.value === '') {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           email: t('email_required_error')
                                       }));
                                   } else {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           email: undefined
                                       }));
                                   }
                               }}/>
                    </div>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.name,
                        })}>
                            {t('Surname + Name')}
                        </label>
                        {errors.name ? <div className={styles.labelError}>{t(errors.name)}</div> : null}
                        <input type="text"
                               className={conditionalClassLister(styles)({
                                   input: true,
                                   invalidInput: errors.name,
                               })}
                               value={name}
                               onChange={(event) => setName(event.target.value)}
                               onBlur={(e) => {
                                   if (e.target.value === '') {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           // t('name_required_error')
                                           name: 'name_required_error',
                                       }));
                                   } else {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           name: undefined,
                                       }));
                                   }
                               }}/>
                    </div>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.street,
                        })}>
                            {t('Street + number')}
                        </label>
                        {errors.street ? <div className={styles.labelError}>{t(errors.street)}</div> : null}
                        <input type="text"
                               className={conditionalClassLister(styles)({
                                   input: true,
                                   invalidInput: errors.street,
                               })}
                               value={street}
                               onChange={(event) => setStreet(event.target.value)}
                               onBlur={(e) => {
                                   if (e.target.value === '') {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           // t('street_required_error')
                                           street: 'street_required_error'
                                       }));
                                   } else {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           street: undefined
                                       }));
                                   }
                               }}/>
                    </div>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.city,
                        })}>
                            {t('Postal code + city')}
                        </label>
                        {errors.city ? <div className={styles.labelError}>{t(errors.city)}</div> : null}
                        <input type="text"
                               className={conditionalClassLister(styles)({
                                   input: true,
                                   invalidInput: errors.city,
                               })}
                               value={city}
                               onChange={(event) => setCity(event.target.value)}
                               onBlur={(e) => {
                                   if (e.target.value === '') {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           // t('city_required_error')
                                           city: 'city_required_error'
                                       }));
                                   } else {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           city: undefined
                                       }));
                                   }
                               }}/>
                    </div>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.country,
                        })}>
                            {t('Country')}
                        </label>
                        {errors.country ? <div className={styles.labelError}>{t(errors.country)}</div> : null}
                        <input type="text"
                               className={conditionalClassLister(styles)({
                                   input: true,
                                   invalidInput: errors.country,
                               })}
                               value={country}
                               onChange={(event) => setCountry(event.target.value)}
                               onBlur={(e) => {
                                   if (e.target.value === '') {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           // t('country_required_error')
                                           country: 'country_required_error'
                                       }));
                                   } else {
                                       setErrors((prevState) => ({
                                           ...prevState,
                                           country: undefined
                                       }));
                                   }
                               }}/>
                    </div>
                    <div className={styles.formField}>
                        <label className={styles.label}>
                            {t('Company')}
                            <div className={styles.labelInfo}>{t('Optional')}</div>
                        </label>
                        <input type="text"
                               className={styles.input}
                               value={company}
                               onChange={(event) => setCompany(event.target.value)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <label className={styles.label}>
                            {t('VAT number')}
                            <div className={styles.labelInfo}>{t('Optional')}</div>
                        </label>
                        <input type="text"
                               className={styles.input}
                               value={vatNumber}
                               onChange={(event) => setVatNumber(event.target.value)}
                        />
                    </div>
                </div>
                {errors.name || errors.street || errors.city || errors.country || errors.email ?
                    <div className={styles.errorsPanel}>
                        {t('required_fields_error')}
                    </div> : null}
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {t('Continue')}
                        <ChevronRight className={styles.icon}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceComponent;

interface InvoiceErrors {
    name?: string;
    street?: string;
    city?: string;
    country?: string;
    email?: string;
}
