import React, {FunctionComponent, useContext, useEffect, useState,} from 'react';
import styles from './web-form.page.module.scss';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import Step0Component from './steps/step-0.component';
import Step1Component, {step1IsValid} from './steps/step-1.component';
import Step2Component, {step2IsValid} from './steps/step-2.component';
import Step3Component, {step3IsValid} from './steps/step-3.component';
import Step4Component, {step4IsValid} from './steps/step-4.component';
import Step5Component, {step5IsValid} from './steps/step-5.component';
import Step6Component, {step6IsValid} from './steps/step-6.component';
import Step7Component, {step7IsValid} from './steps/step-7.component';
import Step8Component, {step8IsValid} from './steps/step-8.component';
import Step9Component, {step9IsValid} from './steps/step-9.component';
import Step10Component, {step10IsValid} from './steps/step-10.component';
import InvoiceComponent from './steps/invoice.component';
import PaymentComponent from './steps/payment.component';
import {PaymentStrategy, PropertyType, WebForm, webformIsEqual, WebformStatus,} from '../../store/webform/types';
import {AppContext} from '../../store/app-context';
import WebformApi from '../../api/webform-api';
import {FormParams} from './web-form.types';
import {WebFormActionTypes} from '../../store/webform/actions';
import StadimLogoWhite from '../../../assets/images/stadim_logo_wit.png';
import StepCompletedComponent from './steps/step-completed.component';
import {PaymentActionTypes} from '../../store/payment/actions';
import PaymentApi from '../../api/payment-api';
import {WebformConfiguration} from '../../store/bank/types';
import {BankActionTypes} from '../../store/bank/actions';
import {LayoutActionTypes} from '../../store/layout/actions';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import {useTranslation} from 'react-i18next';
import {RouteUrl} from '../../routes';
import LanguageSelectorComponent, {LanguageSelectorLocation,} from '../../components/language-selector/language-selector.component';

const WebFormPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [form, setForm] = useState<WebForm>();
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const { invitationToken, bankId } = useParams<FormParams>();
  const location = useLocation();
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const searchParams = new URLSearchParams(location.search);
  const [totalSteps, setTotalSteps] = useState<number>(10);
  const [paymentRequired, setPaymentRequired] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<WebformConfiguration>(
    state.bankState.configuration
  );
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [hasDataFromApi, setHasDataFromApi] = useState<boolean>(false);

  useEffect(() => {
    setForm(state.webFormState.webForm);
  }, [state.webFormState.webForm]);

  useEffect(() => {
    if (
      state.webFormState.webForm &&
      state.webFormState.webForm.invitation &&
      state.webFormState.webForm.invitation.paymentStrategy ===
        PaymentStrategy.ClientPayment
    ) {
      setTotalSteps(12);
      setPaymentRequired(true);
      PaymentApi.getPayment(bankId, state.webFormState.webForm.id).then(
        (payment) => {
          dispatch({ type: PaymentActionTypes.SetPayment, payment });
        }
      );
    }
  }, [state.webFormState.webForm?.invitation]);

  useEffect(() => {
    // get WebForm from server when none is present
    if (!form) {
      if (invitationToken !== 'DEMO-TOKEN') {
        WebformApi.getWebformByTokenId(bankId, invitationToken)
          .then((webform) => {
            dispatch({ type: WebFormActionTypes.SetWebForm, webForm: webform });
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              window.location.replace(RouteUrl.WebFormInvalid);
            } else {
              dispatch({
                type: LayoutActionTypes.AddToast,
                toast: new ToastMessage(
                  ToastMessageType.Error,
                  'Something went wrong, please try again later.'
                ),
              });
            }
          });
      } else {
        dispatch({
          type: WebFormActionTypes.SetWebForm,
          webForm: {
            id: 'DEMO-TOKEN',
          } as WebForm,
        });
      }
    }

    if (searchParams.get('step')) {
      const stepFromUrl = !isNaN(parseInt(searchParams.get('step')!))
        ? parseInt(searchParams.get('step')!)
        : 0;
      setStep(stepFromUrl);
    }

    if (state.bankState.bank) {
      setConfiguration(state.bankState.bank.webformConfiguration);
    } else {
      WebformApi.getBank(bankId).then((bank) => {
        dispatch({ type: BankActionTypes.SetBank, bank });
        setConfiguration(bank.webformConfiguration);
      });
    }
  }, []);

  useEffect(() => {
    if (step === 0) {
      searchParams.delete('step');
    } else {
      searchParams.set('step', step.toString());
    }
    history.push({ search: searchParams.toString() });
  }, [step]);

  const startQuestionnaire = () => {
    if (!inProgress && form) {
      if (state.webFormState.isDirty) {
        sendUpdatedForm(bankId, form).then((result) => {
          if (result.success) {
            setStep(1);
            window.scrollTo({ top: 0 });
          }
        });
      } else {
        setStep(1);
        window.scrollTo({ top: 0 });
      }
    }
  };

  const goToNextStep = async () => {
    if (!inProgress && form) {
      if (state.webFormState.isDirty) {
        sendUpdatedForm(bankId, form).then((result) => {
          if (result.success) {
            const nextStep = step + 1;
            setStep(nextStep);
            window.scrollTo({ top: 0 });
          }
        });
      } else {
        const nextStep = step + 1;
        setStep(nextStep);
        window.scrollTo({ top: 0 });
      }
    }
  };

  const goToPreviousStep = async () => {
    if (!inProgress && form) {
      if (state.webFormState.isDirty) {
        sendUpdatedForm(bankId, form).then((result) => {
          if (result.success) {
            const previousStep = step - 1;
            if (previousStep >= 0) {
              setStep(previousStep);
              window.scrollTo({ top: 0 });
            }
          }
        });
      } else {
        const previousStep = step - 1;
        if (previousStep >= 0) {
          setStep(previousStep);
          window.scrollTo({ top: 0 });
        }
      }
    }
  };

  const onStep10Click = () => {
    if (!paymentRequired) {
      submitForm();
    } else {
      goToNextStep();
    }
  };

  const submitForm = () => {
    if (!inProgress && form) {
      const updatedForm = Object.assign({}, form);
      updatedForm.status = WebformStatus.COMPLETED;
      sendUpdatedForm(bankId, updatedForm).then((result) => {
        if (result.success) {
          setStep(0);
          setIsFormCompleted(true);
        }
      });
    }
  };

  const updateForm = async (updatedForm: WebForm, forceSave = false) => {
    if (form) {
      if (!webformIsEqual(updatedForm, form) && !state.webFormState.isDirty) {
        if (forceSave) {
          await sendUpdatedForm(bankId, updatedForm);
        } else {
          dispatch({ type: WebFormActionTypes.SetIsDirty, isDirty: true });
        }
      }
      setForm(updatedForm);
    }
  };

  const sendUpdatedForm = async (
    bankId: string,
    updatedForm: WebForm
  ): Promise<UpdateResult> => {
    setInProgress(true);
    return WebformApi.updateWebform(bankId, updatedForm)
      .then((response) => {
        compareWebforms(updatedForm, response);
        dispatch({ type: WebFormActionTypes.SetWebForm, webForm: response });
        dispatch({ type: WebFormActionTypes.SetIsDirty, isDirty: false });
        setInProgress(false);
        return { success: true };
      })
      .catch(() => {
        dispatch({
          type: LayoutActionTypes.AddToast,
          toast: new ToastMessage(
            ToastMessageType.Error,
            t(
              'Something went wrong, your input might not have been saved. Please refresh the page and try again.'
            )
          ),
        });
        setInProgress(false);
        return { success: false };
      });
  };

  const compareWebforms = (sendForm: WebForm, receivedForm: WebForm) => {
    if (sendForm.plotSize !== receivedForm.plotSize
        || sendForm.livingAreaSize !== receivedForm.livingAreaSize
        || sendForm.floorCount !== receivedForm.floorCount
        || sendForm.roofType !== receivedForm.roofType
    ) {
      setHasDataFromApi(true);
    } else {
      setHasDataFromApi(false);
    }
  }

  const navigateTo = (url: string): void => {
    window.open(url);
  };

  return (
    <div className={styles.page}>
      {(step === 0 || !step) && form ? (
        <>
          {isFormCompleted ? (
            <StepCompletedComponent />
          ) : (
            <Step0Component
              form={form}
              onChange={(form: WebForm) => updateForm(form)}
              onGetStartedClick={() => startQuestionnaire()}
            />
          )}{' '}
        </>
      ) : (
        <>
          <div className={styles.logoBar}>
            <div className={styles.innerContainer}>
              <LanguageSelectorComponent languageSelectorLocation={LanguageSelectorLocation.Body}/>
            </div>
          </div>
          <div className={styles.addressBar}>
            <div className={styles.innerContainer}>{form?.address}</div>
          </div>
          <div className={styles.languageContainer}>
            <div className={styles.innerContainer}>
              <LanguageSelectorComponent languageSelectorLocation={LanguageSelectorLocation.Body}/>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.multiStepForm}>
              {form && step !== 0 ? (
                <>
                  {step === 1 ? (
                    <Step1Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 2 ? (
                    <Step2Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 3 ? (
                    <Step3Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                      hasDataFromApi={hasDataFromApi}
                    />
                  ) : null}
                  {step === 4 ? (
                    <Step4Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 5 ? (
                    <Step5Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 6 ? (
                    <Step6Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 7 ? (
                    <Step7Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 8 ? (
                    <Step8Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 9 ? (
                    <Step9Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 10 ? (
                    <Step10Component
                      form={form}
                      totalSteps={totalSteps}
                      configuration={configuration}
                      onChange={(form: WebForm, forceSave?: boolean) => updateForm(form, forceSave)}
                      updateInProgress={inProgress}
                      onNextClick={() => onStep10Click()}
                      paymentRequired={paymentRequired}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 11 ? (
                    <InvoiceComponent
                      onNextClick={() => goToNextStep()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                  {step === 12 ? (
                    <PaymentComponent
                      onNextClick={() => submitForm()}
                      onPreviousClick={() => goToPreviousStep()}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.footerSpacer} />
          <div className={styles.footer}>
            <div className={styles.innerContainer}>
              <div className={styles.footerColumn}>
                <div className={styles.title}>STADIM</div>
                <div
                  className={styles.link}
                  onClick={() => navigateTo(`https://www.stadim.be/${i18n.language}`)}
                >
                  {t('About Us')}
                </div>
                <div className={styles.link}>
                  <a
                    className={styles.link}
                    href={'mailto:supportdigital@stadim.be'}
                  >
                    {t('Contact Us')}
                  </a>
                </div>
              </div>
              <div className={styles.footerColumn}>
                <img className={styles.logo} alt='logo' src={StadimLogoWhite} />
                <LanguageSelectorComponent
                  languageSelectorLocation={LanguageSelectorLocation.Footer}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WebFormPage;

export function isPartOfBuilding(webform: WebForm): boolean {
  return (
    webform.propertyType === PropertyType.Apartment ||
    webform.propertyType === PropertyType.Studio ||
    webform.propertyType === PropertyType.Penthouse ||
    webform.propertyType === PropertyType.Duplex ||
    webform.propertyType === PropertyType.Triplex ||
    webform.propertyType === PropertyType.StudentRoom ||
    webform.propertyType === PropertyType.ServiceFlat
  );
}

type UpdateResult = {
  success: boolean;
};

export const getMaxActiveStep = (form?: WebForm, configuration?: WebformConfiguration): number => {
  if (!form || !configuration) return 0;
  let result = 1;
  let proceed = true;
  proceed && step1IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  proceed && step2IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  proceed && step3IsValid(form, configuration)
      ? (result = ++result)
      : (proceed = false);
  proceed && step4IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  proceed && step5IsValid(form, configuration)
      ? (result = ++result)
      : (proceed = false);
  proceed && step6IsValid(form, configuration)
      ? (result = ++result)
      : (proceed = false);
  proceed && step7IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  proceed && step8IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  proceed && step9IsValid(form, configuration)
      ? (result = ++result)
      : (proceed = false);
  proceed && step10IsValid(form)
      ? (result = ++result)
      : (proceed = false);
  return result;
};
