import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import styles from './steps.module.scss';
import {NextAndPreviousProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {AppContext} from '../../../store/app-context';
import {PaymentStatus, TransactionStatus} from '../../../store/payment/types';
import {useParams} from 'react-router-dom';
import {FormParams} from '../web-form.types';
import PaymentApi from '../../../api/payment-api';
import LoaderComponent from '../../../components/loader/loader';
import {LayoutActionTypes} from '../../../store/layout/actions';
import {ToastMessage, ToastMessageType} from '../../../types/toast';
import StepHeaderComponent from '../components/step-header/step-header.component';

const PaymentComponent: FunctionComponent<NextAndPreviousProps> = ({
                                                                       onNextClick,
                                                                       onPreviousClick,
                                                                   }: NextAndPreviousProps) => {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const [errors, setErrors] = useState<PaymentErrors>({});
    const [mollieUrl, setMollieUrl] = useState<string>('');
    const [amount, setAmount] = useState<string>();
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const {bankId} = useParams<FormParams>();

    useEffect(() => {
        if (state.paymentState.payment?.status === PaymentStatus.Open) {
            checkPayment(state.paymentState.payment.id);
        } else if (state.paymentState.payment?.status === PaymentStatus.Paid) {
            // when the payment is received, we finish the form
            setFinished(true);
            setTimeout(onNextClick, 5000);
        }
    }, [state.paymentState.payment]);

    useEffect(() => {
        if (isPolling) {
            pollForStatusChange();
        }
    }, [isPolling]);

    const getPaymentUrl = async () => {
        if (state.webFormState.webForm) {
            const url = await PaymentApi.getPaymentUrl(
                bankId,
                state.webFormState.webForm.id
            );
            setMollieUrl(url);
        }
    };

    const checkPayment = async (paymentId: string) => {
        await PaymentApi.getLatestTransaction(bankId, paymentId).then(
            async (transaction) => {
                if (transaction) {
                    setAmount(
                        (Math.round(transaction.amountVatInclusive * 100) / 100).toFixed(2)
                    );
                }
                if (!transaction || transaction.status === TransactionStatus.open) {
                    setIsPolling(false);
                    getPaymentUrl();
                } else {
                    setIsPolling(true);
                }
            }
        );
    };

    const pollForStatusChange = async () => {
        if (state.paymentState.payment && isPolling) {
            PaymentApi.getLatestTransaction(
                bankId,
                state.paymentState.payment.id
            ).then(async (transaction) => {
                if (transaction) {
                    if (transaction.status === TransactionStatus.paid) {
                        // paid => we can stop polling and finish the form
                        setIsPolling(false);
                        setFinished(true);
                    } else if (
                        transaction.status === TransactionStatus.open ||
                        transaction.status === TransactionStatus.pending
                    ) {
                        // open => there was no status change yet, so we keep polling
                        setTimeout(pollForStatusChange, 5000);
                    } else {
                        // cancelled, expired, failed
                        // we need a new url
                        setIsPolling(false);
                        setErrors((prevState) => ({
                            ...prevState,
                            paymentFailed: t('payment_failed_error'),
                        }));
                        getPaymentUrl();
                    }
                } else {
                    // this should never happen, when a payment is required, there should always be a transaction
                    dispatch({
                        type: LayoutActionTypes.AddToast,
                        toast: new ToastMessage(
                            ToastMessageType.Error,
                            'Something went wrong, please contact an administrator.'
                        ),
                    });
                }
            });
        }
    };

    const back = () => {
        if (!finished) {
            onPreviousClick();
        }
    };

    const nextButtonClick = () => {
        if (mollieUrl && !finished) {
            window.location.href = mollieUrl;
        }
    };

    const doNothing = (): void => {
        // prevent the arrow in the header from submitting the form on this step
    }

    return (
        <>
            <StepHeaderComponent
                step={12}
                totalSteps={12}
                maxActiveStep={12}
                onNextClick={doNothing}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    {!errors.paymentFailed && !finished ? (
                        <div className={styles.formField}>{t('payment_info_1')}</div>
                    ) : null}
                    {amount ? (
                        <div className={styles.formField}>
                            {t('payment_info_2', {amount})}
                        </div>
                    ) : null}
                    {errors.paymentFailed ? (
                        <div className={styles.formField}>{errors.paymentFailed}</div>
                    ) : null}
                    {finished ? (
                        <div className={styles.formField}>{t('payment_success')}</div>
                    ) : null}
                </div>
                <div className={styles.buttons}>
                    <div
                        className={`${styles.button} ${styles.negative}`}
                        onClick={() => back()}
                    >
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => nextButtonClick()}>
                        {mollieUrl && !finished ? (
                            <>
                                {t('payment_button')}
                                <ChevronRight className={styles.icon}/>
                            </>
                        ) : (
                            <LoaderComponent color={'white'}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentComponent;

interface PaymentErrors {
    paymentFailed?: string;
    paymentRequired?: string;
}
