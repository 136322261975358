import React, {FunctionComponent, useEffect, useState} from 'react';
import {EpcCategory} from '../../../../store/webform/types';
import styles from './epc-arrow.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {ReactComponent as PlaySolid} from '../../../../../assets/images/play-solid.svg';

const EPCArrowComponent: FunctionComponent<OwnProps> = ({category, activeCategory, zIndex, onClick}: OwnProps) => {
    const [label, setLabel] = useState<string>('');

    useEffect(() => {
        if (category === EpcCategory.APlus) {
            setLabel('A+');
        } else {
            setLabel(category);
        }
    }, [category]);

    const classNames = conditionalClassLister(styles)({
        box: true,
        active: category === activeCategory,
        aPlusBackground: category === EpcCategory.APlus,
        aBackground: category === EpcCategory.A,
        bBackground: category === EpcCategory.B,
        cBackground: category === EpcCategory.C,
        dBackground: category === EpcCategory.D,
        eBackground: category === EpcCategory.E,
        fBackground: category === EpcCategory.F,
        gBackground: category === EpcCategory.G,
    })


    return (
        <div className={classNames} onClick={() => onClick(category)} style={{zIndex}}>
            <div className={styles.nose} style={{zIndex: zIndex - 1}} />
            <div className={styles.tail} style={{zIndex: zIndex + 1}} />
            <div className={styles.label}>
                {label}
                {category === activeCategory && <PlaySolid className={styles.activeIndicator}/>}
            </div>
        </div>
    )
}

export default EPCArrowComponent;

interface OwnProps {
    category: EpcCategory;
    activeCategory?: EpcCategory;
    zIndex: number;
    onClick: (category: EpcCategory) => void;
}
