import React, {FunctionComponent} from 'react';
import styles from './input.module.scss';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {VariableDisplay} from '../../../../types/variable-display';
import {conditionalClassLister} from '../../../../utils/class-helpers';

const LabeledNumberInputComponent: FunctionComponent<OwnProps> = ({
                                                                      id,
                                                                      label,
                                                                      footnote,
                                                                      display,
                                                                      errors,
                                                                      value,
                                                                      unit,
                                                                      valueChanged,
                                                                      valueBlurred,
                                                                      maxLength,
                                                                  }: OwnProps) => {
    const {t} = useTranslation();

    const displayProperty = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    const isOptional = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Optional;
    }

    const onValueChange = (value: string) => {
        if (valueChanged) {
            valueChanged(value);
        }
    }

    const onValueBlur = (value: string) => {
        if (valueBlurred) {
            valueBlurred(value);
        }
    }

    return displayProperty(display) ? (
        <div className={styles.formField}>
            <label className={conditionalClassLister(styles)({
                label: true,
                withError: !!errors,
            })} htmlFor={id}>
                {label}
                {isOptional(display) &&
                <span>
                    <small>{t('Optional')}</small>
                </span>}
            </label>
            {errors ? <div className={styles.labelError}>{t(errors)}</div> : <></>}
            <div className={styles.inputBox}>
                <NumberFormat
                    className={conditionalClassLister(styles)({
                        input: true,
                        withUnit: !!unit,
                        invalidInput: errors
                    })}
                    type="text"
                    pattern="[0-9]*"
                    min={0}
                    thousandSeparator="&thinsp;"
                    maxLength={maxLength}
                    value={value}
                    onChange={(event: any) => onValueChange(event.target.value)}
                    onBlur={(event: any) => onValueBlur(event.target.value)}
                />
                {unit && <span className={styles.unit}>{unit}</span>}
            </div>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}
export default LabeledNumberInputComponent;

type OwnProps = {
    id: string;
    label: string;
    display: VariableDisplay;
    errors?: string;
    value?: string | number;
    unit?: string;
    footnote?: string;
    valueChanged: (value: string) => void;
    valueBlurred?: (value: string) => void;
    maxLength?: number;
}
