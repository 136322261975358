import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './step-0.module.scss';
import {useTranslation} from 'react-i18next';
import stadimLogo from '../../../../assets/images/stadim_logo.png';
import {AppContext} from '../../../store/app-context';
import {Bank} from '../../../store/bank/types';

const StepCompletedComponent: FunctionComponent = () => {
    const {state} = useContext(AppContext);
    const {t} = useTranslation();
    const [bank, setBank] = useState<Bank | undefined>(state.bankState.bank);

    useEffect(() => {
        if (state.bankState.bank) {
            setBank(state.bankState.bank);
        }
    }, [state.bankState.bank]);

    return (
        <div className={styles.content}>
            <div className={styles.leftPanel}>
                <div className={styles.wrapper}>
                    <img src={stadimLogo} className={styles.logo} alt="placeholder"/>
                    <div className={styles.textPanel}>
                        <div className={styles.titleText}>
                        <p>{t('outro_p_1_1')}</p>
                        </div>
                        <div className={styles.bodyText}>
                            <p>{t('outro_p_1_2')}</p>
                            {state.webFormState.webForm?.invitation?.externalRef ? <p>{t('outro_p_2_1')}: {state.webFormState.webForm?.invitation?.externalRef} </p> : null}
                            {bank ? <p>{t('outro_p_2_2')} <a className={styles.link} href={`mailto:${bank.supportEmail}`}>{bank.supportEmail.toLowerCase()}</a></p> : null}
                            <p><a className={styles.link} href={t('outro_p_3_1')} rel="noreferrer" target="_blank">www.stadim.be</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.opacityMask}></div>
            </div>
        </div>
    )
}

export default StepCompletedComponent;
