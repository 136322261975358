import {ToastMessage} from '../../types/toast';
import {AppAction} from '../types';
import {LayoutActionTypes} from './actions';

export type LayoutState = {
    toasts: ToastMessage[];
};

export const initialLayoutState: LayoutState = {
    toasts: []
};

export const layoutReducer = (state: LayoutState, action: AppAction): LayoutState => {
    switch (action.type) {
        case LayoutActionTypes.AddToast:
            return {toasts: [action.toast, ...state.toasts]};
        case LayoutActionTypes.RemoveToast:
            return {toasts: state.toasts.filter(toast => toast.id != action.toastId)};
        default:
            return state;
    }
};
