import React, {FunctionComponent} from 'react';
import styles from './google-autocomplete.module.scss';
import {ReactComponent as SearchIconComponent} from '../../../../assets/images/search.svg';
import {conditionalClassLister} from '../../../utils/class-helpers';
import PlacesAutocomplete from 'react-places-autocomplete';

interface OwnProps {
    address?: string;
    onAddressInputChange: (value: string) => void;
    onAddressChange: (value: string) => void
}

const GoogleAutocompleteComponent: FunctionComponent<OwnProps> = ({
                                                                      address,
                                                                      onAddressChange,
                                                                      onAddressInputChange
                                                                  }: OwnProps) => {

    return (
        <PlacesAutocomplete
            searchOptions={{componentRestrictions: {country: 'BE'}, types: ['address']}}
            value={address || ''}
            onChange={(value: any) => onAddressInputChange(value)}
            onSelect={(address) => {
                onAddressChange(address);
                onAddressInputChange(address);
            }}
        >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
                return (
                    <div className={styles.dropdownPanelContainer}>
                        <div className={styles.searchBar}>
                            <div className={styles.searchIconPanel}>
                                <SearchIconComponent className={styles.searchIcon}/>
                            </div>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search for address',
                                })}
                                className={conditionalClassLister(styles)({
                                    'suggestion-item': true,
                                    'input': true,
                                    mapSearchInput: true
                                })}
                                autoFocus={true}
                            />
                        </div>
                        {suggestions.length > 0 &&
                        <div className={styles.dropdownPanel}>
                            {suggestions.map((suggestion, index) => {
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {})}
                                        key={`Suggestion_${index}`}
                                        className={conditionalClassLister(styles)({
                                            'suggestion-item--active': suggestion.active,
                                            'suggestion-item': !suggestion.active,
                                            optionItem: true,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>}
                    </div>
                )
            }}
        </PlacesAutocomplete>
    )
}

export default GoogleAutocompleteComponent;
