import React, {ErrorInfo, ReactNode} from 'react';
import {AppContext} from '../../store/app-context';
import {LayoutActionTypes} from '../../store/layout/actions';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import WebformApi from '../../api/webform-api';
import {LoggerRequest} from '../../types/requests/logger-request';
import {v4 as uuidv4} from 'uuid';
import {WebForm} from '../../store/webform/types';

class ErrorBoundary extends React.Component<OwnProps, OwnState> {
    static contextType = AppContext

    constructor(props: OwnProps) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        this.handleError(error, info)
    }

    handleError(error: Error, info: ErrorInfo): void {
        const {dispatch, state} = this.context;
        const webform = state.webFormState.webForm as WebForm;

        dispatch({type: LayoutActionTypes.AddToast, toast: new ToastMessage(ToastMessageType.Error, 'Something went wrong, please try again later.')})
        if (process.env.NODE_ENV && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')) {
            console.log(error, info);
        }

        const loggerRequest: LoggerRequest = {
            correlationId: uuidv4(),
            webform,
            location: window.location.href,
            error: {message: error.message, stack: error.stack},
        }

        WebformApi.logToServer(loggerRequest);
    }

    render(): React.ReactNode {
        return this.props.children;
    }
}

interface OwnProps {
    children?: ReactNode
}
interface OwnState {
    hasError: boolean
}
export default ErrorBoundary;
