import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './number-select.module.scss';
import {conditionalClassLister} from '../../utils/class-helpers';

const NumberSelectComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {defaultValue, min, max, onValueChange, active = true, invalid} = props;
    const [value, setValue] = useState<number | undefined>(defaultValue);

    useEffect(() => {
        if (value || value === 0) {
            onValueChange(value);
        }
    }, [value]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const decreaseValue = () => {
        if (active) {
            if ((value || value === 0) && (min === undefined || value > min)) {
                setValue(value - 1);
            } else if (!value) {
                setValue(min ? min : 0);
            }
        }
    };

    const increaseValue = () => {
        if (active) {
            if ((value || value === 0) && (max === undefined || value < max)) {
                setValue(value + 1);
            } else if (!value) {
                setValue(min ? min : 1);
            }
        }
    };

    const containerClasses = conditionalClassLister(styles)({
        content: true,
        invalid: invalid,
    })

    const inputControlClasses = conditionalClassLister(styles)({
        inputControl: true,
        inactive: !active
    });

    return (
        <div className={containerClasses}>
            <div className={inputControlClasses}
                 onClick={() => decreaseValue()}>-</div>
            <div className={styles.value}>{value || value === 0 ? value.toString() : ''}</div>
            <div className={inputControlClasses}
                 onClick={() => increaseValue()}>+</div>
        </div>
    )
}

export default NumberSelectComponent;

interface OwnProps {
    defaultValue?: number;
    onValueChange: (value: number) => void;
    min?: number;
    max?: number;
    active?: boolean;
    invalid?: boolean
}
