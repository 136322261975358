import {Payment} from './types';
import {AppAction} from '../types';
import {PaymentActionTypes} from './actions';

export type PaymentState = {
    payment?: Payment;
};

export const initialPaymentState: PaymentState = {
    payment: undefined,
};

export const paymentReducer = (state: PaymentState, action: AppAction): PaymentState => {
    switch (action.type) {
        case PaymentActionTypes.SetPayment:
            return {payment: action.payment};
        default:
            return state;
    }
}
