import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {SelectionOption} from '../../../components/dropdown/dropdown.component';
import MultiSelectComponent from '../../../components/multi-select/multi-select.component';
import {
    enumToSelectionOptions,
    enumToSelectionOptionsWithExcluding
} from '../../../components/dropdown/selection-option.util';
import {AttachmentType, BuiltInFurnitureType, WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {ReactComponent as CheckboxComponent} from '../../../../assets/images/checkbox.svg';
import NumberSelectComponent from '../../../components/number-select/number-select.component';
import NumberFormat from 'react-number-format';
import FileHandlerComponent from '../components/file-uploader/file-handler.component';
import {RuntimeConfig} from '../../../../config/runtime-config';
import {VariableDisplay} from '../../../types/variable-display';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import {isNullOrUndefined, isValidInput} from '../../../utils/validation.util';
import LoaderComponent from '../../../components/loader/loader';
import {WebformConfiguration} from '../../../store/bank/types';
import LabeledMultiSelectComponent from '../components/input/labeled-multi-select.component';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step6Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [selectedAmenityOptions, setSelectedAmenityOptions] = useState<SelectionOption[]>([]);
    const [hasPoolHouse, setHasPoolHouse] = useState<boolean>(form.hasPoolHouse || false);
    const [hasGardenOffice, setHasGardenOffice] = useState<boolean>(form.hasGardenOffice || false);
    const [hasSwimmingPool, setHasSwimmingPool] = useState<boolean>(form.hasSwimmingPool || false);
    const [hasLandscapedGarden, setHasLandscapedGarden] = useState<boolean>(form.hasLandscapedGarden || false);
    const [poolHouseSize, setPoolHouseSize] = useState<number | string | undefined>(form.poolHouseSize || '');
    const [gardenOfficeSize, setGardenOfficeSize] = useState<number | string | undefined>(form.gardenOfficeSize || '');
    const [selectedBuiltInFurnitureTypeOptions, setSelectedBuiltInFurnitureTypeOptions] = useState<SelectionOption[]>([]);
    const [errors, setErrors] = useState<Step6Errors>({});
    const [requiredAmenityImages, setRequiredAmenityImages] = useState<AttachmentType[]>([]);

    const [swimmingPoolImages, setSwimmingPoolImages] = useState(0);
    const [landscapedGardenImages, setLandscapedGardenImages] = useState(0);
    const [poolHouseImages, setPoolHouseImages] = useState(0);
    const [gardenOfficeImages, setGardenOfficeImages] = useState(0);

    const amenityOptions = enumToSelectionOptionsWithExcluding(AmenityType, t, [AmenityType.PoolHouse, AmenityType.GardenOffice]);
    const builtInFurnitureOptions = enumToSelectionOptions(BuiltInFurnitureType, t);
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const initialBuiltInOptions: SelectionOption[] = [];
        if (form.builtInFurnitureType) {
            const option = builtInFurnitureOptions.find(o => o.value === form.builtInFurnitureType);
            if (option) {
                initialBuiltInOptions.push(option);
            }
        }

        setSelectedBuiltInFurnitureTypeOptions(initialBuiltInOptions);
    }, []);

    useEffect(() => {
        const options: SelectionOption[] = [];
        if (form.hasSauna)
            options.push(amenityOptions.find(o => o.value === AmenityType.Sauna)!);
        if (form.hasElevator)
            options.push(amenityOptions.find(o => o.value === AmenityType.Elevator)!);
        if (form.hasHomeAutomation)
            options.push(amenityOptions.find(o => o.value === AmenityType.HomeAutomation)!);
        if (form.hasSolarPanels)
            options.push(amenityOptions.find(o => o.value === AmenityType.SolarPanels)!);
        if (form.hasSolarCollector)
            options.push(amenityOptions.find(o => o.value === AmenityType.SolarCollector)!);
        if (form.hasAlarm)
            options.push(amenityOptions.find(o => o.value === AmenityType.Alarm)!);
        if (form.hasVideoPhone)
            options.push(amenityOptions.find(o => o.value === AmenityType.VideoPhone)!);
        if (form.hasSwimmingPool) {
            options.push(amenityOptions.find(o => o.value === AmenityType.SwimmingPool)!);
            setHasSwimmingPool(true);
        }
        if (form.hasLandscapedGarden) {
            options.push(amenityOptions.find(o => o.value === AmenityType.LandscapedGarden)!);
            setHasLandscapedGarden(true);
        }
        setSelectedAmenityOptions(options);
    }, [])

    useEffect(() => {
        const updatedForm = updateForm(true);

        const isScopedValid = step6IsValid(updatedForm, configuration) && (configuration.displayAmenitiesImagesUpload === VariableDisplay.Required ? checkIfAllRequiredImagesAreUploaded() : true);
        if (isScopedValid) {
            onChange(updatedForm);
        }
        setIsValid(isScopedValid);
    }, [selectedAmenityOptions, hasLandscapedGarden, hasGardenOffice, hasPoolHouse, poolHouseSize, gardenOfficeSize, selectedBuiltInFurnitureTypeOptions, swimmingPoolImages, gardenOfficeImages, poolHouseImages, landscapedGardenImages]);

    useEffect(() => {
        const reqImages: AttachmentType[] = [];
        if (selectedAmenityOptions.findIndex(option => option.value === AmenityType.SwimmingPool.toString()) !== -1) {
            reqImages.push(AttachmentType.SwimmingPool);
        }
        if (selectedAmenityOptions.findIndex(option => option.value === AmenityType.LandscapedGarden.toString()) !== -1) {
            reqImages.push(AttachmentType.LandscapedGarden);
        }
        if (hasPoolHouse) {
            reqImages.push(AttachmentType.PoolHouse);
        }
        if (hasGardenOffice) {
            reqImages.push(AttachmentType.GardenOffice);
        }
        setRequiredAmenityImages(reqImages);
    }, [selectedAmenityOptions, hasGardenOffice, hasPoolHouse]);

    useEffect(() => {
        if (requiredAmenityImages.length > 0 && checkIfAllRequiredImagesAreUploaded()) {
            setErrors((prevState) => ({
                ...prevState,
                requiredImages: undefined,
            }));
        }
    }, [requiredAmenityImages, swimmingPoolImages, poolHouseImages, landscapedGardenImages, gardenOfficeImages]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (selectedBuiltInFurnitureTypeOptions.length === 1) {
            updatedForm.builtInFurnitureType = BuiltInFurnitureType[selectedBuiltInFurnitureTypeOptions[0].key as keyof typeof BuiltInFurnitureType];
        } else if (skipValidation && selectedBuiltInFurnitureTypeOptions.length === 0) {
            updatedForm.builtInFurnitureType = undefined;
        }
        updatedForm.hasElevator = selectedAmenityOptions.findIndex(option => option.value === AmenityType.Elevator.toString()) !== -1;
        updatedForm.hasSauna = selectedAmenityOptions.findIndex(option => option.value === AmenityType.Sauna.toString()) !== -1;
        updatedForm.hasHomeAutomation = selectedAmenityOptions.findIndex(option => option.value === AmenityType.HomeAutomation.toString()) !== -1;
        updatedForm.hasSolarPanels = selectedAmenityOptions.findIndex(option => option.value === AmenityType.SolarPanels.toString()) !== -1;
        updatedForm.hasSolarCollector = selectedAmenityOptions.findIndex(option => option.value === AmenityType.SolarCollector.toString()) !== -1;
        updatedForm.hasAlarm = selectedAmenityOptions.findIndex(option => option.value === AmenityType.Alarm.toString()) !== -1;
        updatedForm.hasVideoPhone = selectedAmenityOptions.findIndex(option => option.value === AmenityType.VideoPhone.toString()) !== -1;
        updatedForm.hasLandscapedGarden = hasLandscapedGarden;
        updatedForm.hasSwimmingPool = hasSwimmingPool;
        updatedForm.hasPoolHouse = hasPoolHouse;
        updatedForm.hasGardenOffice = hasGardenOffice;
        if (skipValidation || !isNullOrUndefined(poolHouseSize)) {
            updatedForm.poolHouseSize = poolHouseSize ? (typeof poolHouseSize === 'string' ? Math.round(parseFloat(poolHouseSize)) : Math.round(poolHouseSize)) : undefined;
        }
        if (skipValidation || !isNullOrUndefined(gardenOfficeSize)) {
            updatedForm.gardenOfficeSize = (typeof gardenOfficeSize === 'string' ? Math.round(parseFloat(gardenOfficeSize)) : Math.round(gardenOfficeSize!));
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (hasPoolHouse && !poolHouseSize) {
            setErrors((prevState) => ({
                ...prevState,
                poolHouseSize: 'required',
            }));
        }
        if (hasGardenOffice && !gardenOfficeSize) {
            setErrors((prevState) => ({
                ...prevState,
                gardenOfficeSize: 'required',
            }));
        }
        if (configuration.displayAmenitiesImagesUpload === VariableDisplay.Required && requiredAmenityImages && !checkIfAllRequiredImagesAreUploaded()) {
            setErrors((prevState) => ({
                ...prevState,
                // t('images_required_error')
                requiredImages: 'images_required_error',
            }));
        }
        if (configuration.displayBuiltInFurniture === VariableDisplay.Required && !selectedBuiltInFurnitureTypeOptions || selectedBuiltInFurnitureTypeOptions.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                // t('builtin_furniture_required_error')
                builtInFurniture: 'builtin_furniture_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        if ((configuration.displayBuiltInFurniture === VariableDisplay.Required ? isNullOrUndefined(form.builtInFurnitureType) : false)
            || (form.hasPoolHouse && isNullOrUndefined(form.poolHouseSize))
            || (form.hasGardenOffice && isNullOrUndefined(form.gardenOfficeSize))) {
            return false;
        }
        return true;
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onAmenitiesChanged = (options: SelectionOption[]) => {
        setSelectedAmenityOptions(options);
        let hasSwimmingPoolOption = false;
        let hasLandscapedGardenOption = false;
        options.forEach(option => {
            if (option.value === AmenityType.SwimmingPool) {
                hasSwimmingPoolOption = true;
            } else if (option.value === AmenityType.LandscapedGarden) {
                hasLandscapedGardenOption = true;
            }
        });
        setHasSwimmingPool(hasSwimmingPoolOption);
        setHasLandscapedGarden(hasLandscapedGardenOption);
    };

    const onHasPoolHouseChanged = () => {
        const updatedValue = !hasPoolHouse;
        setHasPoolHouse(updatedValue);
        if (!updatedValue) {
            setPoolHouseSize('');
            setErrors((prevState) => ({
                ...prevState,
                poolHouseSize: undefined,
            }));
        }
    }

    const onHasGardenOfficeChanged = () => {
        const updatedValue = !hasGardenOffice;
        setHasGardenOffice(updatedValue);
        if (!updatedValue) {
            setGardenOfficeSize('');
            setErrors((prevState) => ({
                ...prevState,
                gardenOfficeSize: undefined,
            }));
        }
    }

    const onPoolHouseSizeChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setPoolHouseSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                poolHouseSize: undefined,
            }));
        } else {
            setPoolHouseSize(undefined);
        }
    };

    const onGardenOfficeSizeChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        if (!isNaN(parseFloat(updatedValue))) {
            setGardenOfficeSize(updatedValue);
            setErrors((prevState) => ({
                ...prevState,
                gardenOfficeSize: undefined,
            }));
        } else {
            setGardenOfficeSize(undefined);
        }
    };

    const onBuiltInFurnitureTypeChanged = (options: SelectionOption[]) => {
        setSelectedBuiltInFurnitureTypeOptions(options);
        if (options.length > 0) {
            setErrors((prevState) => ({
                ...prevState,
                builtInFurniture: undefined,
            }));
        }
    };

    const checkIfAllRequiredImagesAreUploaded = () => {
        if (RuntimeConfig.getEnvironment() !== 'local') {
            return (hasSwimmingPool ? swimmingPoolImages > 0 : true)
                && (hasGardenOffice ? gardenOfficeImages > 0 : true)
                && (hasLandscapedGarden ? landscapedGardenImages > 0 : true)
                && (hasPoolHouse ? poolHouseImages > 0 : true);
        } else {
            return true;
        }
    }

    const numberOfFilesChanged = (type: AttachmentType, value: number) => {
        switch (type) {
            case AttachmentType.GardenOffice:
                setGardenOfficeImages(value);
                break;
            case AttachmentType.SwimmingPool:
                setSwimmingPoolImages(value);
                break;
            case AttachmentType.PoolHouse:
                setPoolHouseImages(value);
                break;
            case AttachmentType.LandscapedGarden:
                setLandscapedGardenImages(value);
                break;
        }
    }

    return (
        <>
            <StepHeaderComponent
                step={6}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <LabeledMultiSelectComponent
                        label={t('Built-in furniture')}
                        display={configuration.displayBuiltInFurniture}
                        options={builtInFurnitureOptions}
                        selectedOptions={selectedBuiltInFurnitureTypeOptions}
                        valueChanged={onBuiltInFurnitureTypeChanged}
                        allowMultiple={false}
                        errors={errors.builtInFurniture}
                        footnote={t('Incl. coat check, wardrobe, living room cupboard, ... excl. kitchen and bathroom')}
                    />
                    <div className={styles.formField}>
                        <label className={styles.label}>
                            {t('Select available amenities')}
                            <div className={styles.labelInfo}>{t('Optional, Multiple')}</div>
                        </label>
                        <MultiSelectComponent allowMultiSelect={true}
                                              options={amenityOptions}
                                              onSelectionsChanged={(options: SelectionOption[]) => onAmenitiesChanged(options)}
                                              selection={selectedAmenityOptions}/>
                        <div className={styles.amenityOptionWithInput}>
                            <div className={conditionalClassLister(styles)({
                                amenityOption: true,
                                selected: hasPoolHouse,
                            })} onClick={() => onHasPoolHouseChanged()}>
                                <div className={styles.checkBoxPanel}>
                                    <CheckboxComponent className={styles.checkIcon}/>
                                </div>
                                {t(AmenityType.PoolHouse)}
                            </div>
                            <div className={styles.amenityInput}>
                                <NumberFormat
                                    className={conditionalClassLister(styles)({
                                        input: true,
                                        withUnit: true,
                                        invalidInput: errors.poolHouseSize
                                    })}
                                    disabled={!hasPoolHouse}
                                    type="text"
                                    value={poolHouseSize}
                                    pattern="[0-9]*"
                                    min="0"
                                    thousandSeparator="&thinsp;"
                                    onChange={(event) => onPoolHouseSizeChanged(event.target.value)}
                                />
                                <span className={styles.unit}>m&#178;</span>
                            </div>
                        </div>
                        <div className={styles.amenityOptionWithInput}>
                            <div className={conditionalClassLister(styles)({
                                amenityOption: true,
                                selected: hasGardenOffice,
                            })} onClick={() => onHasGardenOfficeChanged()}>
                                <div className={styles.checkBoxPanel}>
                                    <CheckboxComponent className={styles.checkIcon}/>
                                </div>
                                {t(AmenityType.GardenOffice)}
                            </div>
                            <div className={styles.amenityInput}>
                                <NumberFormat
                                    className={conditionalClassLister(styles)({
                                        input: true,
                                        withUnit: true,
                                        invalidInput: errors.gardenOfficeSize,
                                    })}
                                    disabled={!hasGardenOffice}
                                    type="text"
                                    value={gardenOfficeSize}
                                    pattern="[0-9]*"
                                    min="0"
                                    thousandSeparator="&thinsp;"
                                    onChange={(event) => onGardenOfficeSizeChanged(event.target.value)}
                                />
                                <span className={styles.unit}>m&#178;</span>
                            </div>
                        </div>
                    </div>
                    {requiredAmenityImages && requiredAmenityImages.length > 0 &&
                    <FileHandlerComponent attachmentTypes={requiredAmenityImages}
                                          invalid={!!errors.requiredImages}
                                          onNumberOfFilesChanged={numberOfFilesChanged}
                                          display={configuration.displayAmenitiesImagesUpload}
                                          label={t('Upload images')}
                                          errorMessage={errors.requiredImages}
                                          footNote={`${t('required_amenities')} ${requiredAmenityImages.map(amenity => t(amenity)).join(', ')}`}/>}
                </div>
                <RequiredErrorsBoxComponent
                    errors={errors.poolHouseSize || errors.gardenOfficeSize || errors.outdoorParkingSpacesAmount || errors.builtInFurniture || errors.requiredImages}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step6Component;

interface Step6Errors {
    poolHouseSize?: string;
    gardenOfficeSize?: string;
    outdoorParkingSpacesAmount?: string;
    builtInFurniture?: string;
    requiredImages?: string;
}

// t('Elevator')
// t('SwimmingPool')
// t('Sauna')
// t('PoolHouse')
// t('HomeAutomation')
// t('SolarPanels')
// t('SolarCollector')
// t('LandscapedGarden')
// t('GardenOffice')
// t('Alarm')
// t('VideoPhone')
enum AmenityType {
    Elevator = 'Elevator',
    SwimmingPool = 'SwimmingPool',
    Sauna = 'Sauna',
    PoolHouse = 'PoolHouse',
    HomeAutomation = 'HomeAutomation',
    SolarPanels = 'SolarPanels',
    SolarCollector = 'SolarCollector',
    LandscapedGarden = 'LandscapedGarden',
    GardenOffice = 'GardenOffice',
    Alarm = 'Alarm',
    VideoPhone = 'VideoPhone',
}

export const step6IsValid = (form: WebForm, configuration: WebformConfiguration): boolean => {
    return (form.hasGardenOffice ? isValidInput(form.gardenOfficeSize,) : true)
        && (form.hasPoolHouse ? isValidInput(form.poolHouseSize) : true)
        && (configuration.displayBuiltInFurniture === VariableDisplay.Required ? !isNullOrUndefined(form.builtInFurnitureType) : true);
}
