import {EpcValues} from '../app/types/responses/epc-values';

export class RuntimeConfig {
    public static getEnvironment(): string {
        return this.getRuntimeConfig('environment');
    }

    public static getApiURL(): string {
        return this.getRuntimeConfig('apiUrl');
    }

    public static getEPCValuesVLA(): EpcValues {
        return this.getEPCValues()['VLA'];
    }

    public static getEPCValuesWAL(): EpcValues {
        return this.getEPCValues()['WAL'];
    }

    public static getEPCValuesBRU(): EpcValues {
        return this.getEPCValues()['BRU'];
    }

    public static getEPCValues(): any {
        return this.getRuntimeConfig('epcValues');
    }

    private static getRuntimeConfig(configProp: string): any {
        // @ts-ignore
        return window.runConfig[configProp];
    }

}
