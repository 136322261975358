import React, {FunctionComponent} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import WebFormPage from './pages/web-form/web-form.page';
import NotFoundPage from './pages/not-found/not-found.page';
import WebformInvalidPage from './pages/webform-invalid/webform-invalid.page';
import LandingPage from './pages/landing/landing.page';

export enum RouteUrl {
    Root = '/',
    WebForm = '/web-form/:bankId/:invitationToken',
    WebFormInvalid = '/web-form/invalid',
    NotFound = '/404'
}

const Routes: FunctionComponent = () => {

    return (
        <Router>
            <Switch>
                <Route exact path={RouteUrl.Root} component={LandingPage}/>
                <Route path={RouteUrl.WebForm} component={WebFormPage}/>
                <Route path={RouteUrl.WebFormInvalid} component={WebformInvalidPage}/>
                <Route path={RouteUrl.NotFound} component={NotFoundPage}/>
                <Route path={RouteUrl.Root} component={NotFoundPage}/>
            </Switch>
        </Router>

    );
}

export default Routes;
