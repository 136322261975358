import { WebForm } from '../webform/types';

export interface Payment {
  id: string;
  webform: WebForm;
  status: PaymentStatus;
  transactions: Transaction[];
  invoiceDetails?: InvoiceDetails;
}

export enum PaymentStatus {
  Open = 'open',
  Paid = 'paid',
}

export interface Transaction {
  id: string;
  payment: Payment;
  mollieId: string;
  amountVatExclusive: number;
  amountVatInclusive: number;
  status: TransactionStatus;
  createdOn: Date;
}

// these match the PaymentStatus from Mollie
export enum TransactionStatus {
  open = 'open',
  canceled = 'canceled',
  pending = 'pending',
  authorized = 'authorized',
  expired = 'expired',
  failed = 'failed',
  paid = 'paid',
}

export interface InvoiceDetails {
  id: string;
  name: string;
  street: string;
  city: string;
  country: string;
  email: string;
  company?: string;
  vatNumber?: string;
}
