// eslint-disable-next-line
export const isNullOrUndefined = (value: any): boolean => {
    return value === null || value === undefined;
}

// eslint-disable-next-line
export const isEmpty = (value: any): boolean => {
    if (typeof value === 'string') {
        return value === '';
    } else {
        return value === null
            || value === undefined
    }
}

export const isValidInput = (value?: number | string, minValue = 0, allowEmpty = false): boolean => {
    if (!allowEmpty) {
        if (isEmpty(value)) {
            return false;
        } else if (typeof value === 'string' && parseInt(value) < minValue) {
            return false;
        } else if (typeof value === 'number' && (isNaN(value) || value < minValue)) {
            return false;
        } else {
            return true;
        }
    } else {
        if (typeof value === 'string' && parseInt(value) < minValue) {
            return false;
        } else if (typeof value === 'number' && value < minValue) {
            return false;
        } else {
            return true;
        }
    }
}


