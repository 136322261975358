import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {TFunction, useTranslation} from 'react-i18next';
import MultiSelectComponent from '../../../components/multi-select/multi-select.component';
import {SelectionOption, SelectionOptionCategory} from '../../../components/dropdown/dropdown.component';
import {PropertyType, PropertyTypeCategory, WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import {conditionalClassLister} from '../../../utils/class-helpers';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import LoaderComponent from '../../../components/loader/loader';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step2Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const [propertyType, setPropertyType] = useState<PropertyType | undefined>(form.propertyType);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errors, setErrors] = useState<Step2Errors>({});
    const {t} = useTranslation();
    const propertyOptions = getPropertiesWithCategories(t);
    const propertyCategories: SelectionOptionCategory[] = getPropertyCategories(t);
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step2IsValid(updatedForm);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
        setIsValid(scopedIsValid);
    }, [propertyType]);

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || propertyType) {
            updatedForm.propertyType = propertyType;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (!propertyType) {
            setErrors((prevState) => ({
                ...prevState,
                // t('property_type_required_error')
                propertyTypeRequired: 'property_type_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !!form.propertyType;
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onPropertyTypeChanged = (options: SelectionOption[]) => {
        if (options.length === 1) {
            setPropertyType(options[0].value! as PropertyType);
            setErrors((prevState) => ({
                ...prevState,
                propertyTypeRequired: undefined,
            }));
        } else {
            setPropertyType(undefined);
        }
    };

    return (
        <>
            <StepHeaderComponent
                step={2}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <div className={styles.formField}>
                        <label className={conditionalClassLister(styles)({
                            label: true,
                            withError: errors.propertyTypeRequired,
                        })}>
                            {t('Select property type')}
                        </label>
                        {errors.propertyTypeRequired ?
                            <div className={styles.labelError}>{t(errors.propertyTypeRequired)}</div> : null}
                        <MultiSelectComponent allowMultiSelect={false}
                                              categories={propertyCategories}
                                              options={propertyOptions}
                                              onSelectionsChanged={(options: SelectionOption[]) => onPropertyTypeChanged(options)}
                                              selection={propertyOptions.find(o => o.value === propertyType) ? [propertyOptions.find(o => o.value === propertyType)!] : []}/>
                    </div>
                </div>
                <RequiredErrorsBoxComponent errors={errors.propertyTypeRequired}/>
                {inProgress && <div className={styles.infoPanel}>
                    {t('We are searching for available data on the asset. This may take a few seconds.')}
                </div>}
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step2Component;

interface Step2Errors {
    propertyTypeRequired?: string;
}

export const step2IsValid = (form: WebForm): boolean => {
    return !!form.propertyType;
}

const getPropertiesWithCategories = (t: TFunction): SelectionOption[] => {
    const options: SelectionOption[] = [];
    Object.entries(PropertyType).forEach(([key, value]) => {
        const category = getCategory(value, t);

        options.push({
            value,
            label: t(key),
            key,
            category,
        });
    });
    return options;
}

const getCategory = (item: string, t: TFunction): SelectionOptionCategory => {
    const propertyCategories = getPropertyCategories(t);
    switch (item) {
        case PropertyType.Apartment:
        case PropertyType.Studio:
        case PropertyType.Duplex:
        case PropertyType.Triplex:
        case PropertyType.Penthouse:
        case PropertyType.StudentRoom:
        case PropertyType.ServiceFlat:
            return propertyCategories.find(cat => cat.key === PropertyTypeCategory.PartOfBuilding)!;
        case PropertyType.Townhouse:
        case PropertyType.SemiDetachedHouse:
        case PropertyType.DetachedHouse:
        case PropertyType.Chalet:
        case PropertyType.Farm:
        case PropertyType.Castle:
            return propertyCategories.find(cat => cat.key === PropertyTypeCategory.Building)!;
        case PropertyType.BuildingParcel:
            return propertyCategories.find(cat => cat.key === PropertyTypeCategory.BuildingParcel)!;
        default:
            throw new Error(`No Category fount for Property type: ${item}`);
    }
}

const getPropertyCategories = (t: TFunction): SelectionOptionCategory[] => {
    return [
        {key: PropertyTypeCategory.PartOfBuilding, index: 0, label: t(PropertyTypeCategory.PartOfBuilding)},
        {key: PropertyTypeCategory.Building, index: 1, label: t(PropertyTypeCategory.Building)},
        {key: PropertyTypeCategory.BuildingParcel, index: 2, label: t(PropertyTypeCategory.BuildingParcel)}
    ];
}
