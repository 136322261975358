import React, {FunctionComponent} from 'react';
import styles from './input.module.scss';
import {useTranslation} from 'react-i18next';
import {VariableDisplay} from '../../../../types/variable-display';
import {conditionalClassLister} from '../../../../utils/class-helpers';

const LabeledDateInputComponent: FunctionComponent<OwnProps> = ({id, label, footnote, display, errors, value, valueChanged, valueBlurred}: OwnProps) => {
    const {t} = useTranslation();

    const displayProperty = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    const isOptional = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Optional;
    }

    const onValueChange = (value: string) => {
        if (valueChanged) {
            valueChanged(value);
        }
    }

    const onValueBlur = (value: string) => {
        if (valueBlurred) {
            valueBlurred(value);
        }
    }

    return displayProperty(display) ? (
        <div className={styles.formField}>
            <label className={conditionalClassLister(styles)({
                label: true,
                withError: !!errors,
            })} htmlFor={id}>
                {label}
                {isOptional(display) &&
                <span>
                    <small>{t('Optional')}</small>
                </span>}
            </label>
            {errors && <div className={styles.labelError}>{t(errors)}</div>}
            <div className={styles.inputBox}>
                <input
                    className={conditionalClassLister(styles)({
                        input: true,
                        invalidInput: errors
                    })}
                    type="date"
                    value={value}
                    onChange={(event: any) => onValueChange(event.target.value)}
                    onBlur={(event: any) => onValueBlur(event.target.value)}
                />
            </div>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}
export default LabeledDateInputComponent;

type OwnProps = {
    id: string;
    label: string;
    display: VariableDisplay;
    errors?: string;
    value?: string | number;
    footnote?: string;
    valueChanged: (value: string) => void;
    valueBlurred?: (value: string) => void;
}
