import React, {FunctionComponent} from 'react';
import styles from './checkbox.module.scss'

const CheckboxComponent: FunctionComponent<OwnProps> = ({onSelectionChanged, disabled, value}: OwnProps) => {

    return <label className={styles.switch}>
        <input type={'checkbox'} disabled={disabled} checked={value}
               onChange={(e) => onSelectionChanged(e.target.checked)}/>
        <span className={styles.slider}></span>
    </label>
}
export default CheckboxComponent;

interface OwnProps {
    value: boolean;
    onSelectionChanged: (value: boolean) => void;
    disabled?: boolean;
}
