import React, {FunctionComponent} from 'react';
import styles from './progress-bar.module.scss';

const ProgressBarComponent: FunctionComponent<OwnProps> = ({percentage}: OwnProps) => {

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
            <div className={styles.filler} style={{width: `${percentage}%`}}>
            </div>
            </div>
        </div>
    );
}

interface OwnProps {
    percentage: number;
}

export default ProgressBarComponent;
