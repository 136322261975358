import React, {FunctionComponent} from 'react';
import styles from './input.module.scss';
import {useTranslation} from 'react-i18next';
import {VariableDisplay} from '../../../../types/variable-display';
import DropdownComponent, {SelectionOption} from '../../../../components/dropdown/dropdown.component';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import ReactTooltip from 'react-tooltip';
import {ReactComponent as Info} from '../../../../../assets/images/info.svg';

const LabeledDropdownComponent: FunctionComponent<OwnProps> = ({
                                                                   id,
                                                                   label,
                                                                   display,
                                                                   errors,
                                                                   options,
                                                                   value,
                                                                   valueChanged,
                                                                   footnote,
                                                                   toolTipMessages,
                                                                   addRanking = false,
                                                               }: OwnProps) => {
    const {t} = useTranslation();

    const displayVariable = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    const isOptional = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Optional;
    }

    return displayVariable(display) ? (<div className={styles.formField}>
            {toolTipMessages && toolTipMessages.length > 0 &&
            <ReactTooltip id={`${id}ToolTip`} place="top" effect="solid">
                <div className={styles.tooltip}>
                    {toolTipMessages.map(((message, index) => <div key={`${index}${id}`}>{message}</div>))}
                </div>
            </ReactTooltip>}
            <label className={conditionalClassLister(styles)({
                label: true,
                withError: errors,
            })} htmlFor={id}>
                <div>
                    {label}
                    {toolTipMessages && toolTipMessages.length > 0 &&
                    <Info className={styles.infoIcon} data-tip data-for={`${id}ToolTip`}/>}
                </div>
                {isOptional(display) &&
                <span>
                    <small>{t('Optional')}</small>
                </span>}
            </label>
            {errors && <div className={styles.labelError}>{t(errors)}</div>}
            <DropdownComponent invalid={!!errors}
                               options={options}
                               onSelectionChanged={valueChanged}
                               selectedOptionValue={value}
                               addRanking={addRanking}/>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}
export default LabeledDropdownComponent;

type OwnProps = {
    id: string;
    label: string;
    display: VariableDisplay;
    errors?: string;
    options: SelectionOption[]
    value?: string | number;
    valueChanged: (value: string) => void;
    footnote?: string;
    addRanking?: boolean;
    toolTipMessages?: string[];
}
