import React, {FunctionComponent} from 'react';
import styles from './input.module.scss';
import {useTranslation} from 'react-i18next';
import {VariableDisplay} from '../../../../types/variable-display';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import NumberSelectComponent from '../../../../components/number-select/number-select.component';

const LabeledNumberSelectComponent: FunctionComponent<OwnProps> = ({id, label, footnote, display, errors, value, valueChanged, min = 0}: OwnProps) => {
    const {t} = useTranslation();

    const displayVariable = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    const isOptional = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Optional;
    }

    const onValueChange = (value: number) => {
        if (valueChanged) {
            valueChanged(value);
        }
    }

    return displayVariable(display) ? (
        <div className={styles.formField}>
            <label className={conditionalClassLister(styles)({
                label: true,
                withError: !!errors,
            })} htmlFor={id}>
                {label}
                {isOptional(display) &&
                <span>
                    <small>{t('Optional')}</small>
                </span>}
            </label>
            {errors && <div className={styles.labelError}>{t(errors)}</div>}
            <NumberSelectComponent min={min}
                                   defaultValue={value}
                                   onValueChange={(value: number) => onValueChange(value)}
                                   invalid={!!errors}/>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}
export default LabeledNumberSelectComponent;

type OwnProps = {
    id: string;
    label: string;
    display: VariableDisplay;
    errors?: string;
    value?: number;
    footnote?: string;
    min?: number;
    valueChanged: (value: number) => void;
}
