import {Renovation, RenovationType} from '../../../../store/webform/types';
import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './renovation.module.scss';
import NumberFormat from 'react-number-format';
import DropdownComponent from '../../../../components/dropdown/dropdown.component';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Delete} from '../../../../../assets/images/trash-can.svg';
import {enumToSelectionOptions} from '../../../../components/dropdown/selection-option.util';

interface OwnProps {
    renovation: Renovation;
    onRenovationUpdated: (renovation: Renovation) => void
    onRenovationDeleted: () => void
    showErrors: boolean
}

const RenovationComponent: FunctionComponent<OwnProps> = ({
                                                              renovation,
                                                              onRenovationUpdated,
                                                              onRenovationDeleted,
                                                              showErrors,
                                                          }: OwnProps) => {
    const {t} = useTranslation();
    const [type, setType] = useState<RenovationType | undefined>(renovation.type);
    const [year, setYear] = useState<number | undefined>(renovation.year);
    const [amount, setAmount] = useState<number | undefined>(renovation.amount);
    const [isValid, setIsValid] = useState<boolean>(!!renovation.type)
    const renovationTypeOptions = enumToSelectionOptions(RenovationType, t);

    useEffect(() => {
        renovation.type = type;
        renovation.year = year;
        renovation.amount = amount;
        onRenovationUpdated(renovation);
    }, [type, year, amount])

    const onRenovationTypeChanged = (optionValue: string) => {
        setType(optionValue as RenovationType);
        setIsValid(true);
    }

    const onRenovationAmountChanged = (value: string) => {
        const updatedValue = value.replace(/\s/g, '');
        const amount = !isNaN(parseInt(updatedValue)) ? parseInt(updatedValue) : undefined;
        setAmount(amount);
    }

    const onRenovationYearChanged = (value: string) => {
        const year = !isNaN(parseInt(value)) ? parseInt(value) : undefined;
        setYear(year)
    };

    return (
        <div className={styles.renovation}>
            {showErrors && !isValid && <div className={styles.labelError}>{t('Renovation type is required')}</div>}
            <div className={styles.inputs}>
                <div className={styles.type}>
                    <DropdownComponent
                        options={renovationTypeOptions}
                        onSelectionChanged={(value) => onRenovationTypeChanged(value)}
                        selectedOptionValue={type}
                        invalid={showErrors && !isValid}
                    />
                </div>
                <div className={styles.year}>
                    <NumberFormat
                        placeholder={t('Year')}
                        className={styles.input}
                        type="text"
                        pattern="[0-9]*"
                        maxLength={4}
                        value={year}
                        onChange={(event) => onRenovationYearChanged(event.target.value)}
                    />
                </div>
                <div className={styles.amount}>
                    <NumberFormat
                        placeholder={t('Amount')}
                        className={styles.input}
                        type="text"
                        pattern="[0-9]*"
                        thousandSeparator="&thinsp;"
                        value={amount}
                        onChange={(event) => onRenovationAmountChanged(event.target.value)}
                    />
                    <span className={styles.unit}>EUR</span>
                </div>
                <Delete className={styles.deleteIcon} onClick={() => onRenovationDeleted()}/>
            </div>
        </div>
    )
}

export default RenovationComponent;
