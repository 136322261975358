import {AppAction} from '../types';
import {BankActionTypes} from './actions';
import {Bank, WebformConfiguration} from './types';
import {VariableDisplay} from '../../types/variable-display';

export type BankState = {
    bank?: Bank;
    configuration: WebformConfiguration
};

export const initialBankState: BankState = {
    bank: undefined,
    configuration: {
        id: 'default',
        displayBalconyAndTerraceSize: VariableDisplay.Required,
        displayPartialRenovation: VariableDisplay.Optional,
        displayOverallRenovationWithPermit: VariableDisplay.Optional,
        displayLastValuationPrice: VariableDisplay.Optional,
        displayLastValuationDate: VariableDisplay.Optional,
        displayPlansUpload: VariableDisplay.Optional,
        displayEpcReportUpload: VariableDisplay.Optional,
        displayAmenitiesImagesUpload: VariableDisplay.Required,
        displayBuildingImagesUpload: VariableDisplay.Required,
        displayBuildingPermitUpload: VariableDisplay.Optional,
        displayValuationReportUpload: VariableDisplay.Optional,
        displayBuiltInFurniture: VariableDisplay.Required,
        displayTransactionDate: VariableDisplay.Optional,
        displayTransactionPrice: VariableDisplay.Optional,
        displayStorageBasementSize:  VariableDisplay.Required,
        displayStorageAtticSize:  VariableDisplay.Required,
        displayPrivateGardenSize:  VariableDisplay.Optional,
        displayCommunalGardenSize:  VariableDisplay.Optional,
        displayEpc: VariableDisplay.Optional,
    },
};

export const bankReducer = (state: BankState, action: AppAction): BankState => {
    switch (action.type) {
        case BankActionTypes.SetBank:
            return {
                bank: action.bank,
                configuration: action.bank.webformConfiguration,
            };
        default:
            return state;
    }
};
