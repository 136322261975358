import {WebForm} from './types';
import {AppAction} from '../types';
import {WebFormActionTypes} from './actions';

export type WebFormState = {
    webForm?: WebForm;
    isDirty: boolean;
};

export const initialWebFormState: WebFormState = {
    isDirty: false,
};

export const webFormReducer = (state: WebFormState, action: AppAction): WebFormState => {
    switch (action.type) {
        case WebFormActionTypes.SetWebForm:
            return { ...state, webForm: action.webForm };
        case WebFormActionTypes.SetIsDirty:
            return { ...state, isDirty: action.isDirty };
        default:
            return state;
    }
};
