import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './steps.module.scss';
import {StepProps} from './step-props';
import {useTranslation} from 'react-i18next';
import {WebForm} from '../../../store/webform/types';
import {ReactComponent as ChevronRight} from '../../../../assets/images/chevron_right.svg';
import RequiredErrorsBoxComponent from '../components/error-box/required-errors-box.component';
import {VariableDisplay} from '../../../types/variable-display';
import LabeledNumberSelectComponent from '../components/input/labeled-number-select.component';
import {isNullOrUndefined, isValidInput} from '../../../utils/validation.util';
import LoaderComponent from '../../../components/loader/loader';
import {usePreviousAndNext} from '../../../hooks/usePreviousAndNext';
import {getMaxActiveStep} from '../web-form.page';
import StepHeaderComponent from '../components/step-header/step-header.component';

const Step4Component: FunctionComponent<StepProps> = ({
                                                          form,
                                                          configuration,
                                                          totalSteps,
                                                          onNextClick,
                                                          onPreviousClick,
                                                          onChange,
                                                          updateInProgress
                                                      }: StepProps) => {
    const {t} = useTranslation();
    const [bedrooms, setBedrooms] = useState<number | undefined>(form.bedroomCount);
    const [bathrooms, setBathrooms] = useState<number | undefined>(form.bathroomCount);
    const [toilets, setToilets] = useState<number | undefined>(form.toiletCount);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errors, setErrors] = useState<Step4Errors>({});
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        setInProgress(updateInProgress);
    }, [updateInProgress]);

    useEffect(() => {
        const updatedForm = updateForm(true);
        const scopedIsValid = step4IsValid(updatedForm);
        if (scopedIsValid) {
            onChange(updatedForm);
        }
        setIsValid(scopedIsValid);
    }, [bedrooms, bathrooms, toilets])

    const updateForm = (skipValidation: boolean): WebForm => {
        const updatedForm = Object.assign({}, form);
        if (skipValidation || !isNullOrUndefined(bedrooms)) {
            updatedForm.bedroomCount = bedrooms;
        }
        if (skipValidation || !isNullOrUndefined(bathrooms)) {
            updatedForm.bathroomCount = bathrooms;
        }
        if (skipValidation || !isNullOrUndefined(toilets)) {
            updatedForm.toiletCount = toilets;
        }

        return updatedForm;
    }

    const validateFields = () => {
        if (isNullOrUndefined(bedrooms)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_bedrooms_required_error')
                bedroomCountRequired: 'number_of_bedrooms_required_error',
            }));
        }
        if (isNullOrUndefined(bathrooms)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_bathrooms_required_error')
                bathroomCountRequired: 'number_of_bathrooms_required_error',
            }));
        }
        if (isNullOrUndefined(toilets)) {
            setErrors((prevState) => ({
                ...prevState,
                // t('number_of_toilets_required_error')
                toiletCountRequired: 'number_of_toilets_required_error',
            }));
        }
    }

    const checkIfStepWasCompleted = (): boolean => {
        return !isNullOrUndefined(form.bedroomCount)
            && !isNullOrUndefined(form.bathroomCount)
            && !isNullOrUndefined(form.toiletCount);
    }

    const {next, back} = usePreviousAndNext(
        isValid,
        onNextClick,
        onPreviousClick,
        validateFields,
        checkIfStepWasCompleted,
        updateForm,
        onChange
    );

    const onBedroomsChanged = (value: number) => {
        setBedrooms(value);
        setErrors((prevState) => ({
            ...prevState,
            bedroomCountRequired: undefined,
        }));
    };

    const onBathroomsChanged = (value: number) => {
        setBathrooms(value);
        setErrors((prevState) => ({
            ...prevState,
            bathroomCountRequired: undefined,
        }));
    };

    const onToiletsChanged = (value: number) => {
        setToilets(value);
        setErrors((prevState) => ({
            ...prevState,
            toiletCountRequired: undefined,
        }));
    };

    return (
        <>
            <StepHeaderComponent
                step={4}
                totalSteps={totalSteps}
                maxActiveStep={getMaxActiveStep(form, configuration)}
                onNextClick={() => next()}
                onPreviousClick={() => back()}
            />
            <div className={styles.content}>
                <div className={styles.form}>
                    <LabeledNumberSelectComponent
                        id='bedrooms'
                        label={t('Select number of bedrooms')}
                        footnote={t('A bed room always has a window')}
                        display={VariableDisplay.Required}
                        value={bedrooms}
                        valueChanged={onBedroomsChanged}
                        errors={errors.bedroomCountRequired}/>
                    <LabeledNumberSelectComponent
                        id='bathrooms'
                        label={t('Select number of bathrooms')}
                        display={VariableDisplay.Required}
                        value={bathrooms}
                        valueChanged={onBathroomsChanged}
                        errors={errors.bathroomCountRequired}/>
                    <LabeledNumberSelectComponent
                        id='toilets'
                        label={t('Select number of toilets')}
                        footnote={t('Incl. toilets in bathrooms, etc')}
                        display={VariableDisplay.Required}
                        value={toilets}
                        valueChanged={onToiletsChanged}
                        errors={errors.toiletCountRequired}/>
                </div>
                <RequiredErrorsBoxComponent
                    errors={errors.bedroomCountRequired || errors.bathroomCountRequired || errors.toiletCountRequired}/>
                <div className={styles.buttons}>
                    <div className={`${styles.button} ${styles.negative}`} onClick={() => back()}>
                        <ChevronRight className={`${styles.icon} ${styles.rotate180}`}/>
                        {t('Back')}
                    </div>
                    <div className={styles.button} onClick={() => next()}>
                        {!inProgress ? <>{t('Continue')} <ChevronRight className={styles.icon}/></> :
                            <LoaderComponent color='white'/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step4Component;

interface Step4Errors {
    bedroomCountRequired?: string;
    bathroomCountRequired?: string;
    toiletCountRequired?: string;
}

export const step4IsValid = (form: WebForm): boolean => {
    return isValidInput(form.bedroomCount)
        && isValidInput(form.toiletCount)
        && isValidInput(form.bathroomCount);
}
