import {RegionTypes} from '../../types/responses/epc-values';

export interface WebForm {
    id: string;
    invitation?: Invitation;
    status: WebformStatus;
    address?: string;
    region?: RegionTypes;
    longitude?: string;
    latitude?: string;
    capakeys?: string;
    isOnlyPartOfParcel?: boolean;
    propertyType?: PropertyType;
    constructionYear?: number;
    floorCount?: number;
    bedroomCount?: number;
    bathroomCount?: number;
    toiletCount?: number;
    floor?: number;
    livingAreaSize?: number | string;
    storageBasementSize?: number | string;
    storageAtticSize?: number | string;
    numberOfIndoorParkingSpacesMainBuilding?: number | string;
    numberOfIndoorParkingSpacesSeparateBuilding?: number | string;
    numberOfOutdoorParkingSpacesOpenAir?: number | string;
    numberOfOutdoorParkingSpacesCarport?: number | string;
    plotSize?: number | string;
    balconyAndTerraceSize?: number | string;
    privateGardenSize?: number | string;
    communalGardenSize?: number | string;
    epcCategory?: EpcCategory | null;
    epcScore?: number | null;
    ventilationSystem?: VentilationSystem | null;
    flatRoofInsulation?: FlatRoofInsulation | null;
    slopedRoofInsulation?: SlopedRoofInsulation | null;
    floorType?: FloorType | null;
    floorOnFullGroundInsulation?: FloorOnFullGroundInsulation | null;
    floorOnCrawlBasementInsulation?: FloorOnCrawlBasementInsulation | null;
    wallInsulation?: WallInsulation | null;
    primaryHeatProduction?: PrimaryHeatProduction[];
    waterHeating?: WaterHeating[];
    heatingElements?: HeatingElements[];
    windowIsolationType?: WindowIsolationType;
    ceilingHeightType?: CeilingHeightType;
    floorCoveringType?: FloorCoveringType;
    hasSwimmingPool?: boolean;
    hasElevator?: boolean;
    hasSauna?: boolean;
    hasHomeAutomation?: boolean;
    hasLandscapedGarden?: boolean;
    hasAlarm?: boolean;
    hasVideoPhone?: boolean;
    hasPoolHouse?: boolean;
    hasGardenOffice?: boolean;
    hasSolarPanels?: boolean;
    hasSolarCollector?: boolean;
    poolHouseSize?: number | string;
    gardenOfficeSize?: number | string;
    builtInFurnitureType?: BuiltInFurnitureType;
    overallBuildingCondition?: BuildingCondition;
    kitchenQuality?: Quality;
    bathroomQuality?: Quality;
    buildingQuality?: Quality;
    renovations?: Renovation[];
    overallRenovationWithPermit?: boolean;
    previousTransactionPrice?: number | string | null;
    previousTransactionDate?: string | null;
    lastValuationPrice?: number | string | null;
    lastValuationDate?: string | null;
    overallRemarks?: string;
    consent?: boolean;
    roofType?: RoofType;
    hasEasements?: boolean;
}

export const webformIsEqual = (a: WebForm, b: WebForm): boolean => {
    return a.id === b.id &&
        a.status === b.status &&
        a.address === b.address &&
        a.region === b.region &&
        a.longitude === b.longitude &&
        a.latitude === b.latitude &&
        a.capakeys === b.capakeys &&
        a.isOnlyPartOfParcel === b.isOnlyPartOfParcel &&
        a.propertyType === b.propertyType &&
        a.constructionYear === b.constructionYear &&
        a.floorCount === b.floorCount &&
        a.bedroomCount === b.bedroomCount &&
        a.bathroomCount === b.bathroomCount &&
        a.toiletCount === b.toiletCount &&
        a.floor === b.floor &&
        a.livingAreaSize === b.livingAreaSize &&
        a.storageBasementSize === b.storageBasementSize &&
        a.storageAtticSize === b.storageAtticSize &&
        a.numberOfIndoorParkingSpacesMainBuilding === b.numberOfIndoorParkingSpacesMainBuilding &&
        a.numberOfIndoorParkingSpacesSeparateBuilding === b.numberOfIndoorParkingSpacesSeparateBuilding &&
        a.numberOfOutdoorParkingSpacesCarport === b.numberOfOutdoorParkingSpacesCarport &&
        a.numberOfOutdoorParkingSpacesOpenAir === b.numberOfOutdoorParkingSpacesOpenAir &&
        a.plotSize === b.plotSize &&
        a.balconyAndTerraceSize === b.balconyAndTerraceSize &&
        a.privateGardenSize === b.privateGardenSize &&
        a.communalGardenSize === b.communalGardenSize &&
        a.epcCategory === b.epcCategory &&
        a.ventilationSystem === b.ventilationSystem &&
        a.flatRoofInsulation === b.flatRoofInsulation &&
        a.slopedRoofInsulation === b.slopedRoofInsulation &&
        a.floorType === b.floorType &&
        a.floorOnFullGroundInsulation === b.floorOnFullGroundInsulation &&
        a.floorOnCrawlBasementInsulation === b.floorOnCrawlBasementInsulation &&
        a.wallInsulation === b.wallInsulation &&
        a.waterHeating === b.waterHeating &&
        a.primaryHeatProduction === b.primaryHeatProduction &&
        a.heatingElements === b.heatingElements &&
        a.windowIsolationType === b.windowIsolationType &&
        a.ceilingHeightType === b.ceilingHeightType &&
        a.floorCoveringType === b.floorCoveringType &&
        a.hasSwimmingPool === b.hasSwimmingPool &&
        a.hasElevator === b.hasElevator &&
        a.hasSauna === b.hasSauna &&
        a.hasHomeAutomation === b.hasHomeAutomation &&
        a.hasLandscapedGarden === b.hasLandscapedGarden &&
        a.hasAlarm === b.hasAlarm &&
        a.hasVideoPhone === b.hasVideoPhone &&
        a.hasPoolHouse === b.hasPoolHouse &&
        a.hasGardenOffice === b.hasGardenOffice &&
        a.hasSolarPanels === b.hasSolarPanels &&
        a.hasSolarCollector === b.hasSolarCollector &&
        a.poolHouseSize === b.poolHouseSize &&
        a.gardenOfficeSize === b.gardenOfficeSize &&
        a.builtInFurnitureType === b.builtInFurnitureType &&
        a.overallBuildingCondition === b.overallBuildingCondition &&
        a.kitchenQuality === b.kitchenQuality &&
        a.bathroomQuality === b.bathroomQuality &&
        a.buildingQuality === b.buildingQuality &&
        a.renovations === b.renovations &&
        a.overallRenovationWithPermit === b.overallRenovationWithPermit &&
        a.previousTransactionPrice === b.previousTransactionPrice &&
        a.previousTransactionDate === b.previousTransactionDate &&
        a.lastValuationPrice === b.lastValuationPrice &&
        a.lastValuationDate === b.lastValuationDate &&
        a.overallRemarks === b.overallRemarks &&
        a.consent === b.consent &&
        a.hasEasements === b.hasEasements &&
        a.roofType === b.roofType;
}

export interface Invitation {
    id: string;
    token: string;
    email: string;
    address?: string;
    status: InvitationStatus;
    externalRef?: string;
    paymentStrategy: PaymentStrategy;
}

export interface Renovation {
    id?: string;
    type?: RenovationType;
    amount?: number;
    year?: number;
}

// t('Overall')
// t('Kitchen')
// t('Windows')
// t('Facade')
// t('Roof')
// t('BathroomAndSanitary')
// t('Heating')
// t('Electricity')
// t('Outdoors')
export enum RenovationType {
    Overall = 'Overall',
    Kitchen = 'Kitchen',
    Windows = 'Windows',
    Facade = 'Facade',
    Roof = 'Roof',
    BathroomAndSanitary = 'BathroomAndSanitary',
    Heating = 'Heating',
    Electricity = 'Electricity',
    Outdoors = 'Outdoors',
}

export enum PaymentStrategy {
    ClientPayment = 'ClientPayment',
    NoPayment = 'NoPayment',
}

export enum InvitationStatus {
    NEW = 'NEW',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
}

export enum WebformStatus {
    NEW = 'NEW',
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
}

// t('APlus')
// t('A')
// t('B')
// t('C')
// t('D')
// t('E')
// t('F')
// t('G')
export enum EpcCategory {
    APlus = 'APlus',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

// t('Luxurious')
// t('VeryHigh')
// t('HighF')
// t('JustAboveAverage')
// t('AverageF')
// t('JustBelowAverage')
// t('LowF')
// t('VeryLow')
// t('Inferior')
export enum Quality {
    Luxurious = '9 - LUXURIOUS',
    VeryHigh = '8 - VERY_HIGH_STANDARD',
    HighF = '7 - HIGH_STANDARD',
    JustAboveAverage = '6 - JUST_ABOVE_AVERAGE',
    AverageF = '5 - AVERAGE_STANDARD',
    JustBelowAverage = '4 - JUST_BELOW_AVERAGE',
    LowF = '3 - LOW_STANDARD',
    VeryLow = '2 - VERY_LOW',
    Inferior = '1 - INFERIOR',
}

// t('Perfect')
// t('VeryGood')
// t('Good')
// t('AverageM')
// t('BetweenSomeMaintenanceBacklogAndAverage')
// t('SomeMaintenanceBacklog')
// t('Damaged')
// t('ToRenovateWithPreservationOfMoreThanTheStructure')
// t('ToRenovateFromTheGroundUp')
// t('QuasiDemolishable')
export enum BuildingCondition {
    Perfect = 'PERFECT',
    VeryGood = 'VERY_GOOD',
    Good = 'GOOD',
    AverageM = 'AVERAGE',
    BetweenSomeMaintenanceBacklogAndAverage = 'BETWEEN_SOME_MAINTENANCE_BACKLOG_AND_AVERAGE',
    SomeMaintenanceBacklog = 'SOME_MAINTENANCE_BACKLOG',
    Damaged = 'DAMAGED',
    ToRenovateWithPreservationOfMoreThanTheStructure = 'TO_RENOVATE_WITH_PRESERVATION_OF_MORE_THAN_THE_STRUCTURE',
    ToRenovateFromTheGroundUp = 'TO_RENOVATE_FROM_THE_GROUND_UP',
    QuasiDemolishable = 'QUASI_DEMOLISHABLE',
}

// t('None')
// t('FewStandard')
// t('MultipleStandard')
// t('FewLuxurious')
// t('MultipleLuxurious')
export enum BuiltInFurnitureType {
    MultipleLuxurious = 'PLENTY_AND_LUXURIOUS',
    FewLuxurious = 'LITTLE_AND_LUXURIOUS',
    MultipleStandard = 'PLENTY_AND_STANDARD',
    FewStandard = 'LITTLE_AND_STANDARD',
    None = 'NONE',
}

// t('Gas')
// t('HeatPump')
// t('Geothermal')
// t('Electrical')
// t('Pellets')
// t('Wood')
// t('Fuel')
export enum PrimaryHeatProduction {
    Gas = 'Gas',
    HeatPump = 'HeatPump',
    Geothermal = 'Geothermal',
    Electrical = 'Electrical',
    Pellets = 'Pellets',
    Wood = 'Wood',
    Fuel = 'Fuel',
}

// t('Gas')
// t('Fuel')
// t('ElectricalBoiler')
// t('HeatPumpBoiler')
// t('SolarBoiler')
export enum WaterHeating {
    Gas = 'Gas',
    Fuel = 'Fuel',
    ElectricalBoiler = 'ElectricalBoiler',
    HeatPumpBoiler = 'HeatPumpBoiler',
    SolarBoiler = 'SolarBoiler',
}

// t('TripleGlazing')
// t('RecentDoubleGlazing')
// t('DoubleGlazing')
// t('SingleGlazing')
export enum WindowIsolationType {
    TripleGlazing = 'TRIPLE',
    RecentDoubleGlazing = 'RECENT_DOUBLE',
    DoubleGlazing = 'DOUBLE',
    SingleGlazing = 'SINGLE',
}

// t('FloorHeating')
// t('Radiators')
// t('Convectors')
// t('Stove')
// t('Fireplace')
export enum HeatingElements {
    FloorHeating = 'FloorHeating',
    Radiators = 'Radiators',
    Convectors = 'Convectors',
    Stove = 'Stove',
    Fireplace = 'Fireplace',
}

// t('HighF')
// t('NormalF')
// t('LowF')
export enum CeilingHeightType {
    HighF = 'HIGH',
    NormalF = 'NORMAL',
    LowF = 'LOW'
}

// t('Premium')
// t('HighM')
// t('NormalM')
// t('LowM')
export enum FloorCoveringType {
    Premium = 'PREMIUM',
    HighM = 'HIGH_QUALITY',
    NormalM = 'NORMAL',
    LowM = 'LOW_QUALITY'
}

// t('Apartment')
// t('Studio')
// t('Penthouse')
// t('Townhouse')
// t('SemiDetachedHouse')
// t('DetachedHouse')
// t('Chalet')
// t('Farm')
// t('Castle')
// t('BuildingParcel')
// t('StudentRoom')
// t('ServiceFlat')
// t('Duplex')
// t('Triplex')
export enum PropertyType {
    Apartment = 'APARTMENT',
    Studio = 'STUDIO',
    Duplex = 'DUPLEX',
    Triplex = 'TRIPLEX',
    Penthouse = 'PENTHOUSE',
    StudentRoom = 'STUDENT_ROOM',
    ServiceFlat = 'SERVICEFLAT',
    Townhouse = 'TOWNHOUSE',
    SemiDetachedHouse = 'SEMI_DETACHED_HOUSE',
    DetachedHouse = 'DETACHED_HOUSE',
    Chalet = 'CHALET',
    Farm = 'FARM',
    Castle = 'CASTLE',
    BuildingParcel = 'BUILDING_PARCEL',
}

// t('PartOfBuilding')
// t('Building')
// t('BuildingParcel')
export enum PropertyTypeCategory {
    PartOfBuilding = 'PartOfBuilding',
    Building = 'Building',
    BuildingParcel = 'BuildingParcel',
}

// t('Flat')
// t('Hip')
// t('Gable')
// t('Complex')
export enum RoofType {
    Flat = 'FLAT_ROOF',
    Hip = 'HIP_ROOF',
    Gable = 'GABLE_ROOF',
    Complex = 'COMPLEX_ROOF'
}

// translate labels when multiple attachments type are available in a file handler component
// t('front-facade')
// t('rear-facade')
// t('living-room')
// t('bathroom')
// t('kitchen')
// t('bedroom')
// t('swimming-pool')
// t('landscaped-garden')
// t('pool-house')
// t('garden-office')
// t('images')
export enum AttachmentType {
    Plans = 'plans',
    EPCReport = 'epc-report',
    Amenities = 'amenities',
    BuildingCondition = 'building-condition',
    BuildingPermit = 'building-permit',
    ValuationReport = 'valuation-report',
    Other = 'other',
    SwimmingPool = 'swimming-pool',
    LandscapedGarden = 'landscaped-garden',
    PoolHouse = 'pool-house',
    GardenOffice = 'garden-office',
    FrontFacade = 'front-facade',
    RearFacade = 'rear-facade',
    LivingRoom = 'living-room',
    Bathroom = 'bathroom',
    Kitchen = 'kitchen',
    Bedroom = 'bedroom',
    Images = 'images'
}

// t('NoVentilation')
// t('NaturalVentilationThroughVents')
// t('MechanicalExtraction')
// t('MechanicalExtractionWithDemandControl')
// t('MechanicalSupplyAndExtractionWithHeatRecovery')
export enum VentilationSystem {
    NoVentilation = 'NoVentilation',
    NaturalVentilationThroughVents = 'NaturalVentilationThroughVents',
    MechanicalExtraction = 'MechanicalExtraction',
    MechanicalExtractionWithDemandControl = 'MechanicalExtractionWithDemandControl',
    MechanicalSupplyAndExtractionWithHeatRecovery = 'MechanicalSupplyAndExtractionWithHeatRecovery',
}

// t('NoFlatRoofInsulation')
// t('LightFlatRoofInsulation')
// t('MediumFlatRoofInsulation')
// t('HighFlatRoofInsulation')
export enum FlatRoofInsulation {
    NoFlatRoofInsulation = 'NoFlatRoofInsulation',
    LightFlatRoofInsulation = 'LightFlatRoofInsulation',
    MediumFlatRoofInsulation = 'MediumFlatRoofInsulation',
    HighFlatRoofInsulation = 'HighFlatRoofInsulation',
}

// t('NoSlopedRoofInsulation')
// t('LightSlopedRoofInsulation')
// t('MediumSlopedRoofInsulation')
// t('HighSlopedRoofInsulation')
export enum SlopedRoofInsulation {
    NoSlopedRoofInsulation = 'NoSlopedRoofInsulation',
    LightSlopedRoofInsulation = 'LightSlopedRoofInsulation',
    MediumSlopedRoofInsulation = 'MediumSlopedRoofInsulation',
    HighSlopedRoofInsulation = 'HighSlopedRoofInsulation',
}

// t('OnFullGround')
// t('OnCrawlBasement')
// t('Both')
export enum FloorType {
    OnFullGround = 'OnFullGround',
    OnCrawlBasement = 'OnCrawlBasement',
    Both = 'Both',
}

// t('NoFloorOnFullGroundInsulation')
// t('LightFloorOnFullGroundInsulation')
// t('MediumFloorOnFullGroundInsulation')
// t('HighFloorOnFullGroundInsulation')
export enum FloorOnFullGroundInsulation {
    NoFloorOnFullGroundInsulation = 'NoFloorOnFullGroundInsulation',
    LightFloorOnFullGroundInsulation = 'LightFloorOnFullGroundInsulation',
    MediumFloorOnFullGroundInsulation = 'MediumFloorOnFullGroundInsulation',
    HighFloorOnFullGroundInsulation = 'HighFloorOnFullGroundInsulation',
}

// t('NoFloorOnCrawlBasementInsulation')
// t('MediumFloorOnCrawlBasementInsulation')
// t('HighFloorOnCrawlBasementInsulation')
export enum FloorOnCrawlBasementInsulation {
    NoFloorOnCrawlBasementInsulation = 'NoFloorOnCrawlBasementInsulation',
    MediumFloorOnCrawlBasementInsulation = 'MediumFloorOnCrawlBasementInsulation',
    HighFloorOnCrawlBasementInsulation = 'HighFloorOnCrawlBasementInsulation',
}

// t('NoInsulationNoCavity')
// t('NoInsulationAirCavity')
// t('LightWallInsulation')
// t('MediumWallInsulation')
// t('HighWallInsulation')
export enum WallInsulation {
    NoInsulationNoCavity = 'NoInsulationNoCavity',
    NoInsulationAirCavity = 'NoInsulationAirCavity',
    LightWallInsulation= 'LightWallInsulation',
    MediumWallInsulation = 'MediumWallInsulation',
    HighWallInsulation = 'HighWallInsulation',
}
