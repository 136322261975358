import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {MapShape} from '../../../types/map-shape';
import styles from './google-map.module.scss';

interface OwnProps {
    longitude?: number;
    latitude?: number;
    shapes?: MapShape[];
    selectedCapakeys: string[];
    zoom?: number;
    onDragEnd: (lng: number, lat: number) => void;
    onParcelClick: (capakey: string) => void;
    onZoom: (zoom: number) => void;
}

const GoogleMapComponent: FunctionComponent<OwnProps> = ({
                                                             zoom,
                                                             longitude,
                                                             latitude,
                                                             shapes,
                                                             selectedCapakeys,
                                                             onDragEnd,
                                                             onParcelClick,
                                                             onZoom,
                                                         }: OwnProps) => {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDaQ75JusbGDUP80eU18u3NIpLtcbcMNUQ'
    })

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [center, setCenter] = useState<{ lng: number, lat: number }>();
    const [zoomLevel, setZoomLevel] = useState<number>(zoom || 18);
    const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);

    const onLoad = useCallback((initialMap) => {
        if (longitude && latitude) {
            const newMap = moveToCenter(longitude, latitude, initialMap);

            setMap(newMap);
            if (shapes) {
                displayParcels();
            }
        }
    }, [longitude, latitude]);

    useEffect(() => {
        if (map && shapes && (!polygons || polygons.length === 0)) {
            displayParcels();
        }
    }, [map])

    useEffect(() => {
        if (map && longitude && latitude) {
            moveToCenter(longitude, latitude, map);
        }
    }, [longitude, latitude])

    useEffect(() => {
        if (zoomLevel && zoomLevel < 17 && polygons) {
            // hide polygons if zoomed out
            polygons.forEach(polygon => polygon.setMap(null));
            setPolygons([]);
        } else if (zoomLevel && zoomLevel >= 17 && shapes && (!polygons || polygons.length === 0)) {
            displayParcels();
        }
    }, [zoomLevel])

    useEffect(() => {
        displayParcels();
    }, [shapes, selectedCapakeys])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])

    const displayParcels = () => {
        if (polygons) {
            polygons.forEach(polygon => polygon.setMap(null));
            setPolygons([]);
        }
        if (shapes && map) {
            const newPolygons: google.maps.Polygon[] = [];
            shapes.forEach((shape) => {
                if (shape.geojson && shape.geojson.coordinates) {
                    const isSelected = selectedCapakeys && selectedCapakeys.includes(shape.capakey);
                    const shapeCoordinates = shape.geojson.coordinates[0].map(coordinate => {
                        return {
                            lng: coordinate[0],
                            lat: coordinate[1],
                        }
                    })
                    const parcelPolygon = new google.maps.Polygon({
                            paths: shapeCoordinates,
                            strokeWeight: 2,
                            strokeColor: '#000',
                            strokeOpacity: !isSelected ? 0.5 : 1,
                            fillColor: '#05adf8',
                            fillOpacity: !isSelected ? 0.1 : 0.6,
                        }
                    );

                    parcelPolygon.addListener('click', () => onParcelClick(shape.capakey));

                    parcelPolygon.setMap(map);
                    newPolygons.push(parcelPolygon);
                }
            })
            setPolygons(newPolygons);
        }
    }

    const moveToCenter = (lng: number, lat: number, map: google.maps.Map): google.maps.Map => {
        const updatedCenter = {
            lng,
            lat,
        }
        setCenter(updatedCenter);
        map.setZoom(zoomLevel);

        return map;
    }

    const dragEnd = () => {
        if (map) {
            const updatedCenter = map.getCenter();
            if (updatedCenter) {
                const lng = updatedCenter.lng();
                const lat = updatedCenter.lat();
                onDragEnd(lng, lat)
            }
        }
    }

    const updateZoom = () => {
        if (map) {
            const updatedZoom = map.getZoom();
            if (updatedZoom) {
                setZoomLevel(updatedZoom);
                onZoom(updatedZoom);
            }
        }
    }

    return isLoaded ? (
        <div className={styles.mapContainer}>
            <GoogleMap
                mapContainerStyle={{width: '100%', height: '100%'}}
                center={center}
                zoom={zoomLevel}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onDragEnd={dragEnd}
                options={
                    {
                        mapTypeId: google.maps.MapTypeId.SATELLITE,
                        fullscreenControl: false,
                        mapTypeControl: false,
                        tilt: 0,
                        rotateControl: false,
                        gestureHandling: 'greedy',
                        streetViewControl: false,
                        zoomControl: false,
                    }
                }
                onZoomChanged={updateZoom}
            >
            </GoogleMap>
        </div>
    ) : <></>
}


export default GoogleMapComponent;
