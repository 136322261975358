import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {WebForm} from '../store/webform/types';

type ReturnType = {
    next: () => void,
    back: () => void,
}

export const usePreviousAndNext = (
    isValid: boolean,
    onNextClick: () => void,
    onPreviousClick: () => void,
    validateFields: () => void,
    checkIfStepWasCompleted: () => boolean,
    updateForm: (skipValidation: boolean) => WebForm,
    onChange: (form: WebForm, forceSave: boolean) => void,
): ReturnType => {
    const [isPreviouslyCompleted, setIsPreviouslyCompleted] = useState<boolean>();

    useEffect(() => {
        if (checkIfStepWasCompleted()) {
            setIsPreviouslyCompleted(true);
        } else {
            setIsPreviouslyCompleted(false)
        }
    }, [])

    const next = () => {
        if (isValid) {
            onNextClick();
        } else {
            validateFields();
        }
    };

    const back = () => {
        if (!isPreviouslyCompleted) {
            const updatedForm = updateForm(false);
            onChange(updatedForm, true);
            onPreviousClick();
        } else {
            if (!isValid) {
                validateFields();
            } else {
                onPreviousClick()
            }
        }
    };

    return {next, back};
}
