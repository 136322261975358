import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './input.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import MultiSelectComponent from '../../../../components/multi-select/multi-select.component';
import {SelectionOption} from '../../../../components/dropdown/dropdown.component';
import {VariableDisplay} from '../../../../types/variable-display';
import {useTranslation} from 'react-i18next';

interface OwnProps {
    label: string;
    display: VariableDisplay;
    errors?: string;
    options: SelectionOption[];
    selectedOptions: SelectionOption[];
    valueChanged: (options: SelectionOption[]) => void;
    allowMultiple?: boolean;
    footnote?: string;
}

const LabeledMultiSelectComponent: FunctionComponent<OwnProps> = ({
                                                                      label,
                                                                      display,
                                                                      errors,
                                                                      options,
                                                                      selectedOptions,
                                                                      valueChanged,
                                                                      allowMultiple,
                                                                      footnote,
                                                                  }: OwnProps) => {
    const {t} = useTranslation();
    const [labelInfo, setLabelInfo] = useState<string>('');

    useEffect(() => {
        if (allowMultiple) {
            if (display === VariableDisplay.Required) {
                setLabelInfo(t('Multiple'));
            } else if (display === VariableDisplay.Optional) {
                setLabelInfo(t('Optional, Multiple'));
            }
        } else {
            if (display === VariableDisplay.Optional) {
                setLabelInfo(t('Optional'));
            }
        }
    }, [display, allowMultiple])

    const displayVariable = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    return displayVariable(display) ? (
        <div className={styles.formField}>
            <label className={conditionalClassLister(styles)({
                label: true,
                withError: errors,
            })}>
                {label}
                {labelInfo && <div className={styles.labelInfo}>{labelInfo}</div>}
            </label>
            {errors ? <div className={styles.labelError}>{t(errors)}</div> : null}
            <MultiSelectComponent allowMultiSelect={allowMultiple}
                                  options={options}
                                  onSelectionsChanged={(options: SelectionOption[]) => valueChanged(options)}
                                  selection={selectedOptions}/>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}

export default LabeledMultiSelectComponent;
